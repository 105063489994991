import { extraAdminActions } from './admin.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { AdminState } from './admin.state';

export const adminExtraReducers = (builder: ActionReducerMapBuilder<AdminState>) => {
	fetchAllCases(builder);
	fetchCases(builder);
	createCases(builder);
	updateCases(builder);
	removeCases(builder);
};

// FetchAll
const fetchAllCases = (builder: ActionReducerMapBuilder<AdminState>) => {
	// FetchAll
	builder.addCase(extraAdminActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Admins::fetch::pending');
	});
	builder.addCase(extraAdminActions.fetchAll.fulfilled, (state, action) => {
		state.admins = action.payload;

		state.byId = action.payload.reduce((acc, admin) => {
			acc[admin.id] = admin;
			return acc;
		});

		state.isLoading = false;
		Logger.log('Admin::fetch::success');
	});
	builder.addCase(extraAdminActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Admin::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetchCases = (builder: ActionReducerMapBuilder<AdminState>) => {
	builder.addCase(extraAdminActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Admin::fetch::pending');
	});
	builder.addCase(extraAdminActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;
		state.admins.push(payload);
		state.byId[payload.id] = payload;
		state.isLoading = false;
		Logger.log('Admin::fetch::success');
	});
	builder.addCase(extraAdminActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Admin::fetch::error::' + action.error.message);
	});
};

// Create
const createCases = (builder: ActionReducerMapBuilder<AdminState>) => {
	builder.addCase(extraAdminActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Admin::create::pending');
	});
	builder.addCase(extraAdminActions.create.fulfilled, (state, action) => {
		state.admins.push(action.payload);
		state.isLoading = false;
		Logger.log('Admin::create::success');
	});
	builder.addCase(extraAdminActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Admin::create::error::' + action.error.message);
	});
};

// Update
const updateCases = (builder: ActionReducerMapBuilder<AdminState>) => {
	builder.addCase(extraAdminActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Admin::update::pending');
	});
	builder.addCase(extraAdminActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.admins = state.admins.map((admin) => {
			if (admin.id === payload?.id) {
				return payload;
			} else {
				return admin;
			}
		});

		if (payload?.id) {
			state.byId[payload.id] = payload;
		}

		state.isLoading = false;
		Logger.log('Admin::update::success');
	});
	builder.addCase(extraAdminActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Admin::update::error::' + action.error.message);
	});
};

// Remove
const removeCases = (builder: ActionReducerMapBuilder<AdminState>) => {
	// Remove
	builder.addCase(extraAdminActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Admin::remove::pending');
	});
	builder.addCase(extraAdminActions.remove.fulfilled, (state, action) => {
		state.admins = state.admins.filter((admin) => admin.id !== action.meta.arg);
		state.isLoading = false;
		Logger.log('Admin::remove::success');
	});
	builder.addCase(extraAdminActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Admin::remove::error::' + action.error.message);
	});
};
