import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllLogs, getLog, getRequestStatistics } from '../../../api_clients/logsAPIClient';

const name = 'logs';

export const createLogExtraActions = () => {
	return {
		fetchLogs: fetchLogs(),
		fetchLogEntry: fetchLogEntry(),
		fetchStatistics: fetchStatistics(),
	};

	function fetchLogs() {
		return createAsyncThunk(`${name}/fetchLogs`, async () => await getAllLogs());
	}

	function fetchLogEntry() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getLog(id));
	}

	function fetchStatistics() {
		return createAsyncThunk(`${name}/fetchStatistics`, async () => await getRequestStatistics());
	}
};
