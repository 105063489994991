import { Add, GetApp } from '@mui/icons-material';
import { BankAccountDetailViewDataGrid } from './BankAccountDetailViewDataGrid';
import { Button, Stack, Typography } from '@mui/material';
import { CreateBankAccountTransactionModal } from './components/CreateBankAccountTransactionModal';
import { DeleteConfirmationDialog } from '../../../components/Modals/DeleteConfirmationDialog';
import { EditBankAccountTransactionModal } from './components/EditBankAccountTransactionModal';
import { ReceiptModal } from './components/ReceiptModal';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { calculateBankAccountBalance } from '../../../../helpers/calculateBankAccountBalance';
import { downloadSingleBankAccount } from '../../../../helpers/excel/downloadSingleBankAccount';
import { receiptActions } from '../../../../redux/reducers/receiptsReducer/receiptReducer.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import type { BankAccountTransaction } from '../../../../models/BankAccountTransaction';
import type { Receipt } from '../../../../models/Receipt';

export const BankAccountDetailView = React.memo(() => {
	const dispatch = useAppDispatch();
	const { id } = useParams();

	const bankAccount = useAppSelector((state) => state.bankAccounts.byId[id ? id : '']);
	const transactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions).filter(
		(transaction) => transaction.bankAccount === id,
	);

	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);
	const receipts = useAppSelector((state) => state.receipts.receipts);

	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalTransaction, setEditModalTransaction] = useState<BankAccountTransaction | undefined>(undefined);

	const [showCreateModal, setShowCreateModal] = useState(false);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [transactionToDelete, setTransactionToDelete] = useState<BankAccountTransaction | undefined>(undefined);

	const [showReceiptModal, setShowReceiptModal] = useState(false);
	const [receiptToShow, setReceiptToShow] = useState<Receipt | undefined>(undefined);

	useEffect(() => {
		if (id) {
			dispatch(bankAccountActions.fetch(id));
		}

		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		dispatch(receiptActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (bankAccount) {
			document.title = `${bankAccount.accountDescription} - Kassenbuch`;
		} else {
			document.title = `Kassenbuch`;
		}
	}, [bankAccount]);

	const didCloseEditModal = useCallback(() => {
		setShowEditModal(false);
		setEditModalTransaction(undefined);
	}, []);

	const didCloseCreateModal = useCallback(() => {
		setShowCreateModal(false);
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(receiptActions.fetchAll());
	}, [dispatch]);

	const didCloseConfirmationDialogWithDelete = useCallback(() => {
		setShowConfirmationDialog(false);
		if (transactionToDelete && transactionToDelete.id) {
			dispatch(bankAccountTransactionActions.remove(transactionToDelete.id)).then(() =>
				dispatch(bankAccountTransactionActions.fetchAll()),
			);
		}
		setTransactionToDelete(undefined);
	}, [dispatch, transactionToDelete]);

	const didCloseConfirmationDialogWithoutDelete = useCallback(() => {
		setShowConfirmationDialog(false);
		setTransactionToDelete(undefined);
	}, []);

	if (!bankAccount && !transactions) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<ReceiptModal receipt={receiptToShow} show={showReceiptModal} didClose={() => setShowReceiptModal(false)} />
			<EditBankAccountTransactionModal
				bankAccount={bankAccount}
				transaction={editModalTransaction!}
				didClose={didCloseEditModal}
				show={showEditModal}
			/>
			<CreateBankAccountTransactionModal
				didClose={didCloseCreateModal}
				show={showCreateModal}
				bankAccount={bankAccount}
			/>
			<DeleteConfirmationDialog
				itemToDeleteTitle={transactionToDelete?.description ?? ''}
				onDelete={didCloseConfirmationDialogWithDelete}
				onCancel={didCloseConfirmationDialogWithoutDelete}
				show={showConfirmationDialog}
			/>
			<StyledToolbar
				title={bankAccount?.accountDescription ?? ''}
				description={
					<Stack>
						<Typography variant="subtitle1">
							Kontostand: {calculateBankAccountBalance(transactions)}
						</Typography>
						{bankAccount?.iban && bankAccount?.bic && bankAccount?.bank && (
							<>
								<Typography variant="subtitle1">IBAN: {bankAccount.iban}</Typography>
								<Typography variant="subtitle1">BIC: {bankAccount.bic}</Typography>
								<Typography variant="subtitle1">Bank: {bankAccount.bank}</Typography>
							</>
						)}
					</Stack>
				}
				primaryActions={
					<>
						<Button variant="contained" onClick={() => setShowCreateModal(true)} startIcon={<Add />}>
							Buchung hinzufügen
						</Button>
						<Button
							variant="contained"
							onClick={() =>
								bankAccount ? downloadSingleBankAccount(bankAccount, transactions, categories) : null
							}
							startIcon={<GetApp />}
						>
							Exportieren
						</Button>
					</>
				}
			/>
			<BankAccountDetailViewDataGrid
				transactions={transactions}
				receipts={receipts}
				setEditModalTransaction={setEditModalTransaction}
				setShowEditModal={setShowEditModal}
				setTransactionToDelete={setTransactionToDelete}
				setShowConfirmationDialog={setShowConfirmationDialog}
				setReceiptToShow={setReceiptToShow}
				setShowReceiptModal={setShowReceiptModal}
			/>
		</>
	);
});
