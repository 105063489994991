import { CreateBankAccountTransactionForm } from './CreateBankAccountTransactionForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { bankAccountTransactionActions } from '../../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { receiptActions } from '../../../../../redux/reducers/receiptsReducer/receiptReducer.slice';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { BankAccount } from '../../../../../models/BankAccount';
import type { BankAccountTransaction } from '../../../../../models/BankAccountTransaction';
import type { CreateBankAccountTransactionFormDataType } from './CreateBankAccountTransactionForm';

export type CreateBankAccountTransactionModalProps = {
	bankAccount: BankAccount;
	didClose: () => void;
	show: boolean;
};

export const CreateBankAccountTransactionModal = React.memo(
	({ bankAccount, didClose, show }: CreateBankAccountTransactionModalProps) => {
		const dispatch = useAppDispatch();

		useEffect(() => {
			dispatch(transactionCategoryActions.fetchAll());
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<StyledModal show={show} onClose={didClose} heading="Bankkonto Erstellen">
				<CreateBankAccountTransactionForm
					submitData={function (data: CreateBankAccountTransactionFormDataType): void {
						if (bankAccount.id) {
							dispatch(
								bankAccountTransactionActions.create({
									bankStatement: data.bankStatement,
									date: moment(data.date).format('YYYY-MM-DD HH:mm:ss'),
									amount: data.amount,
									description: data.description,
									person: data.person,
									bankAccount: bankAccount.id,
									transactionCategory: data.transactionCategory,
								}),
							).then((action) => {
								const formData = data.receiptFormData;
								const transaction = action.payload as BankAccountTransaction;

								if (formData.has('image') && transaction.id) {
									formData.append('transaction', transaction.id.toString());
									dispatch(receiptActions.create(formData)).then(() => didClose());
								} else {
									didClose();
								}
							});
						}
					}}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
