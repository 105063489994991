import { EditStandardTransactionCategoryForm } from './EditStandardTransactionCategoryForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { standardTransactionCategoryActions } from '../../../../../redux/reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React from 'react';
import type { EditStandardTransactionCategoryFormDataType } from './EditStandardTransactionCategoryForm';
import type { StandardTransactionCategory } from '../../../../../models/StandardTransactionCategory';

export type EditStandardTransactionCategoryModalProps = {
	standardCategory: StandardTransactionCategory;
	didClose: () => void;
	show: boolean;
};

export const EditStandardTransactionCategoryModal = React.memo(
	({ standardCategory, didClose, show }: EditStandardTransactionCategoryModalProps) => {
		const dispatch = useAppDispatch();

		return (
			<StyledModal show={show} onClose={didClose} heading="Standard Transaktionskategorie Bearbeiten">
				<EditStandardTransactionCategoryForm
					submitData={function (data: EditStandardTransactionCategoryFormDataType): void {
						dispatch(
							standardTransactionCategoryActions.update({
								id: standardCategory.id!,
								name: data.name,
								description: data.description,
							}),
						).then(() => didClose());
					}}
					didCancel={didClose}
					standardCategory={standardCategory}
				/>
			</StyledModal>
		);
	},
);
