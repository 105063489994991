import { Add } from '@mui/icons-material';
import { IconButton, ListSubheader, Typography } from '@mui/material';
import React from 'react';

export type StyledListItemSubHeaderWithButtonProps = {
	icon: React.ReactNode;
	text: string;
	onClick: () => void;
};

export const StyledListItemSubHeaderWithButton = React.memo(
	({ icon, text, onClick }: StyledListItemSubHeaderWithButtonProps) => {
		return (
			<ListSubheader
				key={text + '-subheader-with-button'}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="subtitle2">{text}</Typography>
				<IconButton onClick={onClick}>
					<Add />
				</IconButton>
			</ListSubheader>
		);
	},
);
