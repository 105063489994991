import { bankAccountExtraActions } from './bankAccount.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { BankAccount } from '../../../models/BankAccount';
import type { BankAccountState } from './bankAccount.state';

export const bankAccountExtraReducers = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	// FetchAll
	builder.addCase(bankAccountExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccount::fetch::pending');
	});
	builder.addCase(bankAccountExtraActions.fetchAll.fulfilled, (state, action) => {
		state.bankAccounts = action.payload;

		state.byId = action.payload.reduce((map: Record<string, BankAccount>, bankAccount: BankAccount) => {
			map[bankAccount.id!] = bankAccount;

			return map;
		}, {});

		state.byYouthWork = action.payload.reduce((map: Record<string, BankAccount[]>, bankAccount: BankAccount) => {
			if (bankAccount.youthWork) {
				if (!map[bankAccount.youthWork]) {
					map[bankAccount.youthWork] = [];
				}
				map[bankAccount.youthWork].push(bankAccount);
			}
			return map;
		}, {});

		state.isLoading = false;
		Logger.log('BankAccount::fetch::success');
	});
	builder.addCase(bankAccountExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccount::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	// Fetch
	builder.addCase(bankAccountExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccount::fetch::pending');
	});
	builder.addCase(bankAccountExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;

		if (state.bankAccounts.findIndex((x) => x.id === payload.id) >= 0) {
			state.bankAccounts = state.bankAccounts.map((bankAccount) => {
				if (bankAccount.id === payload.id) {
					return payload;
				} else {
					return bankAccount;
				}
			});
		} else {
			state.bankAccounts.push(payload);
		}

		if (payload.id) {
			state.byId[payload.id] = payload;
		}

		if (payload.youthWork) {
			if (payload.id) {
				state.byYouthWork[payload.youthWork] = state.byYouthWork[payload.youthWork] || [];

				if (state.byYouthWork[payload.youthWork].findIndex((account) => account.id === payload.id) < 0) {
					state.byYouthWork[payload.youthWork].push(payload);
				}
			}
		}

		state.isLoading = false;
		Logger.log('BankAccount::fetch::success');
	});
	builder.addCase(bankAccountExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccount::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	// Create
	builder.addCase(bankAccountExtraActions.create.pending, (state, action) => {
		state.error = null;
		Logger.log('BankAccount::create::pending');
	});
	builder.addCase(bankAccountExtraActions.create.fulfilled, (state, action) => {
		state.bankAccounts.push(action.payload);
		Logger.log('BankAccount::create::success');
	});
	builder.addCase(bankAccountExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		Logger.error('BankAccount::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	// Update
	builder.addCase(bankAccountExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccount::update::pending');
	});
	builder.addCase(bankAccountExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.bankAccounts = state.bankAccounts.map((bankAccount) => {
			if (bankAccount.id === payload.id) {
				return payload;
			} else {
				return bankAccount;
			}
		});
		state.isLoading = false;
		Logger.log('BankAccount::update::success');
	});
	builder.addCase(bankAccountExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		Logger.error('BankAccount::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<BankAccountState>) => {
	// Remove
	builder.addCase(bankAccountExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccount::remove::pending');
	});
	builder.addCase(bankAccountExtraActions.remove.fulfilled, (state, action) => {
		state.bankAccounts = state.bankAccounts.filter((bankAccount) => bankAccount.id !== action.meta.arg);
		state.isLoading = false;
		Logger.log('BankAccount::remove::success');
	});
	builder.addCase(bankAccountExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccount::remove::error::' + action.error.message);
	});
};
