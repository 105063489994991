import { YouthWorkReducer } from './reducers/YouthWorkReducer/youthWork.slice';
import { adminReducer } from './reducers/adminReducer/admin.slice';
import { authenticationReducer } from './reducers/authentificationReducer/authentification.slice';
import { bankAccountReducer } from './reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionReducer } from './reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeReducer } from './reducers/bargeReducer/barge.slice';
import { bargeTransactionReducer } from './reducers/bargeTransactionReducer/bargeTransaction.slice';
import { configureStore } from '@reduxjs/toolkit';
import { logsReducer } from './reducers/LogsReducer/logs.slice';
import { receiptReducer } from './reducers/receiptsReducer/receiptReducer.slice';
import { standardTransactionCategoryReducer } from './reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { transactionCategoryReducer } from './reducers/TransactionCategoryReducer/transactionCategory.slice';
import { userReducer } from './reducers/UserReducer/user.slice';
import thunk from 'redux-thunk';

export const store = configureStore({
	reducer: {
		authentication: authenticationReducer,
		bankAccounts: bankAccountReducer,
		youthWorks: YouthWorkReducer,
		bankAccountTransactions: bankAccountTransactionReducer,
		transactionCategories: transactionCategoryReducer,
		users: userReducer,
		admins: adminReducer,
		barges: bargeReducer,
		bargeTransactions: bargeTransactionReducer,
		logs: logsReducer,
		receipts: receiptReducer,
		standardTransactionCategories: standardTransactionCategoryReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
