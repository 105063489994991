import { logExtraActions } from './logs.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { LogState } from './logs.state';

export const logExtraReducers = (builder: ActionReducerMapBuilder<LogState>) => {
	fetchLogs(builder);
	fetchLogEntry(builder);
	fetchStatistics(builder);
};

// FetchLogs
const fetchLogs = (builder: ActionReducerMapBuilder<LogState>) => {
	builder.addCase(logExtraActions.fetchLogs.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Logs::fetch::pending');
	});
	builder.addCase(logExtraActions.fetchLogs.fulfilled, (state, action) => {
		state.logs = action.payload.items;
		state.isLoading = false;
		Logger.log('Logs::fetch::success');
	});
	builder.addCase(logExtraActions.fetchLogs.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Logs::fetch::error::' + action.error.message);
	});
};

// FetchLogEntry
const fetchLogEntry = (builder: ActionReducerMapBuilder<LogState>) => {
	builder.addCase(logExtraActions.fetchLogEntry.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('LogEntry::fetch::pending');
	});
	builder.addCase(logExtraActions.fetchLogEntry.fulfilled, (state, action) => {
		const payload = action.payload;

		if (state.logs.findIndex((log) => log.id === payload.id) === -1) {
			state.logs.push(payload);
		} else {
			state.logs[state.logs.findIndex((log) => log.id === payload.id)] = payload;
		}

		state.isLoading = false;
		Logger.log('LogEntry::fetch::success');
	});
	builder.addCase(logExtraActions.fetchLogEntry.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('LogEntry::fetch::error::' + action.error.message);
	});
};

// FetchStatistics
const fetchStatistics = (builder: ActionReducerMapBuilder<LogState>) => {
	builder.addCase(logExtraActions.fetchStatistics.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Statistics::fetch::pending');
	});
	builder.addCase(logExtraActions.fetchStatistics.fulfilled, (state, action) => {
		state.statistics = action.payload;
		state.isLoading = false;
		Logger.log('Statistics::fetch::success');
	});
	builder.addCase(logExtraActions.fetchStatistics.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Statistics::fetch::error::' + action.error.message);
	});
};
