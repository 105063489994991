import { bargeTransactionExtraReducers } from './bargeTransaction.extra.reducers';
import { createBargeTransactionExtraActions } from './bargeTransaction.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { BargeTransactionState } from './bargeTransaction.state';

const name = 'bargeTransactionTransactions';
const initialState: BargeTransactionState = {
	bargeTransactions: [],
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: bargeTransactionExtraReducers,
});

// Exports
export const bargeTransactionExtraActions = createBargeTransactionExtraActions();
export const bargeTransactionActions = {
	...slice.actions,
	...bargeTransactionExtraActions,
};
export const bargeTransactionReducer = slice.reducer;
