import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { CreateUser, User } from '../models/User';

const collectionName = 'users';

// List
export async function getAllUsers() {
	Logger.log('UserAPI::getAllUsers');
	try {
		return await ApiClient.instance.client.collection(collectionName).getFullList<User>();
	} catch (error) {
		Logger.error('UserAPI::getAllUsers::error::' + error);
		throw error;
	}
}

// View
export async function getUser(id: string) {
	Logger.log('UserAPI::getUser::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<User>(id);
	} catch (error) {
		Logger.error('UserAPI::getUser::error::' + error);
		throw error;
	}
}

// Create
export async function createUser(user: CreateUser) {
	Logger.log('UserAPI::createUser::' + user);
	try {
		return await ApiClient.instance.client.collection(collectionName).create<User>(user);
	} catch (error) {
		Logger.error('UserAPI::createUser::' + user + '::error::' + error);
		throw error;
	}
}

// Update
export async function updateUser(user: User) {
	if (user.id) {
		Logger.log('UserAPI::updateUser::' + user.id);
		try {
			return await ApiClient.instance.client.collection(collectionName).update<User>(user.id, user);
		} catch (error) {
			Logger.error('UserAPI::updateUser::' + user.id + '::error::' + error);
			throw error;
		}
	}
}

// Delete
export async function deleteUser(id: string) {
	Logger.log('UserAPI::deleteUser::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).delete(id);
	} catch (error) {
		Logger.error('UserAPI::deleteUser::error::' + error);
		throw error;
	}
}
