import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { Constants } from '../../../../globals/Constants';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { calculateBankAccountBalance } from '../../../../helpers/calculateBankAccountBalance';
import { receiptActions } from '../../../../redux/reducers/receiptsReducer/receiptReducer.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { BankAccountTransaction } from '../../../../models/BankAccountTransaction';

import { CheckBankAccountListItem } from './components/CheckBankAccountListItem';
import { LinearProgressWithLabel } from '../BankAccountDetailView/components/LinearProgressWithLabel';

export const CheckBankAccount = React.memo(() => {
	const dispatch = useAppDispatch();

	const { id } = useParams();

	const bankAccount = useAppSelector((state) => state.bankAccounts.byId[id ? id : '']);
	const transactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions).filter(
		(transaction) => transaction.bankAccount === id,
	);
	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);
	const receipts = useAppSelector((state) => state.receipts.receipts);

	const uncheckedTransactions = useMemo(
		() => transactions.filter((transaction) => !transaction.isChecked),
		[transactions],
	);

	const [errorTextDialogIsOpen, setErrorTextDialogIsOpen] = React.useState(false);
	const [errorText, setErrorText] = React.useState('');
	const [transactionToError, setTransactionToError] = React.useState<BankAccountTransaction | undefined>(undefined);

	const didCancelErrorTextDialog = useCallback(() => {
		setTransactionToError(undefined);
		setErrorTextDialogIsOpen(false);
	}, []);

	const didSubmitErrorTextDialog = useCallback(() => {
		if (transactionToError) {
			dispatch(
				bankAccountTransactionActions.update({
					...transactionToError,
					checkErrorMessage: errorText,
					hasCheckError: true,
				}),
			).then(() =>
				dispatch(bankAccountTransactionActions.fetchAll()).then(() => setErrorTextDialogIsOpen(false)),
			);
		} else {
			setErrorTextDialogIsOpen(false);
		}
	}, [dispatch, errorText, transactionToError]);

	useEffect(() => {
		dispatch(bankAccountActions.fetchAll());
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		dispatch(receiptActions.fetchAll());

		if (bankAccount) {
			document.title = `Prüfung: ${bankAccount.accountDescription} - Kassenbuch`;
		} else {
			document.title = `Kassenprüfung - Kassenbuch`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uncheckedTransactionsListItems = useMemo(
		() =>
			uncheckedTransactions.map((transaction) => {
				return (
					<CheckBankAccountListItem
						key={transaction.id}
						transaction={transaction}
						categories={categories}
						receipts={receipts}
						onCheck={(transaction) => {
							dispatch(
								bankAccountTransactionActions.update({
									...transaction,
									isChecked: true,
									hasCheckError: false,
									checkErrorMessage: '',
								}),
							).then(() => dispatch(bankAccountTransactionActions.fetchAll()));
						}}
						onError={(transaction) => {
							setTransactionToError(transaction);
							setErrorTextDialogIsOpen(true);
						}}
					/>
				);
			}),
		[categories, dispatch, receipts, uncheckedTransactions],
	);

	if (!(bankAccount && bankAccount.iban && bankAccount.bic && bankAccount.bank && bankAccount.accountDescription)) {
		return <div>Loading</div>;
	}

	return (
		<>
			<Dialog open={errorTextDialogIsOpen} onClose={didCancelErrorTextDialog}>
				<DialogTitle>Welchen Fehler hast du mit dieser Buchung erkannt?</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="errorText"
						label="Fehlerbeschreibung"
						type="text"
						fullWidth
						variant="standard"
						onChange={(event) => setErrorText(event.target.value)}
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={didCancelErrorTextDialog}>
						Abbrechen
					</Button>
					<Button variant="contained" onClick={didSubmitErrorTextDialog}>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<StyledToolbar
				title={`Kontoprüfung: ${bankAccount?.accountDescription}`}
				description="Auf Beleg klicken um zu vergrößern"
			/>
			<Stack gap={Constants.gap}>
				<StyledPaperListItem
					leading={
						<>
							<Typography variant="subtitle1">IBAN: {bankAccount.iban}</Typography>
							<Typography variant="subtitle1">BIC: {bankAccount.bic}</Typography>
							<Typography variant="subtitle1">Bank: {bankAccount.bank}</Typography>
							<Typography variant="subtitle1">
								Saldo: {calculateBankAccountBalance(transactions)}
							</Typography>
							<Typography variant="subtitle1">Buchungen: {transactions.length}</Typography>
							<Typography variant="subtitle1">
								Ungeprüfte Buchungen: {uncheckedTransactions.length}
							</Typography>
							<Typography sx={{ mt: 1 }} variant="subtitle1">
								Prüffortschritt:
							</Typography>
							<LinearProgressWithLabel
								value={100 - (uncheckedTransactions.length / transactions.length) * 100}
							/>
						</>
					}
				/>
				{uncheckedTransactions.length > 0 && <Typography variant="h5">Ungeprüfte Buchungen</Typography>}
				<Stack gap={Constants.gap}>{uncheckedTransactionsListItems}</Stack>
			</Stack>
		</>
	);
});
