import { createLogExtraActions } from './logs.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import { logExtraReducers } from './logs.extra.reducer';
import type { LogState } from './logs.state';

const name = 'logs';
const initialState: LogState = {
	logs: [],
	statistics: [],
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: logExtraReducers,
});

// Exports
export const logExtraActions = createLogExtraActions();
export const logsActions = { ...slice.actions, ...logExtraActions };
export const logsReducer = slice.reducer;
