import type { BankAccountTransaction } from '../models/BankAccountTransaction';
import type { BargeTransaction } from '../models/BargeTransaction';

export function getIncomingTransactionSum(
	bankAccountTransactions: BankAccountTransaction[],
	bargeTransactions: BargeTransaction[],
) {
	let sum = 0;

	for (const transaction of bankAccountTransactions) {
		if (transaction.amount > 0) {
			sum += transaction.amount;
		}
	}

	for (const transaction of bargeTransactions) {
		if (transaction.amount > 0) {
			sum += transaction.amount;
		}
	}

	return sum;
}
