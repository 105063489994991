import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import React from 'react';
import type { TransactionCategory } from '../../../../../models/TransactionCategory';

export type EditTransactionCategoryFormDataType = {
	name: string;
};

export type EditTransactionCategoryFormProps = {
	transactionCategory: TransactionCategory;
	submitData: (data: EditTransactionCategoryFormDataType) => void;
	didCancel: () => void;
};

export const EditTransactionCategoryForm = ({
	transactionCategory,
	submitData,
	didCancel,
}: EditTransactionCategoryFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			name: transactionCategory.name,
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Name'} isFirstField={true} />}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Bearbeiten
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
