import { createSlice } from '@reduxjs/toolkit';
import { createStandardTransactionCategoryExtraActions } from './standardTransactionCategory.actions';
import { standardTransactionCategoryExtraReducers } from './standardTransactionCategory.reducers';

import type { StandardTransactionCategoryState } from './standardTransactionCategory.state';

const name = 'standardTransactionCategory';
const initialState: StandardTransactionCategoryState = {
	standardTransactionCategories: [],
	byId: {},
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: standardTransactionCategoryExtraReducers,
});

// Exports
export const standardTransactionCategoryExtraActions = createStandardTransactionCategoryExtraActions();
export const standardTransactionCategoryActions = { ...slice.actions, ...standardTransactionCategoryExtraActions };
export const standardTransactionCategoryReducer = slice.reducer;
