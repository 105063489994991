import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAdmin } from '../../../helpers/isAdmin';
import ApiClient from '../../../globals/apiClient';
import type { AuthenticationState } from './authentification.state';
import type { LoginData } from '../../../models/LoginData';

const name = 'authentification';

export const createAuthentificationExtraActions = () => {
	return {
		login: login(),
		reauthenticate: reauthenticate(),
	};

	function login() {
		return createAsyncThunk(
			`${name}/login`,
			async (loginData: LoginData) =>
				await ApiClient.instance.authenticate(loginData.email, loginData.password, loginData.asAdmin),
		);
	}

	function reauthenticate() {
		return createAsyncThunk(`${name}/reauthenticate`, async (state: AuthenticationState) =>
			isAdmin()
				? await ApiClient.instance.client.admins.authRefresh()
				: await ApiClient.instance.client
						.collection('users')
						.authRefresh()
						.then(async (user) => await ApiClient.instance.setLastLogin(user.record.id)),
		);
	}
};
