import { createSlice } from '@reduxjs/toolkit';
import { createUserExtraActions } from './user.extra.actions';
import { userExtraReducers } from './user.extra.reducer';
import type { UserState } from './user.state';

const name = 'users';
const initialState: UserState = {
	users: [],
	byId: {},
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: userExtraReducers,
});

// Exports
export const userExtraActions = createUserExtraActions();
export const userActions = { ...slice.actions, ...userExtraActions };
export const userReducer = slice.reducer;
