import { Add, Check, Clear, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CreateYouthWorkModal } from './CreateYouthWorkModal/CreateYouthWorkModal';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { DeleteConfirmationDialog } from '../../../components/Modals/DeleteConfirmationDialog';
import { EditYouthWorkModal } from './EditYouthWorkModal/EditYouthWorkModal';
import { Strings } from '../../../../globals/Strings';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { userActions } from '../../../../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import type { User } from '../../../../models/User';
import type { YouthWork } from '../../../../models/YouthWork';

export const UserManagement = React.memo(() => {
	const dispatch = useAppDispatch();

	const users = useAppSelector((state) => state.users.users);
	const youthWorks = useAppSelector((state) => state.youthWorks.youthWorks);

	const [showCreateYouthWorkModal, setShowCreateYouthWorkModal] = useState(false);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<User | undefined>(undefined);

	const [showEditYouthWorkModal, setShowEditYouthWorkModal] = useState(false);
	const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
	const [youthWorkToEdit, setYouthWorkToEdit] = useState<YouthWork | undefined>(undefined);

	useEffect(() => {
		document.title = Strings.admin.userManagement.siteTitle;

		dispatch(userActions.fetchAll());
		dispatch(youthWorkActions.fetchAll());
		dispatch(bankAccountActions.fetchAll());
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const didCloseCreateYouthWorkModal = useCallback(() => {
		setShowCreateYouthWorkModal(false);
		dispatch(youthWorkActions.fetchAll());
		dispatch(userActions.fetchAll());
	}, [dispatch]);

	const didCloseConfirmationDialog = useCallback(() => {
		setShowConfirmationDialog(false);
		setItemToDelete(undefined);
	}, []);

	const didConfirmDelete = useCallback(() => {
		setShowConfirmationDialog(false);

		if (itemToDelete) {
			dispatch(userActions.remove(itemToDelete.id!)).then(() => {
				dispatch(userActions.fetchAll());
				dispatch(youthWorkActions.fetchAll());
				dispatch(bankAccountActions.fetchAll());
				dispatch(bankAccountTransactionActions.fetchAll());
				dispatch(transactionCategoryActions.fetchAll());
			});
		}
	}, [itemToDelete, dispatch]);

	const didCloseEditYouthWorkModal = useCallback(() => {
		setShowEditYouthWorkModal(false);
		setUserToEdit(undefined);
		setYouthWorkToEdit(undefined);
		dispatch(youthWorkActions.fetchAll());
		dispatch(userActions.fetchAll());
	}, [dispatch]);

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<DeleteConfirmationDialog
				// eslint-disable-next-line max-len
				customText={Strings.admin.userManagement.deleteYouthWorkConfirmation}
				onDelete={didConfirmDelete}
				onCancel={didCloseConfirmationDialog}
				show={showConfirmationDialog}
			/>
			<CreateYouthWorkModal show={showCreateYouthWorkModal} didClose={didCloseCreateYouthWorkModal} />
			<EditYouthWorkModal
				show={showEditYouthWorkModal}
				didClose={didCloseEditYouthWorkModal}
				initialUser={userToEdit!}
				initialYouthWork={youthWorkToEdit!}
			/>
			<StyledToolbar
				title={Strings.admin.userManagement.heading}
				primaryActions={
					<Button variant="contained" onClick={() => setShowCreateYouthWorkModal(true)} startIcon={<Add />}>
						{Strings.admin.userManagement.createYouthWork}
					</Button>
				}
				description={Strings.admin.userManagement.subheading}
			/>
			<DataGrid
				sx={{ flexGrow: 1 }}
				autoHeight={true}
				rows={users}
				isRowSelectable={() => false}
				hideFooter
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				columns={[
					{
						field: 'youthWork',
						headerName: 'Jugendarbeit',
						width: 200,
						renderCell: ({ row }) => youthWorks.find((youthWork) => youthWork.cashier === row.id)?.name,
					},
					{
						field: 'firstName',
						headerName: 'Vorname',
						width: 200,
					},
					{
						field: 'lastName',
						headerName: 'Nachname',
						width: 200,
					},
					{
						field: 'email',
						headerName: 'E-Mail',
						width: 200,
					},
					{
						field: 'verified',
						headerName: 'Verifiziert',
						width: 90,
						renderCell: ({ row }) => (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
								}}
							>
								{row.verified ? <Check color="success" /> : <Clear color="error" />}
							</Box>
						),
					},
					{
						field: 'lastLogin',
						headerName: 'Letzter Login',
						width: 200,
						renderCell: ({ row }) => {
							if (row.lastLogin) {
								return moment(row.lastLogin).format('DD.MM.YYYY HH:mm:ss');
							}
							return 'Noch nie eingeloggt.';
						},
					},
					{
						field: 'edit',
						headerName: '',
						width: 160,
						renderCell: ({ row }) => (
							<Button
								variant="contained"
								startIcon={<Edit />}
								onClick={() => {
									setUserToEdit(row);
									setYouthWorkToEdit(youthWorks.find((youthWork) => youthWork.cashier === row.id));
									setShowEditYouthWorkModal(true);
								}}
							>
								<Typography variant="body2">Bearbeiten</Typography>
							</Button>
						),
					},
					{
						field: 'delete',
						headerName: '',
						width: 130,
						renderCell: ({ row }) => (
							<Button
								variant="contained"
								color="error"
								startIcon={<Delete />}
								onClick={() => {
									setItemToDelete(row);
									setShowConfirmationDialog(true);
								}}
							>
								<Typography variant="body2">{Strings.global.deleteButtonTitle}</Typography>
							</Button>
						),
					},
				]}
			/>
		</Box>
	);
});
