import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { Receipt } from '../models/Receipt';

const collectionName = 'receipts';

// List
export async function getAllReceipts() {
	Logger.log('ReceiptAPI::getAllReceipts');
	try {
		return await ApiClient.instance.client.collection(collectionName).getFullList<Receipt>();
	} catch (error) {
		Logger.error('ReceiptAPI::getAllReceipts::error::' + error);
		throw error;
	}
}

// View
export async function getReceipt(id: string) {
	Logger.log('ReceiptAPI::getReceipt::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<Receipt>(id);
	} catch (error) {
		Logger.error('ReceiptAPI::getReceipt::error::' + error);
		throw error;
	}
}

// Create
export async function createReceipt(receipt: FormData) {
	Logger.log('ReceiptAPI::createReceipt::' + receipt);
	try {
		return await ApiClient.instance.client.collection(collectionName).create<Receipt>(receipt);
	} catch (error) {
		Logger.error('ReceiptAPI::createReceipt::' + receipt + '::error::' + error);
		throw error;
	}
}

// Delete
export async function deleteReceipt(id: string) {
	Logger.log('ReceiptAPI::deleteReceipt::' + id);
	if (id) {
		try {
			return await ApiClient.instance.client.collection(collectionName).update(id, { transaction: '' });
		} catch (error) {
			Logger.error('ReceiptAPI::deleteReceipt::' + id + '::error::' + error);
			throw error;
		}
	} else {
		throw new Error('ReceiptAPI::deleteReceipt::id is null');
	}
}
