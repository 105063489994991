import { AdminExport } from './ui/Screens/Admin/Export/AdminExport';
import { AdminSidebar } from './ui/Screens/Admin/AdminSidebar';
import { AdminStatistics } from './ui/Screens/Admin/AdminStatistics/AdminStatistics';
import { BankAccountDetailView } from './ui/Screens/User/BankAccountDetailView/BankAccountDetailView';
import { BargeDetailView } from './ui/Screens/User/BargeDetailView/BargeDetailView';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CheckBankAccount } from './ui/Screens/User/CheckBarges/CheckBankAccount';
import { CheckBarge } from './ui/Screens/User/CheckBarges/CheckBarge';
import { CheckBargeOverview } from './ui/Screens/Admin/CheckBargeOverview/CheckBargeOverview';
import { Dashboard } from './ui/Screens/User/Dashboard/Dashboard';
import { Export } from './ui/Screens/User/Export/Export';
import { LoginScreen } from './ui/Login/LoginScreen';
import { Logs } from './ui/Screens/Admin/Logs/Logs';
import { StandardTransactionCategoryScreen } from './ui/Screens/Admin/StandardTransactionCategories/StandardTransactionCategoryScreen';
import { TransactionCategoryScreen } from './ui/Screens/User/TransactionCategory/TransactionCategoryScreen';
import { UserManagement } from './ui/Screens/Admin/UserManagement/UserManagement';
import { UserSidebar } from './ui/Screens/User/UserSidebar/UserSidebar';
import { authActions } from './redux/reducers/authentificationReducer/authentification.slice';
import { fetchMasterData } from './helpers/fetchHelpers/fetchMasterData';
import { isAdmin } from './helpers/isAdmin';
import { isAuthenticated } from './helpers/isAuthenticated';
import { realtimeSubscribe, realtimeUnsubscribe } from './helpers/realtimeSubscribe';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

const App = () => {
	const isAuthenticatedMemo = useMemo(() => isAuthenticated(), []);
	const isAdminMemo = useMemo(() => isAdmin(), []);
	const authenticationState = useAppSelector((state) => state.authentication);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isAuthenticatedMemo) {
			fetchMasterData();
			dispatch(authActions.reauthenticate(authenticationState));
		}

		realtimeSubscribe();

		moment.locale('de');

		return () => realtimeUnsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isAuthenticatedMemo) {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<LoginScreen />} />
					<Route path="*" element={<Navigate to="/login" />} />
				</Routes>
			</BrowserRouter>
		);
	}

	if (isAdminMemo) {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<Navigate to="/" />} />
					<Route
						path="/admin/user-management"
						element={
							<AdminSidebar>
								<UserManagement />
							</AdminSidebar>
						}
					/>
					<Route
						path="/admin/export"
						element={
							<AdminSidebar>
								<AdminExport />
							</AdminSidebar>
						}
					/>
					<Route
						path="/admin/logs"
						element={
							<AdminSidebar>
								<Logs />
							</AdminSidebar>
						}
					/>
					<Route
						path="/admin/statistics"
						element={
							<AdminSidebar>
								<AdminStatistics />
							</AdminSidebar>
						}
					/>
					<Route
						path="/admin/check-barges-overview"
						element={
							<AdminSidebar>
								<CheckBargeOverview />
							</AdminSidebar>
						}
					/>
					<Route
						path="/admin/standard-transaction-categories"
						element={
							<AdminSidebar>
								<StandardTransactionCategoryScreen />
							</AdminSidebar>
						}
					/>
					<Route path="/" element={<Navigate to="/admin/user-management" replace />} />
				</Routes>
			</BrowserRouter>
		);
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Navigate to="/" />} />
				<Route
					path="/"
					element={
						<UserSidebar>
							<Dashboard />
						</UserSidebar>
					}
				/>
				<Route
					path="/check/barges/:id"
					element={
						<UserSidebar>
							<CheckBarge />
						</UserSidebar>
					}
				/>
				<Route
					path="/check/bankAccounts/:id"
					element={
						<UserSidebar>
							<CheckBankAccount />
						</UserSidebar>
					}
				/>
				<Route
					path="/export"
					element={
						<UserSidebar>
							<Export />
						</UserSidebar>
					}
				/>
				<Route
					path="/bankAccounts/:id"
					element={
						<UserSidebar>
							<BankAccountDetailView />
						</UserSidebar>
					}
				/>
				<Route
					path="/barges/:id"
					element={
						<UserSidebar>
							<BargeDetailView />
						</UserSidebar>
					}
				/>
				<Route
					path="/transactionCategories"
					element={
						<UserSidebar>
							<TransactionCategoryScreen />
						</UserSidebar>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
