import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { Constants } from '../../../../globals/Constants';
import { CreateTransactionCategoryModal } from './CreateTransactionCategoryModal/CreateTransactionCategoryModal';
import { DeleteConfirmationDialog } from '../../../components/Modals/DeleteConfirmationDialog';
import { EditTransactionCategoryModal } from './EditTransactionCategoryModal/EditTransactionCategoryModal';
import { Stack } from '@mui/system';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { TransactionCategory } from '../../../../models/TransactionCategory';

export const TransactionCategoryScreen = React.memo(() => {
	const dispatch = useAppDispatch();

	const transactionCategories = useAppSelector((state) => state.transactionCategories.transactionCategories);

	const [showCreateTransactionCategoryModal, setShowCreateTransactionCategoryModal] = useState(false);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [categoryToDelete, setCategoryToDelete] = useState<TransactionCategory | undefined>(undefined);

	const [showEditTransactionCategoryModal, setShowEditTransactionCategoryModal] = useState(false);
	const [categoryToEdit, setCategoryToEdit] = useState<TransactionCategory | undefined>(undefined);

	useEffect(() => {
		document.title = 'Kategorien - Kassenbuch';

		dispatch(transactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const didCloseCreateTransactionCategoryModal = useCallback(() => {
		setShowCreateTransactionCategoryModal(false);
		dispatch(transactionCategoryActions.fetchAll());
	}, [dispatch]);

	const didCloseConfirmationDialogWithoutDelete = useCallback(() => {
		setShowConfirmationDialog(false);
		setCategoryToDelete(undefined);
	}, []);

	const didCloseConfirmationDialogWithDelete = useCallback(() => {
		if (categoryToDelete) {
			dispatch(transactionCategoryActions.remove(categoryToDelete.id!));
		}
		setShowConfirmationDialog(false);
		setCategoryToDelete(undefined);
	}, [categoryToDelete, dispatch]);

	const didCloseEditTransactionCategoryModal = useCallback(() => {
		setShowEditTransactionCategoryModal(false);
		setCategoryToEdit(undefined);
		dispatch(transactionCategoryActions.fetchAll());
	}, [dispatch]);

	const listItems = useMemo(() => {
		return transactionCategories.map((transactionCategory) => {
			return (
				<StyledPaperListItem
					key={transactionCategory.id}
					leading={
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Typography variant="h6">{transactionCategory.name}</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								{transactionCategory.isStandard ? null : (
									<>
										<Button
											variant="contained"
											color="primary"
											onClick={() => {
												setCategoryToEdit(transactionCategory);
												setShowEditTransactionCategoryModal(true);
											}}
										>
											Bearbeiten
										</Button>
										<Button
											sx={{ ml: 1 }}
											variant="contained"
											color="error"
											onClick={() => {
												setShowConfirmationDialog(true);
												setCategoryToDelete(transactionCategory);
											}}
										>
											Löschen
										</Button>
									</>
								)}
							</Box>
						</Box>
					}
				/>
			);
		});
	}, [transactionCategories]);

	return (
		<>
			<DeleteConfirmationDialog
				itemToDeleteTitle={categoryToDelete?.name ?? ''}
				onDelete={didCloseConfirmationDialogWithDelete}
				onCancel={didCloseConfirmationDialogWithoutDelete}
				show={showConfirmationDialog}
			/>
			<EditTransactionCategoryModal
				transactionCategory={categoryToEdit!}
				didClose={didCloseEditTransactionCategoryModal}
				show={showEditTransactionCategoryModal}
			/>
			<CreateTransactionCategoryModal
				didClose={didCloseCreateTransactionCategoryModal}
				show={showCreateTransactionCategoryModal}
			/>
			<StyledToolbar
				title="Kategorien"
				primaryActions={
					<Button
						variant="contained"
						color="primary"
						sx={{ ml: 2, color: 'white' }}
						startIcon={<Add />}
						onClick={() => setShowCreateTransactionCategoryModal(true)}
					>
						Kategorie hinzufügen
					</Button>
				}
			/>
			<Stack gap={Constants.gap}>{listItems}</Stack>
		</>
	);
});
