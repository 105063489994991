import { useAppSelector } from '../../redux/hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';

export const ErrorSnackBar = React.memo(() => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const authenticationError = useAppSelector((state) => state.authentication.error);
	const adminError = useAppSelector((state) => state.admins.error);
	const bankAccountError = useAppSelector((state) => state.bankAccounts.error);
	const bankAccountTransactionError = useAppSelector((state) => state.bankAccountTransactions.error);
	const receiptError = useAppSelector((state) => state.receipts.error);
	const transactionCategoryError = useAppSelector((state) => state.transactionCategories.error);
	const userError = useAppSelector((state) => state.users.error);
	const bargeError = useAppSelector((state) => state.barges.error);
	const bargeTransactionError = useAppSelector((state) => state.bargeTransactions.error);

	const errors = useMemo(
		() => [
			authenticationError,
			adminError,
			bankAccountError,
			bankAccountTransactionError,
			receiptError,
			transactionCategoryError,
			userError,
			bargeError,
			bargeTransactionError,
		],
		[
			adminError,
			authenticationError,
			bankAccountError,
			bankAccountTransactionError,
			bargeError,
			bargeTransactionError,
			receiptError,
			transactionCategoryError,
			userError,
		],
	);

	useEffect(() => {
		errors.forEach((error) => {
			if (error) {
				if (!error.includes('autocancelled')) {
					if (error.includes('Failed to authenticate.')) {
						enqueueSnackbar(
							'Bitte überprüfen sie Ihren Benutzernamen und Ihr Passwort. Falls Sie sich als Kassier anmelden wollen, bitte haken Sie den Haken "Als Admin anmelden" nicht an!',
							{
								variant: 'error',
							},
						);
					} else {
						enqueueSnackbar(error, {
							variant: 'error',
						});
					}
				}
			}
		});
	}, [errors, enqueueSnackbar, closeSnackbar]);

	return null;
});
