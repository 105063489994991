import { adminExtraReducers } from './admin.extra.reducers';
import { createAdminExtraActions } from './admin.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { AdminState } from './admin.state';

const name = 'admins';
const initialState: AdminState = {
	admins: [],
	error: null,
	byId: {},
	isLoading: false,
};
const reducers = {};

export const extraAdminActions = createAdminExtraActions();

const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: adminExtraReducers,
});

export const adminActions = { ...slice.actions, ...extraAdminActions };
export const adminReducer = slice.reducer;
