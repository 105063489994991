import { StyledPaperGridItem } from '../../../../components/Styled/StyledPaperGridItem';
import { Typography } from '@mui/material';
import { calculateBankAccountBalance } from '../../../../../helpers/calculateBankAccountBalance';
import { calculateBargeBalance } from '../../../../../helpers/calculateBargeBalance';
import { useAppSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import type { BankAccount } from '../../../../../models/BankAccount';
import type { Barge } from '../../../../../models/Barge';

export type DashboardGridItemProps = {
	bankAccount?: BankAccount;
	barge?: Barge;
};

export const DashboardGridItem = React.memo(({ bankAccount, barge }: DashboardGridItemProps) => {
	const navigate = useNavigate();

	const bankAccountTransactions = useAppSelector((state) =>
		state.bankAccountTransactions.bankAccountTransactions.filter(
			(transaction) => transaction.bankAccount === bankAccount?.id,
		),
	);

	const bargeTransactions = useAppSelector((state) =>
		state.bargeTransactions.bargeTransactions.filter((transaction) => transaction.barge === barge?.id),
	);

	const bankAccountBalance = useMemo(
		() => calculateBankAccountBalance(bankAccountTransactions),
		[bankAccountTransactions],
	);
	const bargeBalance = useMemo(() => calculateBargeBalance(bargeTransactions), [bargeTransactions]);

	return (
		<>
			{bankAccount && (
				<StyledPaperGridItem
					key={`dashboard-bankAccount-${bankAccount.id}`}
					onClick={() => navigate(`/bankAccounts/${bankAccount.id}`)}
				>
					<Typography variant="h6">{bankAccount?.accountDescription}</Typography>
					<Typography>{`Stand: ${bankAccountBalance}`}</Typography>
				</StyledPaperGridItem>
			)}
			{barge && (
				<StyledPaperGridItem
					key={`dashboard-barge-${barge.id}`}
					onClick={() => navigate(`/barges/${barge.id}`)}
				>
					<Typography variant="h6">{barge?.accountDescription}</Typography>
					<Typography>{`Stand: ${bargeBalance}`}</Typography>
				</StyledPaperGridItem>
			)}
		</>
	);
});
