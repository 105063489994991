import { Box, Button, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import { LocalizedDatePickerFormField } from '../../../../components/LocalizedDatePickerFormField';
import { NumericFormat } from 'react-number-format';
import { Upload } from '@mui/icons-material';
import { useAppSelector } from '../../../../../redux/hooks';
import React from 'react';

export type CreateBankAccountTransactionFormDataType = {
	bankStatement: string;
	date: number;
	amount: number;
	description: string;
	isIncoming: boolean;
	person: string;
	transactionCategory: string;
	receiptFormData: FormData;
};

export type CreateBankAccountTransactionFormProps = {
	submitData: (data: CreateBankAccountTransactionFormDataType) => void;
	didCancel: () => void;
};

export const CreateBankAccountTransactionForm = ({ submitData, didCancel }: CreateBankAccountTransactionFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			bankStatement: '',
			date: Date.now(),
			amount: 0.0,
			description: '',
			isIncoming: false,
			person: '',
			transactionCategory: '',
			receiptFormData: new FormData(),
		},
	});

	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);

	const receiptFormData = new FormData();

	const onSubmit = () => {
		const data = getValues();

		data.receiptFormData = receiptFormData;

		submitData(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="bankStatement"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Kontoauszug'} isFirstField={true} />}
				/>
				<Controller
					name="date"
					control={control}
					render={({ field }) => <LocalizedDatePickerFormField label="Datum" field={field} />}
				/>
				<Controller
					name="amount"
					control={control}
					render={({ field }) => (
						<NumericFormat
							onValueChange={(values) => {
								field.onChange(values.floatValue);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale
							allowNegative={true}
							customInput={TextField}
							suffix=" €"
							required={true}
							label="Betrag"
						/>
					)}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field }) => <FormTextField label="Beschreibung" field={field} />}
				/>
				<Controller
					name="person"
					control={control}
					render={({ field }) => <FormTextField label="Person/Klient" field={field} />}
				/>
				<Controller
					name="transactionCategory"
					control={control}
					render={({ field }) => (
						<TextField label="Kategorie" select {...field}>
							{categories.map((category) => (
								<MenuItem key={category.id} value={category.id}>
									{category.name}
								</MenuItem>
							))}
						</TextField>
					)}
				/>
				<label htmlFor="imageUpload">
					<input
						id="imageUpload"
						hidden
						accept="image/*"
						type="file"
						onChange={(event) => {
							receiptFormData.append('image', event.target.files![0]);
						}}
					/>
					<Button variant="contained" component="span" startIcon={<Upload />}>
						Beleg Hochladen
					</Button>
				</label>
				<Box>
					<Button variant="contained" type="submit">
						Hinzufügen
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
