import { getLocalizedAmount } from '../getLocalizedAmount';
import { utils } from 'xlsx';
import moment from 'moment';
import type { Barge } from '../../models/Barge';
import type { BargeTransaction } from '../../models/BargeTransaction';
import type { TransactionCategory } from '../../models/TransactionCategory';

export const generateExcelSheetFromBarge = (
	barge: Barge,
	transactions: BargeTransaction[],
	transactionCategories: TransactionCategory[],
) => {
	const adjustedTransactions = transactions.map((transaction) => {
		return {
			Datum: moment(transaction.date).format('DD.MM.YYYY'),
			Betrag: getLocalizedAmount(transaction.amount),
			Beschreibung: transaction.description,
			Kategorie: transactionCategories.find((category) => category.id === transaction.transactionCategory)?.name,
			Person: transaction.person,
			Kassenprüfung: transaction.isChecked ? 'Geprüft' : 'Nicht geprüft',
		};
	});

	return utils.json_to_sheet(adjustedTransactions);
};
