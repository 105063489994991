import { createAsyncThunk } from '@reduxjs/toolkit';
import { createBarge, deleteBarge, getAllBarges, getBarge, updateBarge } from '../../../api_clients/bargeAPIClient';
import type { Barge } from '../../../models/Barge';

const name = 'barges';

export const createBargeExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllBarges());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getBarge(id));
	}

	function create() {
		return createAsyncThunk(`${name}/create`, async (barge: Barge) => await createBarge(barge));
	}

	function update() {
		return createAsyncThunk(`${name}/update`, async (barge: Barge) => await updateBarge(barge));
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteBarge(id));
	}
};
