import { Box } from '@mui/system';
import { Chip, Stack, Typography } from '@mui/material';
import { Constants } from '../../../../globals/Constants';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { logsActions } from '../../../../redux/reducers/LogsReducer/logs.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

export const Logs = React.memo(() => {
	const dispatch = useAppDispatch();

	const [filterParameters, setFilterParameters] = useState<number[]>([]);

	const logs = useAppSelector((state) => state.logs.logs);

	useEffect(() => {
		document.title = 'Logs - Kassenbuch';

		dispatch(logsActions.fetchLogs());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredLogs = useMemo(() => {
		if (filterParameters.length !== 0) {
			return logs.filter((log) => filterParameters.findIndex((parameter) => parameter === log.status) !== -1);
		}
		return logs;
	}, [filterParameters, logs]);

	const listEntries = useMemo(() => {
		return filteredLogs.map((log) => (
			<StyledPaperListItem
				key={log.id}
				leading={
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Typography
								color={
									log.status === 400 || log.status === 403 || log.status === 404 ? 'error' : 'success'
								}
							>
								HTTP-Code: {log.status}
							</Typography>
						</Box>
						<Typography>User Agent: {log.userAgent}</Typography>
						<Typography>Method: {log.method}</Typography>
						<Typography>URL: {log.url}</Typography>
						<Typography>User IP: {log.userIp}</Typography>
						<Typography>Date: {moment(log.created).format('YYYY-MM-DD HH:MM:SS')}</Typography>
					</>
				}
			/>
		));
	}, [filteredLogs]);

	const filterChips = useMemo(() => {
		return [
			<Chip
				sx={{ mr: 1 }}
				key={200}
				label="Successful (200)"
				color={filterParameters.includes(200) ? 'primary' : 'default'}
				onClick={() => {
					if (filterParameters.includes(200)) {
						setFilterParameters(filterParameters.filter((param) => param !== 200));
					} else {
						setFilterParameters([...filterParameters, 200]);
					}
				}}
			/>,
			<Chip
				sx={{ mr: 1 }}
				key={204}
				label="No Content but successfull (204)"
				color={filterParameters.includes(204) ? 'primary' : 'default'}
				onClick={() => {
					if (filterParameters.includes(204)) {
						setFilterParameters(filterParameters.filter((param) => param !== 204));
					} else {
						setFilterParameters([...filterParameters, 204]);
					}
				}}
			/>,
			<Chip
				sx={{ mr: 1 }}
				key={400}
				label="Server Error (400)"
				color={filterParameters.includes(400) ? 'primary' : 'default'}
				onClick={() => {
					if (filterParameters.includes(400)) {
						setFilterParameters(filterParameters.filter((param) => param !== 400));
					} else {
						setFilterParameters([...filterParameters, 400]);
					}
				}}
			/>,
			<Chip
				key={403}
				label="No Permission (403)"
				color={filterParameters.includes(403) ? 'primary' : 'default'}
				onClick={() => {
					if (filterParameters.includes(403)) {
						setFilterParameters(filterParameters.filter((param) => param !== 403));
					} else {
						setFilterParameters([...filterParameters, 403]);
					}
				}}
			/>,
		];
	}, [filterParameters]);

	return (
		<>
			<StyledToolbar
				title="Request Logs"
				description="Hier werden alle Requests aufgelistet, die an den Server gesendet wurden."
			/>
			<Typography variant="h6">
				Showing: {filteredLogs.length} / {logs.length}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'start',
					alignContent: 'start',
					alignItems: 'start',
					mt: 1,
				}}
			>
				<Typography variant="h6" mr={2}>
					Filter:{' '}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							xs: 'column',
							sm: 'column',
							md: 'row',
							lg: 'row',
							xl: 'row',
						},
						justifyContent: 'start',
						alignContent: 'start',
						alignItems: 'start',
					}}
				>
					{filterChips}
				</Box>
			</Box>
			<Stack gap={Constants.gap}>{listEntries}</Stack>
		</>
	);
});
