import { bankAccountTransactionExtraActions } from './bankAccountTransaction.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { BankAccountTransaction } from '../../../models/BankAccountTransaction';
import type { BankAccountTransactionState } from './bankAccountTransaction.state';

export const bankAccountTransactionExtraReducers = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	// FetchAll
	builder.addCase(bankAccountTransactionExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccountTransaction::fetch::pending');
	});
	builder.addCase(bankAccountTransactionExtraActions.fetchAll.fulfilled, (state, action) => {
		const payload = action.payload;
		state.bankAccountTransactions = payload;

		state.byId = payload.reduce((map: Record<string, BankAccountTransaction>, bankAccountTransaction) => {
			map[bankAccountTransaction.id!] = bankAccountTransaction;

			return map;
		}, {});

		state.byBankAccount = payload.reduce(
			(map: Record<string, BankAccountTransaction[]>, bankAccountTransaction) => {
				if (bankAccountTransaction.bankAccount) {
					if (!map[bankAccountTransaction.bankAccount]) {
						map[bankAccountTransaction.bankAccount] = [];
					}
					map[bankAccountTransaction.bankAccount].push(bankAccountTransaction);
				}
				return map;
			},
			{},
		);

		state.isLoading = false;
		Logger.log('BankAccountTransaction::fetch::success');
	});
	builder.addCase(bankAccountTransactionExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccountTransaction::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	// Fetch
	builder.addCase(bankAccountTransactionExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccountTransaction::fetch::pending');
	});
	builder.addCase(bankAccountTransactionExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;
		if (
			state.bankAccountTransactions.findIndex(
				(bankAccountTransaction) => bankAccountTransaction.id === action.payload.id,
			) >= 0
		) {
			state.bankAccountTransactions = state.bankAccountTransactions.map((bankAccountTransaction) => {
				if (bankAccountTransaction.id === payload.id) {
					return payload;
				} else {
					return bankAccountTransaction;
				}
			});
		} else {
			state.bankAccountTransactions.push(payload);
		}

		if (payload.id) {
			state.byId[payload.id] = payload;

			if (payload.bankAccount) {
				state.byBankAccount[payload.bankAccount] = state.byBankAccount[payload.bankAccount] || [];

				if (state.byBankAccount[payload.id].findIndex((transaction) => transaction.id === payload.id) < 0) {
					state.byBankAccount[payload.bankAccount].push(payload);
				}
			}
		}

		state.isLoading = false;
		Logger.log('BankAccountTransaction::fetch::success');
	});
	builder.addCase(bankAccountTransactionExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccountTransaction::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	// Create
	builder.addCase(bankAccountTransactionExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccountTransaction::create::pending');
	});
	builder.addCase(bankAccountTransactionExtraActions.create.fulfilled, (state, action) => {
		state.bankAccountTransactions.push(action.payload);
		state.isLoading = false;
		Logger.log('BankAccountTransaction::create::success');
	});
	builder.addCase(bankAccountTransactionExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccountTransaction::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	// Update
	builder.addCase(bankAccountTransactionExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccountTransaction::update::pending');
	});
	builder.addCase(bankAccountTransactionExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.bankAccountTransactions = state.bankAccountTransactions.map((bankAccountTransaction) => {
			if (bankAccountTransaction.id === payload.id) {
				return payload;
			} else {
				return bankAccountTransaction;
			}
		});

		state.isLoading = false;
		Logger.log('BankAccountTransaction::update::success');
	});
	builder.addCase(bankAccountTransactionExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		Logger.error('BankAccountTransaction::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<BankAccountTransactionState>) => {
	// Delete
	builder.addCase(bankAccountTransactionExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BankAccountTransaction::delete::pending');
	});
	builder.addCase(bankAccountTransactionExtraActions.remove.fulfilled, (state, action) => {
		state.isLoading = false;
		Logger.log('BankAccountTransaction::delete::success');
	});
	builder.addCase(bankAccountTransactionExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BankAccountTransaction::delete::error::' + action.error.message);
	});
};
