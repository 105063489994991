import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';

export type DeleteConfirmationDialogProps = {
	itemToDeleteTitle?: string;
	onDelete: () => void;
	onCancel: () => void;
	show: boolean;
	customText?: string;
};

export const DeleteConfirmationDialog = React.memo(
	({ itemToDeleteTitle, onDelete, onCancel, show, customText }: DeleteConfirmationDialogProps) => {
		return (
			<Dialog open={show} onClose={onCancel}>
				<DialogTitle id="alert-dialog-title">
					{customText ? customText : `Möchtest du ${itemToDeleteTitle} unwiederruflich löschen?`}
				</DialogTitle>
				<DialogActions>
					<Button variant="contained" onClick={onCancel}>
						Nein
					</Button>
					<Button variant="contained" onClick={onDelete} autoFocus color="error">
						Ja, Löschen
					</Button>
				</DialogActions>
			</Dialog>
		);
	},
);
