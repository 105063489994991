import '../../../../assets/fonts/Montserrat/Montserrat.ttf';
import { AccountBalanceWallet, Add, Home, ImportExport, Sort } from '@mui/icons-material';
import { CreateBankAccountModal } from '../CreateBankAccountModal/CreateBankAccountModal';
import { CreateBargeModal } from '../CreateBargeModal/CreateBargeModal';
import { Divider, List, ListSubheader } from '@mui/material';
import { ResponsiveContext } from '../../../ResponsiveContext/ResponsiveContext';
import { SidebarLink } from '../../../ResponsiveContext/SidebarLink';
import { StyledListItemSubHeaderWithButton } from '../../../components/Styled/StyledListItemSubHeaderWithButton';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bargeActions } from '../../../../redux/reducers/bargeReducer/barge.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export type UserSidebarProps = {
	children: React.ReactNode;
};

export const UserSidebar = React.memo(({ children }: UserSidebarProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const logo = require('../../../../assets/media/logo-primary.png');

	const bankAccounts = useAppSelector((state) => state.bankAccounts.bankAccounts);
	const barges = useAppSelector((state) => state.barges.barges);

	const didCloseCreateBankAccountModal = useCallback(() => {
		setShowCreateBankAccountModal(false);
	}, []);
	const [showCreateBankAccountModal, setShowCreateBankAccountModal] = useState(false);

	const didCloseCreateBargeModal = useCallback(() => {
		setShowCreateBargeModal(false);
	}, []);
	const [showCreateBargeModal, setShowCreateBargeModal] = useState(false);

	useEffect(() => {
		dispatch(bankAccountActions.fetchAll());
		dispatch(bargeActions.fetchAll());
		dispatch(youthWorkActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const drawer = useMemo(() => {
		return (
			<div>
				<img
					src={logo}
					alt="SWD-EC e.V. Logo"
					style={{
						padding: '25px',
						paddingBottom: '15px',
					}}
				/>
				<List>
					<SidebarLink
						key="dashboard"
						icon={<Home />}
						text="Dashboard"
						path={'/'}
						onClick={() => navigate('/')}
					/>
					<Divider />
					<StyledListItemSubHeaderWithButton
						icon={<Add />}
						text={'Bankkonten'}
						onClick={() => setShowCreateBankAccountModal(true)}
					/>
					{bankAccounts.map((bankAccount) => (
						<SidebarLink
							key={bankAccount.id}
							icon={<AccountBalanceWallet />}
							text={bankAccount.accountDescription}
							path={`/bankAccounts/${bankAccount.id}`}
							onClick={() => navigate(`/bankAccounts/${bankAccount.id}`)}
						/>
					))}
					<Divider />
					<StyledListItemSubHeaderWithButton
						icon={<Add />}
						text={'Barkassen'}
						onClick={() => setShowCreateBargeModal(true)}
					/>
					{barges.map((barge) => (
						<SidebarLink
							key={barge.id}
							icon={<AccountBalanceWallet />}
							text={barge.accountDescription}
							path={`/barges/${barge.id}`}
							onClick={() => navigate(`/barges/${barge.id}`)}
						/>
					))}
					<Divider />
					<ListSubheader key="transactionCategoriesHeading">Buchungskategorien</ListSubheader>
					<SidebarLink
						key="transactionCategories"
						icon={<Sort />}
						text="Buchungskategorien"
						path={'/transactionCategories'}
						onClick={() => navigate('/transactionCategories')}
					/>
					<ListSubheader key="checking">Kassenprüfung</ListSubheader>
					{bankAccounts.map((bankAccount) => (
						<SidebarLink
							key={bankAccount.id}
							icon={<AccountBalanceWallet />}
							text={bankAccount.accountDescription}
							path={`/check/bankAccounts/${bankAccount.id}`}
							onClick={() => navigate(`/check/bankAccounts/${bankAccount.id}`)}
						/>
					))}
					{barges.map((barge) => (
						<SidebarLink
							key={barge.id}
							icon={<AccountBalanceWallet />}
							text={barge.accountDescription}
							path={`/check/barges/${barge.id}`}
							onClick={() => navigate(`/check/barges/${barge.id}`)}
						/>
					))}
					<ListSubheader key="other">Sonstiges</ListSubheader>
					<SidebarLink
						key="export"
						icon={<ImportExport />}
						text="Datenexport"
						path={'/export'}
						onClick={() => navigate('/export')}
					/>
				</List>
			</div>
		);
	}, [bankAccounts, barges, logo, navigate]);

	return (
		<>
			<CreateBankAccountModal didClose={didCloseCreateBankAccountModal} show={showCreateBankAccountModal} />
			<CreateBargeModal didClose={didCloseCreateBargeModal} show={showCreateBargeModal} />
			<ResponsiveContext drawer={drawer}>{children}</ResponsiveContext>
		</>
	);
});
