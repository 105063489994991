import 'moment/locale/de';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import React from 'react';

export type LocalizedDatePickerFormFieldProps = {
	field: any;
	label: string;
	isFirstField?: boolean;
};

export const LocalizedDatePickerFormField = React.memo(
	({ field, label, isFirstField = false }: LocalizedDatePickerFormFieldProps) => {
		return (
			<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
				<DatePicker
					label={label}
					renderInput={(params) => <TextField autoFocus={isFirstField} {...params} />}
					{...field}
				/>
			</LocalizationProvider>
		);
	},
);
