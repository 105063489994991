import { createSlice } from '@reduxjs/toolkit';
import { createYouthworkExtraActions } from './youthWork.extra.actions';
import { youthWorkExtraReducers } from './youthWork.extra.reducers';
import type { YouthWorkState } from './youthWork.state';

const name = 'youthWorks';
const initialState: YouthWorkState = {
	youthWorks: [],
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: youthWorkExtraReducers,
});

// Exports
export const youthWorkExtraActions = createYouthworkExtraActions();
export const youthWorkActions = { ...slice.actions, ...youthWorkExtraActions };
export const YouthWorkReducer = slice.reducer;
