import { CreateBargeTransactionForm } from './CreateBargeTransactionForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { bargeTransactionActions } from '../../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { Barge } from '../../../../../models/Barge';
import type { CreateBargeTransactionFormDataType } from './CreateBargeTransactionForm';

export type CreateBargeTransactionModalProps = {
	barge: Barge;
	didClose: () => void;
	show: boolean;
};

export const CreateBargeTransactionModal = React.memo(({ barge, didClose, show }: CreateBargeTransactionModalProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(transactionCategoryActions.fetchAll());
	}, [dispatch]);

	return (
		<StyledModal show={show} onClose={didClose} heading="Buchung Erstellen">
			<CreateBargeTransactionForm
				submitData={function (data: CreateBargeTransactionFormDataType): void {
					if (barge.id) {
						dispatch(
							bargeTransactionActions.create({
								date: moment(data.date).format('YYYY-MM-DD HH:mm:ss'),
								amount: data.amount,
								description: data.description,
								person: data.person,
								barge: barge.id,
								transactionCategory: data.transactionCategory,
							}),
						).then((action) => {
							if (action.payload) {
								didClose();
							}
						});
					}
				}}
				didCancel={didClose}
			/>
		</StyledModal>
	);
});
