import type { Changelog } from './ChangelogTypes';

export const generateChangelog = (): string => {
	const changelog = require('./changelog.json') as Changelog;
	let markdown = '';

	changelog.items.forEach((item) => {
		markdown += `##### Version ${item.version}\n\n`;
		if (item.features && item.features?.length > 0) {
			markdown += `###### Features\n\n`;
			item.features.forEach((feature) => {
				markdown += `- ${feature}\n`;
			});
			markdown += '\n';
		}

		if (item.bugfixes && item.bugfixes?.length > 0) {
			markdown += `###### Bugfixes\n\n`;
			item.bugfixes.forEach((bugfix) => {
				markdown += `- ${bugfix}\n`;
			});
			markdown += '\n';
		}

		if (item.improvements && item.improvements?.length > 0) {
			markdown += `###### Verbesserungen\n\n`;
			item.improvements.forEach((improvement) => {
				markdown += `- ${improvement}\n`;
			});
			markdown += '\n';
		}
	});

	return markdown;
};
