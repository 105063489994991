import type { RootState } from '../redux/store';

export const isLoadingUser = (state: RootState) => {
	return (
		state.authentication.isLoading ||
		state.bankAccountTransactions.isLoading ||
		state.bankAccounts.isLoading ||
		state.bargeTransactions.isLoading ||
		state.barges.isLoading ||
		state.receipts.isLoading ||
		state.transactionCategories.isLoading ||
		state.youthWorks.isLoading
	);
};

export const isLoadingAdmin = (state: RootState) => {
	return (
		isLoadingUser(state) ||
		state.logs.isLoading ||
		state.standardTransactionCategories.isLoading ||
		state.users.isLoading ||
		state.admins.isLoading
	);
};
