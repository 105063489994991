import { Box } from '@mui/system';
import { DashboardGridItem } from './components/DashboardGridItem';
import { DashboardTransactionCategoryView } from './components/DashboardTransactionCategoryView';
import { Paper, Stack, Typography } from '@mui/material';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { getLocalizedAmount } from '../../../../helpers/getLocalizedAmount';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import ApiClient from '../../../../globals/apiClient';
import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo } from 'react';

export const Dashboard = React.memo(() => {
	const dispatch = useAppDispatch();

	const bankAccounts = useAppSelector((state) => state.bankAccounts.bankAccounts);
	const bankAccountTransactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions);
	const barges = useAppSelector((state) => state.barges.barges);
	const bargeTransactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions);
	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);
	const youthWork = useAppSelector((state) =>
		state.youthWorks.youthWorks.find(
			(youthWork) => youthWork.cashier === ApiClient.instance.client.authStore.model?.id,
		),
	);

	useEffect(() => {
		document.title = 'Dashboard - Kassenbuch';

		dispatch(bankAccountActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(youthWorkActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const bankAccountItems = useMemo(() => {
		return bankAccounts.map((bankAccount) => (
			<Grid item xs={6} key={bankAccount.id}>
				<DashboardGridItem bankAccount={bankAccount} />
			</Grid>
		));
	}, [bankAccounts]);

	const bargeItems = useMemo(() => {
		return barges.map((barge) => (
			<Grid item xs={6} key={barge.id}>
				<DashboardGridItem barge={barge} />
			</Grid>
		));
	}, [barges]);

	const incomingCategoryItems = useMemo(() => {
		return categories.map((category) => (
			<DashboardTransactionCategoryView key={category.id} transactionCategory={category} direction="incoming" />
		));
	}, [categories]);

	const outgoingCategoryItems = useMemo(() => {
		return categories.map((category) => (
			<DashboardTransactionCategoryView key={category.id} transactionCategory={category} direction="outgoing" />
		));
	}, [categories]);

	const incomingSum = useMemo(
		() =>
			getLocalizedAmount(
				bankAccountTransactions
					.filter((bankAccountTransaction) => bankAccountTransaction.amount > 0)
					.reduce(
						(sum, bankAccountTransaction) =>
							bankAccountTransaction.description !== 'Eröffnungskontostand'
								? sum + bankAccountTransaction.amount
								: sum,
						0,
					) +
					bargeTransactions
						.filter((bargeTransaction) => bargeTransaction.amount > 0)
						.reduce(
							(sum, bargeTransaction) =>
								bargeTransaction.description !== 'Eröffnungswert' ? sum + bargeTransaction.amount : sum,
							0,
						),
			),
		[bankAccountTransactions, bargeTransactions],
	);

	const outgoingSum = useMemo(
		() =>
			getLocalizedAmount(
				bankAccountTransactions
					.filter((bankAccountTransaction) => bankAccountTransaction.amount < 0)
					.reduce(
						(sum, bankAccountTransaction) =>
							bankAccountTransaction.description !== 'Eröffnungskontostand'
								? sum + bankAccountTransaction.amount
								: sum,
						0,
					) +
					bargeTransactions
						.filter((bargeTransaction) => bargeTransaction.amount < 0)
						.reduce(
							(sum, bargeTransaction) =>
								bargeTransaction.description !== 'Eröffnungswert' ? sum + bargeTransaction.amount : sum,
							0,
						),
			),
		[bankAccountTransactions, bargeTransactions],
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			{youthWork && <Typography variant="h4">{youthWork.name}</Typography>}
			<Stack>
				<Typography variant="h5">Konten</Typography>
				<Grid container spacing={2}>
					{bankAccountItems}
				</Grid>
			</Stack>
			<Stack>
				<Typography variant="h5">Barkassen</Typography>
				<Grid container spacing={2}>
					{bargeItems}
				</Grid>
			</Stack>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: 2,
						width: '100%',
					}}
				>
					<Stack sx={{ width: '100%' }} gap={1}>
						<Typography variant="h5">Einnahmen</Typography>
						{incomingCategoryItems}
						<Paper
							sx={{
								padding: 2,
								mt: 2,
								display: 'flex',
								flexWrap: 'wrap',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography variant="h6">Summe Einnahmen:</Typography>
							{incomingSum}
						</Paper>
					</Stack>
					<Stack sx={{ width: '100%' }} gap={1}>
						<Typography variant="h5">Ausgaben</Typography>
						{outgoingCategoryItems}
						<Paper
							sx={{
								padding: 2,
								mt: 2,
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography variant="h6">Summe Ausgaben:</Typography>
							{outgoingSum}
						</Paper>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
});
