import { getExcelLocalizedAmount } from './excel/getExcelLocalizedAmount';
import { getLocalizedAmount } from './getLocalizedAmount';
import type { BankAccountTransaction } from '../models/BankAccountTransaction';

export const calculateBankAccountBalance = (transactions: BankAccountTransaction[]) => {
	return getLocalizedAmount(
		transactions.reduce((acc, transaction) => {
			return acc + transaction.amount;
		}, 0),
	);
};

export const calculateBankAccountBalanceExcel = (transactions: BankAccountTransaction[]) => {
	return getExcelLocalizedAmount(
		transactions.reduce((acc, transaction) => {
			return acc + transaction.amount;
		}, 0),
	);
};
