import { Box, Typography } from '@mui/material';
import { ExportKassenbuchButton } from '../../../components/ExportKassenbuchButton';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeActions } from '../../../../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import ApiClient from '../../../../globals/apiClient';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

export const Export = React.memo(() => {
	const dispatch = useAppDispatch();

	const youthWork = useAppSelector((state) =>
		state.youthWorks.youthWorks.find(
			(youthWork) => youthWork.cashier === ApiClient.instance.client.authStore.model?.id,
		),
	);
	const bankAccounts = useAppSelector((state) => state.bankAccounts.bankAccounts);
	const bankAccountTransactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions);
	const transactionCategories = useAppSelector((state) => state.transactionCategories.transactionCategories);
	const barges = useAppSelector((state) => state.barges.barges);
	const bargeTransactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions);

	const year = useMemo(() => moment(Date.now()).year(), []);

	useEffect(() => {
		document.title = 'Datenexport - Kassenbuch';

		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(bankAccountActions.fetchAll());
		dispatch(youthWorkActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		dispatch(bargeActions.fetchAll());
		dispatch(bargeTransactionActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!youthWork) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<Typography variant="h4" sx={{ textAlign: 'center' }}>
					Daten konnten nicht geladen werden!
				</Typography>
			</Box>
		);
	}

	return (
		<ExportKassenbuchButton
			bankAccounts={bankAccounts}
			bankAccountTransactions={bankAccountTransactions}
			transactionCategories={transactionCategories}
			barges={barges}
			bargeTransactions={bargeTransactions}
			youthWork={youthWork}
			year={year}
		/>
	);
});
