import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../components/FormTextField';
import { NumericFormat } from 'react-number-format';
import React from 'react';

export type CreateBankAccountFormDataType = {
	accountDescription: string;
	iban: string;
	bic: string;
	bank: string;
	initialBalance: number;
};

export type CreateBankAccountFormProps = {
	submitData: (data: CreateBankAccountFormDataType) => void;
	didCancel: () => void;
};

export const CreateBankAccountForm = ({ submitData, didCancel }: CreateBankAccountFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			accountDescription: '',
			iban: '',
			bic: '',
			bank: '',
			initialBalance: 0,
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="accountDescription"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Kontoname'} isFirstField={true} />}
				/>
				<Controller
					name="iban"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'IBAN'} />}
				/>
				<Controller
					name="bic"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'BIC'} />}
				/>
				<Controller
					name="bank"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Bank'} />}
				/>
				<Controller
					name="initialBalance"
					control={control}
					render={({ field }) => (
						<NumericFormat
							onValueChange={(values) => {
								field.onChange(values.floatValue);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale
							allowNegative={true}
							customInput={TextField}
							suffix=" €"
							required={true}
							label="Aktueller Kontostand"
						/>
					)}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Hinzufügen
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
