import { Box, Button, ListSubheader } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import React from 'react';
import type { User } from '../../../../../models/User';
import type { YouthWork } from '../../../../../models/YouthWork';

export type EditYouthWorkFormDataType = {
	email: string;
	firstName: string;
	lastName: string;
	youthWorkName: string;
};

export type EditYouthWorkFormProps = {
	initialYouthWork: YouthWork;
	initialUser: User;
	submitData: (data: EditYouthWorkFormDataType) => void;
	didCancel: () => void;
};

export const EditYouthWorkForm = ({ initialUser, initialYouthWork, submitData, didCancel }: EditYouthWorkFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			email: initialUser.email,
			firstName: initialUser.firstName,
			lastName: initialUser.lastName,
			youthWorkName: initialYouthWork.name,
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<ListSubheader>Jugendarbeit</ListSubheader>
				<Controller
					name="youthWorkName"
					control={control}
					render={({ field }) => <FormTextField field={field} label="Name" isFirstField={true} />}
				/>

				<ListSubheader>Kassier</ListSubheader>
				<Controller
					name="firstName"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Vorname'} />}
				/>
				<Controller
					name="lastName"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Nachname'} />}
				/>
				<Controller
					name="email"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'E-Mail'} type="email" />}
				/>

				<Box>
					<Button variant="contained" type="submit">
						Speichern
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
