import { createAsyncThunk } from '@reduxjs/toolkit';
import { createUser, deleteUser, getAllUsers, getUser, updateUser } from '../../../api_clients/userAPIClient';
import type { CreateUser, User } from '../../../models/User';

const name = 'users';

export const createUserExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllUsers());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getUser(id));
	}

	function create() {
		return createAsyncThunk(`${name}/create`, async (user: CreateUser) => await createUser(user));
	}

	function update() {
		return createAsyncThunk(`${name}/update`, async (user: User) => await updateUser(user));
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteUser(id));
	}
};
