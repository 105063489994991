import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { BargeTransaction } from '../models/BargeTransaction';

const collectionName = 'bargeTransactions';

// List
export async function getAllBargeTransactions() {
	Logger.log('BargeTransactionAPI::getAllBargeTransactions');
	try {
		return await ApiClient.instance.client.collection(collectionName).getFullList<BargeTransaction>(1000, {
			sort: '-date',
		});
	} catch (error) {
		Logger.error('BargeTransactionAPI::getAllBargeTransactions::error::' + error);
		throw error;
	}
}

// View
export async function getBargeTransaction(id: string) {
	Logger.log('BargeTransactionAPI::getBargeTransaction::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<BargeTransaction>(id);
	} catch (error) {
		Logger.error('BargeTransactionAPI::getBargeTransaction::error::' + error);
		throw error;
	}
}

// Create
export async function createBargeTransaction(bargeTransaction: BargeTransaction) {
	Logger.log('BargeTransactionAPI::createBargeTransaction::' + bargeTransaction.id);
	try {
		return await ApiClient.instance.client.collection(collectionName).create<BargeTransaction>(bargeTransaction);
	} catch (error) {
		Logger.error('BargeTransactionAPI::createBargeTransaction::' + bargeTransaction.id + '::error::' + error);
		throw error;
	}
}

// Update
export async function updateBargeTransaction(bargeTransaction: BargeTransaction) {
	if (bargeTransaction.id) {
		Logger.log('BargeTransactionAPI::updateBargeTransaction::' + bargeTransaction.id);
		try {
			return await ApiClient.instance.client
				.collection(collectionName)
				.update<BargeTransaction>(bargeTransaction.id, bargeTransaction);
		} catch (error) {
			Logger.error('BargeTransactionAPI::updateBargeTransaction::' + bargeTransaction.id + '::error::' + error);
			throw error;
		}
	} else {
		return bargeTransaction;
	}
}

// Delete
export async function deleteBargeTransaction(id: string) {
	Logger.log('BargeTransactionAPI::deleteBargeTransaction::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).delete(id);
	} catch (error) {
		Logger.error('BargeTransactionAPI::deleteBargeTransaction::' + id + '::error::' + error);
		throw error;
	}
}
