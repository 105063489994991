import { StyledPaperGridItem } from '../../../../components/Styled/StyledPaperGridItem';
import { Typography } from '@mui/material';
import { getIncomingTransactionSum } from '../../../../../helpers/getIncomingTransactionsSum';
import { getLocalizedAmount } from '../../../../../helpers/getLocalizedAmount';
import { getOutGoingTransactionSum } from '../../../../../helpers/getOutgoingTransactionSum';
import { useAppSelector } from '../../../../../redux/hooks';
import React from 'react';
import type { TransactionCategory } from '../../../../../models/TransactionCategory';

export type DashboardTransactionCategoryViewProps = {
	transactionCategory: TransactionCategory;
	direction: 'incoming' | 'outgoing';
};

export const DashboardTransactionCategoryView = React.memo(
	({ transactionCategory, direction }: DashboardTransactionCategoryViewProps) => {
		const bankAccountTransactions = useAppSelector(
			(state) => state.bankAccountTransactions.bankAccountTransactions,
		).filter((transaction) => transaction.transactionCategory === transactionCategory.id);

		const bargeTransactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions).filter(
			(transaction) => transaction.transactionCategory === transactionCategory.id,
		);

		return (
			<StyledPaperGridItem key={`${transactionCategory.id}-${direction}`}>
				<Typography variant="h6">{transactionCategory.name}</Typography>
				<Typography>
					{getLocalizedAmount(
						direction === 'incoming'
							? getIncomingTransactionSum(bankAccountTransactions, bargeTransactions)
							: getOutGoingTransactionSum(bankAccountTransactions, bargeTransactions),
					)}
				</Typography>
			</StyledPaperGridItem>
		);
	},
);
