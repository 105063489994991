import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { getUserId } from '../../../../helpers/getUserId';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { BankAccount } from '../../../../models/BankAccount';

import { CreateBankAccountForm } from './CreateBankAccountForm';
import { StyledModal } from '../../../components/Styled/StyledModal';
import type { CreateBankAccountFormDataType } from './CreateBankAccountForm';

export type CreateBankAccountModalProps = {
	didClose: () => void;
	show: boolean;
};

export const CreateBankAccountModal = React.memo(({ didClose, show }: CreateBankAccountModalProps) => {
	const dispatch = useAppDispatch();

	const youthWork = useAppSelector((state) =>
		state.youthWorks.youthWorks.find((youthWork) => youthWork.cashier === getUserId()),
	);

	useEffect(() => {
		dispatch(youthWorkActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!youthWork) {
		return null;
	}

	return (
		<StyledModal show={show} onClose={didClose} heading="Bankkonto hinzufügen">
			<CreateBankAccountForm
				submitData={function (data: CreateBankAccountFormDataType): void {
					if (youthWork.id) {
						dispatch(
							bankAccountActions.create({
								accountDescription: data.accountDescription,
								iban: data.iban,
								bic: data.bic,
								bank: data.bank,
								youthWork: youthWork.id,
							}),
						)
							.then((action) => {
								if (action.payload) {
									const id = (action.payload as unknown as BankAccount).id;
									if (id) {
										dispatch(
											bankAccountTransactionActions.create({
												bankAccount: id,
												amount: data.initialBalance,
												date: moment().format('YYYY-MM-DD'),
												description: 'Eröffnungskontostand',
												bankStatement: 'Initial',
												isChecked: true,
											}),
										);
									}
								}
							})
							.then(() => didClose());
					} else {
						didClose();
					}
				}}
				didCancel={didClose}
			/>
		</StyledModal>
	);
});
