import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorSnackBar } from '../components/ErrorSnackBar';
import { FormTextField } from '../components/FormTextField';
import { authActions } from '../../redux/reducers/authentificationReducer/authentification.slice';
import { isAdmin } from '../../helpers/isAdmin';
import { isLoadingAdmin, isLoadingUser } from '../../helpers/isLoading';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';

export const LoginScreen = React.memo(() => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const logo = require('../../assets/media/logo-primary.png');

	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			email: '',
			password: '',
			asAdmin: false,
		},
	});

	const store = useAppSelector((state) => state);
	const loadingUser = useMemo(() => isLoadingUser(store), [store]);
	const loadingAdmin = useMemo(() => isLoadingAdmin(store), [store]);
	const isAdminMemo = useMemo(() => isAdmin(), []);

	useEffect(() => {
		document.title = 'Login - Kassenbuch';
	}, []);

	const onSubmit = () => {
		const data = getValues();

		dispatch(authActions.login({ email: data.email, password: data.password, asAdmin: data.asAdmin })).then(
			(data) => {
				if (data.type === 'authentification/login/fulfilled') {
					navigate('/');
					window.location.reload();
				}
			},
		);
	};

	return (
		<>
			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isAdminMemo ? loadingAdmin : loadingUser}
				transitionDuration={100}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<ErrorSnackBar />
			<Grid
				container
				spacing={0}
				direction="column"
				justifyContent="center"
				alignItems="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={3}>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							p: 6,
						}}
					>
						<img
							src={logo}
							alt="SWD-EC e.V. Logo"
							style={{
								padding: '25px',
								paddingBottom: '15px',
								maxWidth: '60%',
							}}
						/>
						<Typography variant="h5" sx={{ mb: 2 }}>
							Login
						</Typography>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<FormTextField field={field} label="E-Mail" isFirstField={true} type="email" />
								)}
							/>
							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										fullWidth
										label="Passwort"
										variant="outlined"
										margin="normal"
										type="password"
										autoComplete="current-password"
										{...field}
									/>
								)}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									alignItems: 'center',
									mt: 2,
								}}
							>
								<Controller
									name="asAdmin"
									control={control}
									render={({ field }) => (
										<FormControlLabel
											control={<Checkbox {...field} />}
											label="Als Admin anmelden"
										/>
									)}
								/>
								<Button variant="contained" type="submit">
									Login
								</Button>
							</Box>
						</form>
					</Paper>
				</Grid>
			</Grid>
			<footer style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center' }}>
				<Typography variant="body2" color="text.secondary" align="center">
					{'© '}
					{new Date().getFullYear()}
					{' - '}
					<Link color="inherit" href="https://niklasarnitz.com">
						Niklas Arnitz
					</Link>
					{' - '}
					<Link color="inherit" href="https://swdec.de">
						SWD-EC e.V.
					</Link>
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					<Link color="inherit" href="https://swdec.de/impressum/">
						Impressum
					</Link>
					{' - '}
					<Link color="inherit" href="https://swdec.de/datenschutz/">
						Datenschutz
					</Link>
				</Typography>
			</footer>
		</>
	);
});
