import { getExcelLocalizedAmount } from './excel/getExcelLocalizedAmount';
import { getLocalizedAmount } from './getLocalizedAmount';
import type { BargeTransaction } from '../models/BargeTransaction';

export const calculateBargeBalance = (transactions: BargeTransaction[]) => {
	return getLocalizedAmount(
		transactions.reduce((acc, transaction) => {
			return acc + transaction.amount;
		}, 0),
	);
};

export const calculateBargeBalanceExcel = (transactions: BargeTransaction[]) => {
	return getExcelLocalizedAmount(
		transactions.reduce((acc, transaction) => {
			return acc + transaction.amount;
		}, 0),
	);
};
