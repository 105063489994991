import { receiptExtraActions } from './receiptReducer.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { Receipt } from '../../../models/Receipt';
import type { ReceiptState } from './receiptReducer.state';
export const receiptExtraReducers = (builder: ActionReducerMapBuilder<ReceiptState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<ReceiptState>) => {
	builder.addCase(receiptExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Receipt::fetch::pending');
	});
	builder.addCase(receiptExtraActions.fetchAll.fulfilled, (state, action) => {
		state.receipts = action.payload;

		state.byTransaction = state.receipts.reduce((acc, receipt) => {
			if (receipt.transaction) {
				acc[receipt.transaction] = receipt;
			}
			return acc;
		}, {} as Record<string, Receipt>);

		state.byId = state.receipts.reduce((acc, receipt) => {
			if (receipt.id) {
				acc[receipt.id] = receipt;
			}
			return acc;
		}, {} as Record<string, Receipt>);

		state.isLoading = false;
		Logger.log('Receipt::fetch::success');
	});
	builder.addCase(receiptExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Receipt::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<ReceiptState>) => {
	builder.addCase(receiptExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Receipt::fetch::pending');
	});
	builder.addCase(receiptExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;

		if (state.receipts.findIndex((x) => x.id === payload.id) >= 0) {
			state.receipts = state.receipts.map((receipt) => {
				if (receipt.id === payload.id) {
					return payload;
				} else {
					return receipt;
				}
			});
		} else {
			state.receipts.push(payload);
		}

		state.byId = state.receipts.reduce((acc, receipt) => {
			if (receipt.id) {
				acc[receipt.id] = receipt;
			}
			return acc;
		}, {} as Record<string, Receipt>);

		state.byTransaction = state.receipts.reduce((acc, receipt) => {
			if (receipt.transaction) {
				acc[receipt.transaction] = receipt;
			}
			return acc;
		}, {} as Record<string, Receipt>);

		state.isLoading = false;
		Logger.log('Receipt::fetch::success');
	});

	builder.addCase(receiptExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Receipt::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<ReceiptState>) => {
	builder.addCase(receiptExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Receipt::create::pending');
	});
	builder.addCase(receiptExtraActions.create.fulfilled, (state, action) => {
		state.receipts.push(action.payload);
		state.isLoading = false;
		Logger.log('Receipt::create::success');
	});
	builder.addCase(receiptExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Receipt::create::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<ReceiptState>) => {
	builder.addCase(receiptExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Receipt::remove::pending');
	});
	builder.addCase(receiptExtraActions.remove.fulfilled, (state, action) => {
		state.receipts = state.receipts.filter((receipt) => receipt.id !== action.meta.arg);
		state.isLoading = false;
		Logger.log('Receipt::remove::success');
	});
	builder.addCase(receiptExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Receipt::remove::error::' + action.error.message);
	});
};
