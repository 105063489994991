import { userExtraActions } from './user.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { User } from '../../../models/User';
import type { UserState } from './user.state';

export const userExtraReducers = (builder: ActionReducerMapBuilder<UserState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<UserState>) => {
	builder.addCase(userExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Users::fetch::pending');
	});
	builder.addCase(userExtraActions.fetchAll.fulfilled, (state, action) => {
		state.users = action.payload;

		state.byId = state.users.reduce((acc, user) => {
			if (user.id) {
				acc[user.id] = user;
			}
			return acc;
		}, {} as { [key: string]: User });
		state.isLoading = false;
		Logger.log('User::fetch::success');
	});
	builder.addCase(userExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('User::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<UserState>) => {
	builder.addCase(userExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.error('User::fetch::pending');
	});
	builder.addCase(userExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;

		if (payload.id) {
			state.byId[payload.id] = payload;
		}

		if (state.users.findIndex((x) => x.id === payload.id) >= 0) {
			state.users = state.users.map((user) => {
				if (user.id === payload.id) {
					return payload;
				} else {
					return user;
				}
			});
		} else {
			state.users.push(payload);
		}
		state.isLoading = false;
		Logger.log('User::fetch::success');
	});
	builder.addCase(userExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('User::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<UserState>) => {
	builder.addCase(userExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('User::create::pending');
	});
	builder.addCase(userExtraActions.create.fulfilled, (state, action) => {
		state.users.push(action.payload);
		state.isLoading = false;
		Logger.log('User::create::success');
	});
	builder.addCase(userExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('User::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<UserState>) => {
	builder.addCase(userExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('User::update::pending');
	});
	builder.addCase(userExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload!;

		state.users = state.users.map((user) => {
			if (user.id === payload?.id) {
				return payload;
			} else {
				return user;
			}
		});

		state.isLoading = false;
		Logger.log('User::update::success');
	});
	builder.addCase(userExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('User::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<UserState>) => {
	builder.addCase(userExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('User::remove::pending');
	});
	builder.addCase(userExtraActions.remove.fulfilled, (state, action) => {
		state.users = state.users.filter((user) => user.id !== action.meta.arg);
		state.isLoading = false;
		Logger.log('User::remove::success');
	});
	builder.addCase(userExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('User::remove::error::' + action.error.message);
	});
};
