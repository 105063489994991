import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import React from 'react';
import type { StandardTransactionCategory } from '../../../../../models/StandardTransactionCategory';

export type EditStandardTransactionCategoryFormDataType = {
	name: string;
	description: string;
};

export type EditStandardTransactionCategoryFormProps = {
	standardCategory: StandardTransactionCategory;
	submitData: (data: EditStandardTransactionCategoryFormDataType) => void;
	didCancel: () => void;
};

export const EditStandardTransactionCategoryForm = ({
	standardCategory,
	submitData,
	didCancel,
}: EditStandardTransactionCategoryFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			name: standardCategory.name,
			description: standardCategory.description ?? '',
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Name'} isFirstField={true} />}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Beschreibung'} />}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Speichern
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
