import { bankAccountActions } from '../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeActions } from '../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { isAdmin } from './isAdmin';
import { store } from '../redux/store';
import { transactionCategoryActions } from '../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { userActions } from '../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../redux/reducers/YouthWorkReducer/youthWork.slice';
import ApiClient from '../globals/apiClient';

export const realtimeSubscribe = () => {
	// Realtime updates of data
	const client = ApiClient.instance.client;
	client.collection('youthworks').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(youthWorkActions.fetch(data.record.id));
		}
	});
	client.collection('bankAccounts').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(bankAccountActions.fetch(data.record.id));
		} else if (data.action === 'delete') {
			store.dispatch(bankAccountActions.fetchAll());
		}
	});
	client.collection('bankAccountTransactions').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(bankAccountTransactionActions.fetch(data.record.id));
		} else if (data.action === 'delete') {
			store.dispatch(bankAccountTransactionActions.fetchAll());
		}
	});
	client.collection('barges').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(bargeActions.fetch(data.record.id));
		} else if (data.action === 'delete') {
			store.dispatch(bargeActions.fetchAll());
		}
	});
	client.collection('bargeTransactions').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(bargeTransactionActions.fetch(data.record.id));
		} else if (data.action === 'delete') {
			store.dispatch(bargeTransactionActions.fetchAll());
		}
	});
	client.collection('transactionCategories').subscribe('*', (data) => {
		if (data.action === 'create' || data.action === 'update') {
			store.dispatch(transactionCategoryActions.fetch(data.record.id));
		} else if (data.action === 'delete') {
			store.dispatch(transactionCategoryActions.fetchAll());
		}
	});

	if (isAdmin()) {
		client.collection('users').subscribe('*', (data) => {
			if (data.action === 'create' || data.action === 'update') {
				store.dispatch(userActions.fetch(data.record.id));
			} else if (data.action === 'delete') {
				store.dispatch(userActions.fetchAll());
			}
		});
	}
};

export const realtimeUnsubscribe = () => {
	const client = ApiClient.instance.client;

	client.collection('youthworks').unsubscribe();
	client.collection('bankAccounts').unsubscribe();
	client.collection('bankAccountTransactions').unsubscribe();
	client.collection('barges').unsubscribe();
	client.collection('bargeTransactions').unsubscribe();
	client.collection('transactionCategories').unsubscribe();

	if (isAdmin()) {
		client.collection('users').unsubscribe();
	}
};
