import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { TransactionCategory } from '../models/TransactionCategory';

const collectionName = 'transactionCategories';

// List
export async function getAllTransactionCategories() {
	Logger.log('TransactionCategoryAPI::getAllTransactionCategories');
	try {
		return ApiClient.instance.client.collection(collectionName).getFullList<TransactionCategory>();
	} catch (error) {
		Logger.error('TransactionCategoryAPI::getAllTransactionCategories::error::' + error);
		throw error;
	}
}

// View
export async function getTransactionCategory(id: string) {
	Logger.log('TransactionCategoryAPI::getTransactionCategory::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<TransactionCategory>(id);
	} catch (error) {
		Logger.error('TransactionCategoryAPI::getTransactionCategory::error::' + error);
		throw error;
	}
}

// Create
export async function createTransactionCategory(transactionCategory: TransactionCategory) {
	Logger.log('TransactionCategoryAPI::createTransactionCategory::' + transactionCategory.id);
	try {
		return await ApiClient.instance.client
			.collection(collectionName)
			.create<TransactionCategory>(transactionCategory);
	} catch (error) {
		Logger.error(
			'TransactionCategoryAPI::createTransactionCategory::' + transactionCategory.id + '::error::' + error,
		);
		throw error;
	}
}

// Update
export async function updateTransactionCategory(transactionCategory: TransactionCategory) {
	if (transactionCategory.id) {
		Logger.log('TransactionCategoryAPI::updateTransactionCategory::' + transactionCategory.id);
		try {
			return await ApiClient.instance.client
				.collection(collectionName)
				.update<TransactionCategory>(transactionCategory.id, transactionCategory);
		} catch (error) {
			Logger.error(
				'TransactionCategoryAPI::updateTransactionCategory::' + transactionCategory.id + '::error::' + error,
			);
			throw error;
		}
	} else {
		return transactionCategory;
	}
}

// Delete
export async function deleteTransactionCategory(id: string) {
	Logger.log('TransactionCategoryAPI::deleteTransactionCategory::' + id);
	if (id) {
		try {
			return await ApiClient.instance.client.collection(collectionName).delete(id);
		} catch (error) {
			Logger.error('TransactionCategoryAPI::deleteTransactionCategory::' + id + '::error::' + error);
			throw error;
		}
	} else {
		throw new Error('TransactionCategoryAPI::deleteTransactionCategory::id is null');
	}
}
