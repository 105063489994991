import { standardTransactionCategoryExtraActions } from './standardTransactionCategory.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { StandardTransactionCategory } from '../../../models/StandardTransactionCategory';
import type { StandardTransactionCategoryState } from './standardTransactionCategory.state';

export const standardTransactionCategoryExtraReducers = (
	builder: ActionReducerMapBuilder<StandardTransactionCategoryState>,
) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<StandardTransactionCategoryState>) => {
	builder.addCase(standardTransactionCategoryExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('StandardTransactionCategory::fetch::pending');
	});
	builder.addCase(standardTransactionCategoryExtraActions.fetchAll.fulfilled, (state, action) => {
		state.standardTransactionCategories = action.payload;

		state.byId = state.standardTransactionCategories.reduce((acc, standardStandardTransactionCategory) => {
			if (standardStandardTransactionCategory.id) {
				acc[standardStandardTransactionCategory.id] = standardStandardTransactionCategory;
			}
			return acc;
		}, {} as Record<string, StandardTransactionCategory>);

		state.isLoading = false;
		Logger.log('StandardTransactionCategory::fetch::success');
	});
	builder.addCase(standardTransactionCategoryExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('StandardTransactionCategory::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<StandardTransactionCategoryState>) => {
	builder.addCase(standardTransactionCategoryExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('StandardTransactionCategory::fetch::pending');
	});
	builder.addCase(standardTransactionCategoryExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;

		if (state.standardTransactionCategories.findIndex((x) => x.id === payload.id) >= 0) {
			state.standardTransactionCategories = state.standardTransactionCategories.map(
				(standardStandardTransactionCategory) => {
					if (standardStandardTransactionCategory.id === payload.id) {
						return payload;
					} else {
						return standardStandardTransactionCategory;
					}
				},
			);
		} else {
			state.standardTransactionCategories.push(payload);
		}

		state.byId = state.standardTransactionCategories.reduce((acc, standardStandardTransactionCategory) => {
			if (standardStandardTransactionCategory.id) {
				acc[standardStandardTransactionCategory.id] = standardStandardTransactionCategory;
			}
			return acc;
		}, {} as Record<string, StandardTransactionCategory>);

		state.isLoading = false;
		Logger.log('StandardTransactionCategory::fetch::success');
	});

	builder.addCase(standardTransactionCategoryExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('StandardTransactionCategory::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<StandardTransactionCategoryState>) => {
	builder.addCase(standardTransactionCategoryExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('StandardTransactionCategory::create::pending');
	});
	builder.addCase(standardTransactionCategoryExtraActions.create.fulfilled, (state, action) => {
		state.standardTransactionCategories.push(action.payload);
		state.isLoading = false;
		Logger.log('StandardTransactionCategory::create::success');
	});
	builder.addCase(standardTransactionCategoryExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('StandardTransactionCategory::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<StandardTransactionCategoryState>) => {
	builder.addCase(standardTransactionCategoryExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('StandardTransactionCategory::update::pending');
	});
	builder.addCase(standardTransactionCategoryExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.standardTransactionCategories = state.standardTransactionCategories.map(
			(standardStandardTransactionCategory) => {
				if (standardStandardTransactionCategory.id === payload.id) {
					return payload;
				} else {
					return standardStandardTransactionCategory;
				}
			},
		);

		state.isLoading = false;
		Logger.log('StandardTransactionCategory::update::success');
	});
	builder.addCase(standardTransactionCategoryExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('StandardTransactionCategory::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<StandardTransactionCategoryState>) => {
	builder.addCase(standardTransactionCategoryExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('StandardTransactionCategory::remove::pending');
	});
	builder.addCase(standardTransactionCategoryExtraActions.remove.fulfilled, (state, action) => {
		state.standardTransactionCategories = state.standardTransactionCategories.filter(
			(standardStandardTransactionCategory) => standardStandardTransactionCategory.id !== action.meta.arg,
		);
		state.isLoading = false;
		Logger.log('StandardTransactionCategory::remove::success');
	});
	builder.addCase(standardTransactionCategoryExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('StandardTransactionCategory::remove::error::' + action.error.message);
	});
};
