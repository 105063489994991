import { createAsyncThunk } from '@reduxjs/toolkit';
import { createReceipt, deleteReceipt, getAllReceipts, getReceipt } from '../../../api_clients/receiptAPIClient';

const name = 'transactionCategories';

export const createReceiptExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllReceipts());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getReceipt(id));
	}

	function create() {
		return createAsyncThunk(`${name}/create`, async (receipt: FormData) => await createReceipt(receipt));
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteReceipt(id));
	}
};
