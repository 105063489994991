import './index.css';
import * as Sentry from '@sentry/browser';
import { APPCONFIG } from './globals/APP_CONFIG';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { isDev } from './helpers/isDev';
import { store } from './redux/store';
import App from './App';
import React from 'react';
import reportWebVitals from './reportWebVitals';

const AppTheme = createTheme({
	palette: {
		primary: {
			main: '#8fb217',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					color: 'white',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Montserrat',
	},
});

if (isDev) {
	Sentry.init({
		dsn: APPCONFIG.DSN,
		release: `${APPCONFIG.APP_VERSION}@${APPCONFIG.API_VERSION}-dev`,
		environment: 'development',
	});
} else {
	Sentry.init({
		dsn: APPCONFIG.DSN,
		release: `${APPCONFIG.APP_VERSION}@${APPCONFIG.API_VERSION}-prod`,
		environment: 'production',
	});
}

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
	<ThemeProvider theme={AppTheme}>
		<Provider store={store}>
			<SnackbarProvider maxSnack={6}>
				<App />
			</SnackbarProvider>
		</Provider>
	</ThemeProvider>,
);

reportWebVitals();
