import { bargeExtraReducers } from './barge.extra.reducers';
import { createBargeExtraActions } from './barge.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { BargeState } from './barge.state';

const name = 'barges';
const initialState: BargeState = {
	barges: [],
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: bargeExtraReducers,
});

// Exports
export const bargeExtraActions = createBargeExtraActions();
export const bargeActions = { ...slice.actions, ...bargeExtraActions };
export const bargeReducer = slice.reducer;
