import { Box } from '@mui/system';
import { CancelRounded, CheckRounded } from '@mui/icons-material';
import { Constants } from '../../../../globals/Constants';
import { Paper, Stack, Toolbar, Typography } from '@mui/material';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeActions } from '../../../../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { YouthWork } from '../../../../models/YouthWork';

export const CheckBargeOverview = React.memo(() => {
	const dispatch = useAppDispatch();

	const youthWorks = useAppSelector((state) => state.youthWorks.youthWorks);
	const bankAccounts = useAppSelector((state) => state.bankAccounts.bankAccounts);
	const bankAccountTransactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions);
	const barges = useAppSelector((state) => state.barges.barges);
	const bargeTransactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions);

	useEffect(() => {
		document.title = 'Kassenprüfübersicht - Kassenbuch';

		dispatch(youthWorkActions.fetchAll());
		dispatch(bankAccountActions.fetchAll());
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(bargeActions.fetchAll());
		dispatch(bargeTransactionActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getYouthMarkCheckmark = useCallback(
		(youthWork: YouthWork) => {
			const youthWorkBankAccounts = bankAccounts.filter((bankAccount) => bankAccount.youthWork === youthWork.id);
			const youthWorkBarges = barges.filter((barge) => barge.youthWork === youthWork.id);

			if (youthWorkBankAccounts.length === 0) {
				return;
			}

			let success = true;

			youthWorkBankAccounts.forEach((bankAccount) => {
				bankAccountTransactions
					.filter((bankAccountTransaction) => bankAccountTransaction.bankAccount === bankAccount.id)
					.forEach((bankAccountTransaction) => {
						if (!bankAccountTransaction.isChecked) {
							success = false;
						}
					});
			});

			youthWorkBarges.forEach((barge) => {
				bargeTransactions
					.filter((bargeTransaction) => bargeTransaction.barge === barge.id)
					.forEach((bargeTransaction) => {
						if (!bargeTransaction.isChecked) {
							success = false;
						}
					});
			});

			return success ? (
				<CheckRounded color="success" fontSize="large" />
			) : (
				<CancelRounded color="error" fontSize="large" />
			);
		},
		[bankAccountTransactions, bankAccounts, bargeTransactions, barges],
	);

	const youthWorkListEntries = useMemo(() => {
		return youthWorks.map((youthWork) => {
			const hasBankAccounts =
				bankAccounts.filter((bankAccount) => bankAccount.youthWork === youthWork.id).length !== 0;
			const hasBarges = barges.filter((barge) => barge.youthWork === youthWork.id).length !== 0;

			return (
				<StyledPaperListItem
					key={youthWork.id}
					leading={
						<>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Typography variant="h6">{youthWork.name}</Typography>
								{getYouthMarkCheckmark(youthWork)}
							</Box>
							{hasBankAccounts && (
								<Box>
									<Typography variant="subtitle1" fontWeight="bold">
										Konten:
									</Typography>
									{bankAccounts
										.filter((bankAccount) => bankAccount.youthWork === youthWork.id)
										.map((bankAccount) => {
											const uncheckedTransactions = bankAccountTransactions.filter(
												(bankAccountTransaction) =>
													bankAccountTransaction.bankAccount === bankAccount.id &&
													!bankAccountTransaction.isChecked,
											);
											return (
												<Paper key={`${bankAccount.id}-paper`} sx={{ width: '100%', mt: 2 }}>
													<Toolbar
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'space-between',
														}}
													>
														<Typography variant="body1">
															{bankAccount.accountDescription}
														</Typography>
														{uncheckedTransactions.length === 0 ? (
															<CheckRounded color="success" fontSize="large" />
														) : (
															<CancelRounded color="error" fontSize="large" />
														)}
													</Toolbar>
												</Paper>
											);
										})}
								</Box>
							)}
							{hasBarges && (
								<Box>
									<Typography variant="subtitle1" fontWeight="bold">
										Barkassen:
									</Typography>
									{barges
										.filter((barge) => barge.youthWork === youthWork.id)
										.map((barge) => {
											const uncheckedTransactions = bargeTransactions.filter(
												(bargeTransaction) =>
													bargeTransaction.barge === barge.id && !bargeTransaction.isChecked,
											);
											return (
												<Paper key={`${barge.id}-paper`} sx={{ width: '100%', mt: 2 }}>
													<Toolbar
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'space-between',
														}}
													>
														<Typography variant="body1">
															{barge.accountDescription}
														</Typography>
														{uncheckedTransactions.length === 0 ? (
															<CheckRounded color="success" fontSize="large" />
														) : (
															<CancelRounded color="error" fontSize="large" />
														)}
													</Toolbar>
												</Paper>
											);
										})}
								</Box>
							)}
						</>
					}
				/>
			);
		});
	}, [bankAccountTransactions, bankAccounts, bargeTransactions, barges, getYouthMarkCheckmark, youthWorks]);

	return (
		<>
			<StyledToolbar
				title="Kassenprüfübersicht"
				description="Hier bekommst du eine Übersicht über den Fortschritt der Kassenprüfung der einzelnen Jugendarbeiten."
			/>
			<Stack gap={Constants.gap}>{youthWorkListEntries}</Stack>
		</>
	);
});
