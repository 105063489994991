import { APPCONFIG } from '../../globals/APP_CONFIG';
import {
	AppBar,
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Drawer,
	Link,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { Constants } from '../../globals/Constants';
import { ErrorSnackBar } from '../components/ErrorSnackBar';
import { Logout } from '@mui/icons-material';
import { StyledMarkdown } from '../components/Styled/StyledMarkdown';
import { authActions } from '../../redux/reducers/authentificationReducer/authentification.slice';
import { isAdmin } from '../../helpers/isAdmin';
import { isLoadingAdmin, isLoadingUser } from '../../helpers/isLoading';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../globals/apiClient';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback, useMemo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import moment from 'moment';

const drawerWidth = 280;

export type ResponsiveContextProps = {
	children: React.ReactNode;
	drawer: React.ReactNode;
};

export const ResponsiveContext = ({ children, drawer }: ResponsiveContextProps) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = useCallback(() => {
		setMobileOpen(!mobileOpen);
	}, [mobileOpen]);

	const [showChangelogDialog, setShowChangelogDialog] = React.useState(false);

	const container = window !== undefined ? () => document.body : undefined;

	const adminError = useAppSelector((state) => state.admins.error);
	const userError = useAppSelector((state) => state.users.error);
	const bankAccountError = useAppSelector((state) => state.bankAccounts.error);
	const bargeError = useAppSelector((state) => state.barges.error);
	const bankAccountTransactionError = useAppSelector((state) => state.bankAccountTransactions.error);
	const bargeTransactionError = useAppSelector((state) => state.bargeTransactions.error);
	const transactionCategoryError = useAppSelector((state) => state.transactionCategories.error);
	const youthWorkError = useAppSelector((state) => state.youthWorks.error);
	const jsonStore = JSON.stringify(
		useAppSelector((state) => state),
		null,
		4,
	);

	const store = useAppSelector((state) => state);

	const userName = useMemo(() => ApiClient.instance.client.authStore.model?.email ?? '', []);

	const loadingUser = useMemo(() => isLoadingUser(store), [store]);
	const loadingAdmin = useMemo(() => isLoadingAdmin(store), [store]);
	const isAdminMemo = useMemo(() => isAdmin(), []);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const reportErrorText = useMemo(
		() =>
			encodeURI(
				`mailto:${APPCONFIG.SUPPORT_EMAIL}?subject=Fehlermeldung Kassenbuch ${moment(new Date()).format(
					'YYYY-MM-DD HH:mm:ss',
				)}&body=Fehlermeldung\nBitte hier eine kurze Fehlerbeschreibung eingeben. Falls möglich bitte auch einen Screenshot als Anhang mitsenden.\n \n \nApp-Informationen (Bitte hieran nichts ändern!)\n - Aktueller Pfad: ${
					window.location.pathname
				}\n - APP-Version: ${APPCONFIG.APP_VERSION}\n - API-Version: ${
					APPCONFIG.API_VERSION
				}\n - state.admins.error: ${adminError}\n - state.users.error: ${userError}\n - state.bankAccounts.error: ${bankAccountError}\n - state.bankAccountTransactions.error: ${bargeError}\n - state.barges.error: ${bankAccountTransactionError}\n - state.bargeTransactions.error: ${bargeTransactionError}\n - state.transactionCategories.error: ${transactionCategoryError}\n - state.youthWorks.error: ${youthWorkError}\n\n\`\`\`json\n${jsonStore}\n\`\`\`\n\n${moment(
					new Date(),
				).format('YYYY-MM-DD HH:mm:ss')}\n/label ~bug-via-email`,
			),
		[
			adminError,
			bankAccountError,
			bankAccountTransactionError,
			bargeError,
			bargeTransactionError,
			jsonStore,
			transactionCategoryError,
			userError,
			youthWorkError,
		],
	);

	const footer = useMemo(() => {
		return (
			<Box sx={{ marginY: 2 }}>
				<Typography variant="body2" color="text.secondary" align="center">
					<Link color="inherit" href={reportErrorText}>
						Fehler Melden
					</Link>
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					<Link color="inherit" onClick={() => setShowChangelogDialog(true)}>
						Changelog
					</Link>
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					{`App-Version: ${APPCONFIG.APP_VERSION}`}
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					{'© '}
					{new Date().getFullYear()}
					{' - '}
					<Link color="inherit" href="https://niklasarnitz.com">
						Niklas Arnitz
					</Link>
					{' - '}
					<Link color="inherit" href="https://swdec.de">
						SWD-EC e.V.
					</Link>
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					<Link color="inherit" href="https://swdec.de/impressum/">
						Impressum
					</Link>
					{' - '}
					<Link color="inherit" href="https://swdec.de/datenschutz/">
						Datenschutz
					</Link>
				</Typography>
			</Box>
		);
	}, [reportErrorText]);

	const logoutItem = useMemo(
		() => (
			<ListItem
				sx={{
					position: 'fixed',
					bottom: 0,
					backgroundColor: 'background.paper',
					width: drawerWidth - 2,
				}}
				secondaryAction={
					<IconButton
						edge="end"
						onClick={() => {
							dispatch(authActions.logout());
							navigate('/login', { replace: true });
							// This is needed because of a weird React Router Dom Bug
							// eslint-disable-next-line no-restricted-globals
							location.reload();
						}}
					>
						<Logout />
					</IconButton>
				}
			>
				<ListItemAvatar>
					<Avatar>{userName.charAt(0).toUpperCase()}</Avatar>
				</ListItemAvatar>
				<ListItemText primary={userName} />
			</ListItem>
		),
		[dispatch, navigate, userName],
	);

	return (
		<>
			<Dialog open={showChangelogDialog} onClose={() => setShowChangelogDialog(false)}>
				<DialogContent>
					<StyledMarkdown markdown={APPCONFIG.CHANGELOG} />
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={() => setShowChangelogDialog(false)}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>
			<ErrorSnackBar />
			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isAdminMemo ? loadingAdmin : loadingUser}
				transitionDuration={100}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box sx={{ display: 'flex' }}>
				<AppBar
					position="fixed"
					sx={{
						display: { xs: 'block', sm: 'none' },
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						ml: { sm: `${drawerWidth}px` },
					}}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				)
				<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
					>
						<Stack>
							{drawer}
							{footer}
							{logoutItem}
						</Stack>
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
						open
					>
						<Stack>
							{drawer}
							{footer}
							{logoutItem}
						</Stack>
					</Drawer>
				</Box>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: Constants.paddingY,
						px: Constants.paddingX,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}
				>
					<Toolbar sx={{ display: { xs: 'block', sm: 'none' } }} />
					{children}
				</Box>
			</Box>
		</>
	);
};
