import { getLocalizedAmount } from '../getLocalizedAmount';
import { utils } from 'xlsx';
import moment from 'moment';
import type { BankAccount } from '../../models/BankAccount';
import type { BankAccountTransaction } from '../../models/BankAccountTransaction';
import type { TransactionCategory } from '../../models/TransactionCategory';

export const generateExcelSheetFromBankAccount = (
	bankAccount: BankAccount,
	transactions: BankAccountTransaction[],
	transactionCategories: TransactionCategory[],
) => {
	const adjustedTransactions = transactions.map((transaction) => {
		return {
			Datum: moment(transaction.date).format('DD.MM.YYYY'),
			Kontoauszug: transaction.bankStatement,
			Betrag: getLocalizedAmount(transaction.amount),
			Beschreibung: transaction.description,
			Beleg: transaction.receipt,
			Kategorie: transactionCategories.find((category) => category.id === transaction.transactionCategory)?.name,
			Person: transaction.person,
			Kassenprüfung: transaction.isChecked ? 'Geprüft' : 'Nicht geprüft',
		};
	});

	return utils.json_to_sheet(adjustedTransactions);
};
