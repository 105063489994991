import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { BankAccountTransaction } from '../models/BankAccountTransaction';

const collectionName = 'bankAccountTransactions';

// List
export async function getAllBankAccountTransactions() {
	Logger.log('BankAccountTransactionAPI::getAllBankAccountTransactions');
	try {
		return await ApiClient.instance.client.collection(collectionName).getFullList<BankAccountTransaction>(1000, {
			sort: '-date',
			expand: 'receipt,transactionCategory,bankAccount',
		});
	} catch (error) {
		Logger.error('BankAccountTransactionAPI::getAllBankAccountTransactions::error::' + error);
		throw error;
	}
}

// View
export async function getBankAccountTransaction(id: string) {
	Logger.log('BankAccountTransactionAPI::getBankAccountTransaction::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<BankAccountTransaction>(id);
	} catch (error) {
		Logger.error('BankAccountTransactionAPI::getBankAccountTransaction::error::' + error);
		throw error;
	}
}

// Create
export async function createBankAccountTransaction(bankAccountTransaction: BankAccountTransaction) {
	Logger.log('BankAccountTransactionAPI::createBankAccountTransaction::' + bankAccountTransaction.id);
	try {
		return await ApiClient.instance.client
			.collection(collectionName)
			.create<BankAccountTransaction>(bankAccountTransaction);
	} catch (error) {
		Logger.error(
			'BankAccountTransactionAPI::createBankAccountTransaction::' +
				bankAccountTransaction.id +
				'::error::' +
				error,
		);
		throw error;
	}
}

// Update
export async function updateBankAccountTransaction(bankAccountTransaction: BankAccountTransaction) {
	if (bankAccountTransaction.id) {
		Logger.log('BankAccountTransactionAPI::updateBankAccountTransaction::' + bankAccountTransaction.id);
		try {
			return await ApiClient.instance.client
				.collection(collectionName)
				.update<BankAccountTransaction>(bankAccountTransaction.id, bankAccountTransaction);
		} catch (error) {
			Logger.error(
				'BankAccountTransactionAPI::updateBankAccountTransaction::' +
					bankAccountTransaction.id +
					'::error::' +
					error,
			);
			throw error;
		}
	} else {
		return bankAccountTransaction;
	}
}

// Delete
export async function deleteBankAccountTransaction(id: string) {
	Logger.log('BankAccountTransactionAPI::deleteBankAccountTransaction::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).delete(id);
	} catch (error) {
		Logger.error('BankAccountTransactionAPI::deleteBankAccountTransaction::' + id + '::error::' + error);
		throw error;
	}
}
