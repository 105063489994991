import { EditBankAccountTransactionForm } from './EditBankAccountTransactionForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { bankAccountTransactionActions } from '../../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { BankAccount } from '../../../../../models/BankAccount';
import type { BankAccountTransaction } from '../../../../../models/BankAccountTransaction';
import type { EditBankAccountTransactionFormDataType } from './EditBankAccountTransactionForm';

export type EditBankAccountTransactionModalProps = {
	bankAccount: BankAccount;
	transaction: BankAccountTransaction;
	didClose: () => void;
	show: boolean;
};

export const EditBankAccountTransactionModal = React.memo(
	({ bankAccount, transaction, didClose, show }: EditBankAccountTransactionModalProps) => {
		const dispatch = useAppDispatch();

		useEffect(() => {
			dispatch(transactionCategoryActions.fetchAll());
		}, [dispatch]);

		return (
			<StyledModal show={show} onClose={didClose} heading="Buchung Bearbeiten">
				<EditBankAccountTransactionForm
					submitData={function (data: EditBankAccountTransactionFormDataType): void {
						dispatch(
							bankAccountTransactionActions.update({
								id: transaction.id,
								bankStatement: data.bankStatement,
								date: moment(data.date).format('YYYY-MM-DD HH:mm:ss'),
								amount: data.amount,
								description: data.description,
								person: data.person,
								bankAccount: transaction.bankAccount,
								transactionCategory: data.transactionCategory,
							}),
						).then(() => didClose());
					}}
					initialData={transaction}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
