import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import React from 'react';

export type CreateTransactionCategoryFormDataType = {
	name: string;
	description: string;
};

export type CreateTransactionCategoryFormProps = {
	submitData: (data: CreateTransactionCategoryFormDataType) => void;
	didCancel: () => void;
};

export const CreateTransactionCategoryForm = ({ submitData, didCancel }: CreateTransactionCategoryFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			name: '',
			description: '',
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Name'} isFirstField={true} />}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Hinzufügen
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
