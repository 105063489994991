import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { YouthWork } from '../models/YouthWork';

const collectionName = 'youthworks';

// List
export function getAllYouthwork() {
	Logger.log('YouthworkAPI::getAllYouthworks');
	try {
		return ApiClient.instance.client.collection(collectionName).getFullList<YouthWork>();
	} catch (error) {
		Logger.error('YouthworkAPI::getAllYouthworks::error::' + error);
		throw error;
	}
}

// View
export function getYouthwork(id: string) {
	Logger.log('YouthworkAPI::getYouthwork::' + id);
	try {
		return ApiClient.instance.client.collection(collectionName).getOne<YouthWork>(id);
	} catch (error) {
		Logger.error('YouthworkAPI::getYouthwork::error::' + error);
		throw error;
	}
}

// Create
export function createYouthwork(youthwork: YouthWork) {
	Logger.log('YouthworkAPI::createYouthwork::' + youthwork.id);
	try {
		return ApiClient.instance.client.collection(collectionName).create<YouthWork>(youthwork);
	} catch (error) {
		Logger.error('YouthworkAPI::createYouthwork::' + youthwork.id + '::error::' + error);
		throw error;
	}
}

// Update
export function updateYouthwork(youthwork: YouthWork) {
	if (youthwork.id) {
		Logger.log('YouthworkAPI::updateYouthwork::' + youthwork.id);
		try {
			return ApiClient.instance.client.collection(collectionName).update<YouthWork>(youthwork.id, youthwork);
		} catch (error) {
			Logger.error('YouthworkAPI::updateYouthwork::' + youthwork.id + '::error::' + error);
			throw error;
		}
	} else {
		return youthwork;
	}
}

// Delete
export function deleteYouthwork(id: string) {
	Logger.log('YouthworkAPI::deleteYouthwork::' + id);
	try {
		return ApiClient.instance.client.collection(collectionName).delete(id);
	} catch (error) {
		Logger.error('YouthworkAPI::deleteYouthwork::error::' + error);
		throw error;
	}
}
