import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import React from 'react';

export type StyledToolbarProps = {
	title: string;
	description?: string | React.ReactNode;
	primaryActions?: React.ReactNode;
};

export const StyledToolbar: React.FC<StyledToolbarProps> = React.memo(
	({ title, primaryActions, description }: StyledToolbarProps) => {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column',
						sm: 'column',
						md: 'row',
						lg: 'row',
						xl: 'row',
					},
					justifyContent: 'space-between',
					alignItems: {
						xs: 'flex-start',
						sm: 'flex-start',
						md: 'flex-end',
					},
					mx: 0,
					px: 0,
					pb: 2,
				}}
			>
				<Box display="flex" flexDirection="column" justifyContent="center">
					<Typography variant="h4">{title}</Typography>
					{typeof description === 'string' ? (
						<Typography variant="subtitle1">{description}</Typography>
					) : (
						description
					)}
				</Box>
				{primaryActions && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: {
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'row',
								xl: 'row',
							},
							justifyContent: {
								lg: 'flex-end',
								xl: 'flex-end',
							},
							gap: 1,
						}}
					>
						{primaryActions}
					</Box>
				)}
			</Box>
		);
	},
);
