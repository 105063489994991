import { createReceiptExtraActions } from './receiptReducer.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import { receiptExtraReducers } from './receiptReducer.extra.reducers';
import type { ReceiptState } from './receiptReducer.state';

const name = 'receipts';
const initialState: ReceiptState = {
	receipts: [],
	byTransaction: {},
	byId: {},
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: receiptExtraReducers,
});

// Exports
export const receiptExtraActions = createReceiptExtraActions();
export const receiptActions = { ...slice.actions, ...receiptExtraActions };
export const receiptReducer = slice.reducer;
