import { bankAccountExtraReducers } from './bankAccount.extra.reducers';
import { createBankAccountExtraActions } from './bankAccount.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { BankAccountState } from './bankAccount.state';

const name = 'bankAccounts';
const initialState: BankAccountState = {
	bankAccounts: [],
	byId: {},
	byYouthWork: {},
	error: null,
	isLoading: false,
};
const reducers = {};

const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: bankAccountExtraReducers,
});

// Exports
export const bankAccountExtraActions = createBankAccountExtraActions();
export const bankAccountActions = { ...slice.actions, ...bankAccountExtraActions };
export const bankAccountReducer = slice.reducer;
