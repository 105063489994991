import 'react-medium-image-zoom/dist/styles.css';
import { Box, Button, Typography } from '@mui/material';
import { StyledPaperListItem } from '../../../../components/Styled/StyledPaperListItem';
import { createReceiptImageURL } from '../../../../../helpers/createReceiptImageURL';
import { getLocalizedAmount } from '../../../../../helpers/getLocalizedAmount';
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import moment from 'moment';
import type { BankAccountTransaction } from '../../../../../models/BankAccountTransaction';
import type { Receipt } from '../../../../../models/Receipt';
import type { TransactionCategory } from '../../../../../models/TransactionCategory';

export type CheckBankAccountListItemProps = {
	transaction: BankAccountTransaction;
	categories: TransactionCategory[];
	receipts: Receipt[];
	onCheck: (transaction: BankAccountTransaction) => void;
	onError: (transaction: BankAccountTransaction) => void;
};

export const CheckBankAccountListItem = React.memo(
	({ transaction, categories, receipts, onCheck, onError }: CheckBankAccountListItemProps) => {
		const category = categories.find((category) => category.id === transaction.transactionCategory);
		const receipt = receipts.find((receipt) => receipt.transaction === transaction.id);

		return (
			<StyledPaperListItem
				leading={
					<>
						{transaction.hasCheckError && (
							<Typography variant="subtitle1" color="red" fontWeight="semibold">
								ACHTUNG: Diese Buchung hat einen Fehler!
							</Typography>
						)}
						{transaction.checkErrorMessage && (
							<Typography variant="subtitle1" color="red">
								Fehlerbeschreibung: {transaction.checkErrorMessage}
							</Typography>
						)}{' '}
						<Typography variant="subtitle1">Kontoauszug: {transaction.bankStatement}</Typography>
						<Typography variant="subtitle1">
							Datum: {moment(transaction.date).format('DD.MM.YYYY')}
						</Typography>
						<Typography variant="subtitle1">Beschreibung: {transaction.description}</Typography>
						<Typography variant="subtitle1">Betrag: {getLocalizedAmount(transaction.amount)}</Typography>
						{transaction.transactionCategory && category && (
							<Typography variant="subtitle1">Kategorie: {category.name}</Typography>
						)}
						{transaction.person && (
							<Typography variant="subtitle1">
								{transaction.amount > 0 ? 'Sender' : 'Empfänger'}: {transaction.person}
							</Typography>
						)}
						{receipt && (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'start',
								}}
							>
								<Typography variant="subtitle1">Quittung:</Typography>
								<Zoom>
									<img
										style={{ maxHeight: 200 }}
										src={createReceiptImageURL(receipt)}
										alt="Receipt"
									/>
								</Zoom>
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								mt: 2,
							}}
						>
							<Button
								variant="contained"
								color="success"
								sx={{ mr: 1 }}
								onClick={() => onCheck(transaction)}
							>
								Korrekt
							</Button>
							<Button variant="contained" color="error" onClick={() => onError(transaction)}>
								Fehler Anmerken
							</Button>
						</Box>
					</>
				}
			/>
		);
	},
);
