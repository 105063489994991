import { authentificationExtraActions } from './authentification.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { AuthenticationState } from './authentification.state';

export const authentificationExtraReducers = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
	loginCases(builder);
	reauthenticateCases(builder);
};

// Login
const loginCases = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
	builder.addCase(authentificationExtraActions.login.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Authentication::login::pending');
	});
	builder.addCase(authentificationExtraActions.login.fulfilled, (state, action) => {
		state.isLoading = false;
		Logger.log('Authentication::login::success');
	});
	builder.addCase(authentificationExtraActions.login.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Authentication::login::error::' + action.error.message);
	});
};

// Reauthenticate
const reauthenticateCases = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
	builder.addCase(authentificationExtraActions.reauthenticate.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Authentication::reauthenticate::pending');
	});
	builder.addCase(authentificationExtraActions.reauthenticate.fulfilled, (state, action) => {
		localStorage.setItem('isAuthenticated', 'true');
		state.isLoading = false;
		Logger.log('Authentication::reauthenticate::success');
	});
	builder.addCase(authentificationExtraActions.reauthenticate.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Authentication::reauthenticate::error::' + action.error.message);
	});
};
