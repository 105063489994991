import { createAdmin, deleteAdmin, getAdmin, getAllAdmins, updateAdmin } from '../../../api_clients/adminAPIClient';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Admin } from 'pocketbase';

const name = 'admin';

export function createAdminExtraActions() {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllAdmins());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getAdmin(id));
	}

	function create() {
		return createAsyncThunk(`${name}/create`, async (admin: Admin) => await createAdmin(admin));
	}

	function update() {
		return createAsyncThunk(`${name}/update`, async (admin: Admin) => await updateAdmin(admin));
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteAdmin(id));
	}
}
