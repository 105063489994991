import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createBargeTransaction,
	deleteBargeTransaction,
	getAllBargeTransactions,
	getBargeTransaction,
	updateBargeTransaction,
} from '../../../api_clients/bargeTransactionAPIClient';
import type { BargeTransaction } from '../../../models/BargeTransaction';

const name = 'bargeTransactions';

export const createBargeTransactionExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllBargeTransactions());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getBargeTransaction(id));
	}

	function create() {
		return createAsyncThunk(
			`${name}/create`,
			async (bargeTransaction: BargeTransaction) => await createBargeTransaction(bargeTransaction),
		);
	}

	function update() {
		return createAsyncThunk(
			`${name}/update`,
			async (bargeTransaction: BargeTransaction) => await updateBargeTransaction(bargeTransaction),
		);
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteBargeTransaction(id));
	}
};
