import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, Receipt as ReceiptIcon } from '@mui/icons-material';
import { deDE } from '@mui/x-data-grid';
import { getLocalizedAmount } from '../../../../helpers/getLocalizedAmount';
import React from 'react';
import moment from 'moment';
import type { BankAccountTransaction } from '../../../../models/BankAccountTransaction';
import type { Receipt } from '../../../../models/Receipt';

export type BankAccountDetailViewDataGridProps = {
	transactions: BankAccountTransaction[];
	receipts: Receipt[];
	setReceiptToShow: (receipt: Receipt) => void;
	setShowReceiptModal: (show: boolean) => void;
	setEditModalTransaction: (transaction: BankAccountTransaction) => void;
	setShowEditModal: (show: boolean) => void;
	setTransactionToDelete: (transaction: BankAccountTransaction) => void;
	setShowConfirmationDialog: (show: boolean) => void;
};

export const BankAccountDetailViewDataGrid = React.memo(
	({
		transactions,
		receipts,
		setReceiptToShow,
		setShowReceiptModal,
		setEditModalTransaction,
		setShowEditModal,
		setTransactionToDelete,
		setShowConfirmationDialog,
	}: BankAccountDetailViewDataGridProps) => (
		<Box
			display="flex"
			flexDirection="column"
			width="100%"
			sx={{
				'& .error': {
					bgcolor: (theme) => theme.palette.error.light,
					'&:hover': {
						bgcolor: (theme) => theme.palette.error.dark,
					},
				},
			}}
		>
			<DataGrid
				autoHeight={true}
				rows={transactions}
				getRowClassName={(params) => {
					if (params.row.hasCheckError) {
						return 'error';
					}
					return '';
				}}
				isRowSelectable={() => false}
				hideFooter
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				columns={[
					{
						field: 'date',
						headerName: 'Datum',
						width: 100,
						valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY'),
					},
					{ field: 'bankStatement', headerName: 'Kontoauszug', width: 130 },
					{
						field: 'amount',
						headerName: 'Betrag',
						width: 130,
						renderCell: ({ value, row }) => getLocalizedAmount(value),
					},
					{ field: 'description', headerName: 'Beschreibung', width: 200 },
					{ field: 'person', headerName: 'Person', width: 140 },
					{
						field: 'transactionCategory',
						headerName: 'Kategorie',
						width: 180,
						renderCell: ({ row }) => row.expand?.transactionCategory?.name ?? '',
					},
					{
						field: 'isChecked',
						headerName: 'Geprüft',
						width: 70,
						renderCell: ({ value }) => (value ? 'Ja' : 'Nein'),
					},
					{
						field: 'hasCheckError',
						headerName: 'Prüffehler?',
						width: 90,
						renderCell: ({ value }) => (value ? 'Ja' : 'Nein'),
					},
					{
						field: 'checkErrorMessage',
						headerName: 'Fehlermeldung',
						width: 200,
					},
					{
						field: 'viewReceipt',
						headerName: 'Beleg Ansehen',
						width: 190,
						renderCell: ({ row }) => {
							if (row.id) {
								const receipt = row.expand?.receipt || receipts.find((r) => r.transaction === row.id);

								if (!receipt || !receipt.image) {
									return null;
								}

								return (
									<Button
										variant="contained"
										color="primary"
										startIcon={<ReceiptIcon />}
										onClick={() => {
											if (row.id) {
												setReceiptToShow(receipt);
												setShowReceiptModal(true);
											}
										}}
									>
										<Typography variant="body2">Beleg Ansehen</Typography>
									</Button>
								);
							}
							return null;
						},
					},
					{
						field: 'actions',
						type: 'actions',
						headerName: 'Aktionen',
						width: 100,
						getActions: ({ row }) => {
							return [
								<GridActionsCellItem
									key={`view-mode-edit-${row.id}`}
									icon={<Edit />}
									label="Edit"
									className="textPrimary"
									onClick={() => {
										setEditModalTransaction(row);
										setShowEditModal(true);
									}}
									color="inherit"
								/>,
								<GridActionsCellItem
									key={`view-mode-delete-${row.id}`}
									icon={<Delete />}
									label="Delete"
									onClick={() => {
										setTransactionToDelete(row);
										setShowConfirmationDialog(true);
									}}
									color="inherit"
								/>,
							];
						},
					},
				]}
			/>
		</Box>
	),
);
