import { CreateYouthWorkForm } from './CreateYouthWorkForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { standardTransactionCategoryActions } from '../../../../../redux/reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { userActions } from '../../../../../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useEffect } from 'react';
import type { CreateYouthWorkFormDataType } from './CreateYouthWorkForm';
import type { User } from '../../../../../models/User';
import type { YouthWork } from '../../../../../models/YouthWork';

export type CreateYouthWorkModalProps = {
	didClose: () => void;
	show: boolean;
};

export const CreateYouthWorkModal = React.memo(({ didClose, show }: CreateYouthWorkModalProps) => {
	const dispatch = useAppDispatch();

	const standardCategories = useAppSelector(
		(state) => state.standardTransactionCategories.standardTransactionCategories,
	);

	useEffect(() => {
		dispatch(standardTransactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledModal show={show} onClose={didClose} heading="Jugendarbeit Erstellen">
			<CreateYouthWorkForm
				submitData={function (data: CreateYouthWorkFormDataType): void {
					dispatch(
						userActions.create({
							email: data.email,
							firstName: data.firstName,
							lastName: data.lastName,
							password: data.password,
							passwordConfirm: data.password,
							username: `${data.firstName.slice(0, 1).toLowerCase()}${data.lastName.toLowerCase()}`,
						}),
					).then((newUser) => {
						const user = newUser.payload as User;

						if (user.id) {
							dispatch(
								youthWorkActions.create({
									name: data.youthWorkName,
									cashier: user.id,
								}),
							)
								.then((newYouthWork) => {
									const youthWork = newYouthWork.payload as YouthWork;

									if (data.shouldCreateStandardCategories) {
										standardCategories.forEach((standardCategory) => {
											dispatch(
												transactionCategoryActions.create({
													name: standardCategory.name,
													description: standardCategory.description,
													youthwork: youthWork.id!,
													isStandard: true,
												}),
											);
										});
									}
								})
								.then(() => {
									didClose();
								});
						} else {
							didClose();
						}
					});
				}}
				didCancel={didClose}
			/>
		</StyledModal>
	);
});
