import { Box, Button, Checkbox, FormControlLabel, ListSubheader } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import React from 'react';

export type CreateYouthWorkFormDataType = {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	youthWorkName: string;
	shouldCreateStandardCategories: boolean;
};

export type CreateYouthWorkFormProps = {
	submitData: (data: CreateYouthWorkFormDataType) => void;
	didCancel: () => void;
};

export const CreateYouthWorkForm = ({ submitData, didCancel }: CreateYouthWorkFormProps) => {
	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			email: '',
			firstName: '',
			lastName: '',
			password: '',
			youthWorkName: '',
			shouldCreateStandardCategories: true,
		},
	});

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<ListSubheader>Jugendarbeit</ListSubheader>
				<Controller
					name="youthWorkName"
					control={control}
					render={({ field }) => <FormTextField field={field} label="Name" isFirstField={true} />}
				/>

				<ListSubheader>Kassier</ListSubheader>
				<Controller
					name="firstName"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Vorname'} />}
				/>
				<Controller
					name="lastName"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Nachname'} />}
				/>
				<Controller
					name="email"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'E-Mail'} type="email" />}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Passwort'} type="password" />}
				/>
				<Controller
					name="shouldCreateStandardCategories"
					control={control}
					render={({ field }) => (
						<FormControlLabel
							control={<Checkbox {...field} />}
							label="Standardkategorien für die Jugendarbeit erstellen"
						/>
					)}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Hinzufügen
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
