import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { Admin } from 'pocketbase';

// List
export async function getAllAdmins() {
	Logger.log('AdminAPI::getAllAdmins');
	try {
		return await ApiClient.instance.client.admins.getFullList();
	} catch (error) {
		Logger.error('AdminAPI::getAllAdmins::error::' + error);
		throw error;
	}
}

// View
export async function getAdmin(id: string) {
	Logger.log('AdminAPI::getAdmin::' + id);
	try {
		return await ApiClient.instance.client.admins.getOne(id);
	} catch (error) {
		Logger.error('AdminAPI::getAdmin::error::' + error);
		throw error;
	}
}

// Create
export async function createAdmin(adminUser: Admin) {
	Logger.log('AdminAPI::createAdmin::' + adminUser.id);
	try {
		return await ApiClient.instance.client.admins.create(adminUser);
	} catch (error) {
		Logger.error('AdminAPI::createAdmin::' + adminUser.id + '::error::' + error);
		throw error;
	}
}

// Update
export async function updateAdmin(adminUser: Admin) {
	if (adminUser.id) {
		Logger.log('AdminAPI::updateAdmin::' + adminUser.id);
		try {
			return await ApiClient.instance.client.admins.update(adminUser.id, adminUser);
		} catch (error) {
			Logger.error('AdminAPI::updateAdmin::' + adminUser.id + '::error::' + error);
			throw error;
		}
	}
}

// Delete
export async function deleteAdmin(id: string) {
	Logger.log('AdminAPI::deleteAdmin::' + id);
	try {
		return await ApiClient.instance.client.admins.delete(id);
	} catch (error) {
		Logger.error('AdminAPI::deleteAdmin::error::' + error);
		throw error;
	}
}
