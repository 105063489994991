import { StyledModal } from '../../../../components/Styled/StyledModal';
import { createReceiptImageURL } from '../../../../../helpers/createReceiptImageURL';
import React from 'react';
import type { Receipt } from '../../../../../models/Receipt';

export type CreateBankAccountTransactionModalProps = {
	receipt?: Receipt;
	didClose: () => void;
	show: boolean;
};

export const ReceiptModal = React.memo(({ receipt, didClose, show }: CreateBankAccountTransactionModalProps) => {
	if (receipt && receipt.image) {
		const imageUrl = createReceiptImageURL(receipt);
		return (
			<StyledModal show={show} onClose={didClose} heading="Quittung">
				<img src={imageUrl} alt="Receipt" />
			</StyledModal>
		);
	}
	return null;
});
