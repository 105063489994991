import { EditYouthWorkForm } from './EditYouthWorkForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { useAppDispatch } from '../../../../../redux/hooks';
import { userActions } from '../../../../../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React from 'react';
import type { EditYouthWorkFormDataType } from './EditYouthWorkForm';
import type { User } from '../../../../../models/User';
import type { YouthWork } from '../../../../../models/YouthWork';

export type EditYouthWorkModalProps = {
	initialYouthWork: YouthWork;
	initialUser: User;
	didClose: () => void;
	show: boolean;
};

export const EditYouthWorkModal = React.memo(
	({ initialYouthWork, initialUser, didClose, show }: EditYouthWorkModalProps) => {
		const dispatch = useAppDispatch();

		return (
			<StyledModal show={show} onClose={didClose} heading="Jugendarbeit Bearbeiten">
				<EditYouthWorkForm
					initialUser={initialUser}
					initialYouthWork={initialYouthWork}
					submitData={function (data: EditYouthWorkFormDataType): void {
						dispatch(
							userActions.update({
								id: initialUser.id,
								email: data.email,
								firstName: data.firstName,
								lastName: data.lastName,
								username: initialUser.username,
								verified: initialUser.verified,
							}),
						).then((newUser) => {
							const user = newUser.payload as User;

							if (user.id) {
								dispatch(
									youthWorkActions.update({
										id: initialYouthWork.id,
										name: data.youthWorkName,
										cashier: user.id,
									}),
								).then(() => didClose());
							} else {
								didClose();
							}
						});
					}}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
