import { bankAccountTransactionExtraReducers } from './bankAccountTransaction.extra.reducer';
import { createBankAccountExtraActions } from './bankAccountTransaction.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { BankAccountTransactionState } from './bankAccountTransaction.state';

const name = 'bankAccountTransactionTransactions';
const initialState: BankAccountTransactionState = {
	bankAccountTransactions: [],
	byId: {},
	byBankAccount: {},
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: bankAccountTransactionExtraReducers,
});

// Exports
export const bankAccountTransactionExtraActions = createBankAccountExtraActions();
export const bankAccountTransactionActions = {
	...slice.actions,
	...bankAccountTransactionExtraActions,
};
export const bankAccountTransactionReducer = slice.reducer;
