import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createBankAccount,
	deleteBankAccount,
	getAllBankAccounts,
	getBankAccount,
	updateBankAccount,
} from '../../../api_clients/bankAccountAPI';
import type { BankAccount } from '../../../models/BankAccount';

const name = 'bankAccounts';

export function createBankAccountExtraActions() {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllBankAccounts());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getBankAccount(id));
	}

	function create() {
		return createAsyncThunk(
			`${name}/create`,
			async (bankAccount: BankAccount) => await createBankAccount(bankAccount),
		);
	}

	function update() {
		return createAsyncThunk(
			`${name}/update`,
			async (bankAccount: BankAccount) => await updateBankAccount(bankAccount),
		);
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteBankAccount(id));
	}
}
