import { Category, Check, DataArray, Download, Group, SsidChart } from '@mui/icons-material';
import { Divider, List, Toolbar } from '@mui/material';
import { ResponsiveContext } from '../../ResponsiveContext/ResponsiveContext';
import { SidebarLink } from '../../ResponsiveContext/SidebarLink';
import { bankAccountActions } from '../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { useAppDispatch } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';

export type AdminSidebarProps = {
	children: React.ReactNode;
};

export const AdminSidebar = React.memo(({ children }: AdminSidebarProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(bankAccountActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const drawer = useMemo(() => {
		return (
			<div>
				<Toolbar />
				<Divider />
				<List>
					<SidebarLink
						text="Benutzerverwaltung"
						icon={<Group />}
						path={'/admin/user-management'}
						onClick={() => navigate('/admin/user-management')}
					/>
					<SidebarLink
						text="Standardkategorien"
						icon={<Category />}
						path={'/admin/standard-transaction-categories'}
						onClick={() => navigate('/admin/standard-transaction-categories')}
					/>
					<SidebarLink
						text="Kassenprüfübersicht"
						icon={<Check />}
						path={'/admin/check-barges-overview'}
						onClick={() => navigate('/admin/check-barges-overview')}
					/>
					<SidebarLink
						text="Export"
						icon={<Download />}
						path={'/admin/export'}
						onClick={() => navigate('/admin/export')}
					/>
					<SidebarLink
						text="Statistiken"
						icon={<SsidChart />}
						path={'/admin/statistics'}
						onClick={() => navigate('/admin/statistics')}
					/>
					<SidebarLink
						text="Logs"
						icon={<DataArray />}
						path={'/admin/logs'}
						onClick={() => navigate('/admin/logs')}
					/>
				</List>
			</div>
		);
	}, [navigate]);

	return (
		<>
			<ResponsiveContext drawer={drawer}>{children}</ResponsiveContext>
		</>
	);
});
