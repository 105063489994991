import { bargeExtraActions } from './barge.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { BargeState } from './barge.state';

export const bargeExtraReducers = (builder: ActionReducerMapBuilder<BargeState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// Fetch All
const fetchAll = (builder: ActionReducerMapBuilder<BargeState>) => {
	// FetchAll
	builder.addCase(bargeExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Barge::fetch::pending');
	});
	builder.addCase(bargeExtraActions.fetchAll.fulfilled, (state, action) => {
		state.barges = action.payload;
		state.isLoading = false;
		Logger.log('Barge::fetch::success');
	});
	builder.addCase(bargeExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Barge::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<BargeState>) => {
	builder.addCase(bargeExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Barge::fetch::pending');
	});
	builder.addCase(bargeExtraActions.fetch.fulfilled, (state, action) => {
		if (state.barges.findIndex((x) => x.id === action.payload.id) >= 0) {
			state.barges = state.barges.map((barge) => {
				if (barge.id === action.payload.id) {
					return action.payload;
				} else {
					return barge;
				}
			});
		} else {
			state.barges.push(action.payload);
		}
		state.isLoading = false;
		Logger.log('Barge::fetch::success');
	});
	builder.addCase(bargeExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Barge::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<BargeState>) => {
	builder.addCase(bargeExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Barge::create::pending');
	});
	builder.addCase(bargeExtraActions.create.fulfilled, (state, action) => {
		state.barges.push(action.payload);
		state.isLoading = false;
		Logger.log('Barge::create::success');
	});
	builder.addCase(bargeExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Barge::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<BargeState>) => {
	builder.addCase(bargeExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.error('Barge::update::pending');
	});
	builder.addCase(bargeExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.barges = state.barges.map((barge) => {
			if (barge.id === payload.id) {
				return payload;
			} else {
				return barge;
			}
		});
		state.isLoading = false;
		Logger.log('Barge::update::success');
	});
	builder.addCase(bargeExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Barge::update::error::' + action.error.message);
	});
};

// Delete
const remove = (builder: ActionReducerMapBuilder<BargeState>) => {
	builder.addCase(bargeExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('Barge::remove::pending');
	});
	builder.addCase(bargeExtraActions.remove.fulfilled, (state, action) => {
		state.barges = state.barges.filter((barge) => barge.id !== action.meta.arg);
		state.isLoading = false;
		Logger.log('Barge::remove::success');
	});
	builder.addCase(bargeExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('Barge::remove::error::' + action.error.message);
	});
};
