import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { Barge } from '../models/Barge';

const collectionName = 'barges';

// List
export async function getAllBarges() {
	Logger.log('BargeAPI::getAllBarges');
	try {
		const response = await ApiClient.instance.client.collection(collectionName).getFullList<Barge>();
		return response;
	} catch (error) {
		Logger.error('BargeAPI::getAllBarges::error::' + error);
		throw error;
	}
}

// View
export async function getBarge(id: string) {
	Logger.log('BargeAPI::getBarge::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<Barge>(id);
	} catch (error) {
		Logger.error('BargeAPI::getBarge::error::' + error);
		throw error;
	}
}

// Create
export async function createBarge(barge: Barge) {
	Logger.log('BargeAPI::createBarge::' + barge.id);
	try {
		return await ApiClient.instance.client.collection(collectionName).create<Barge>(barge);
	} catch (error) {
		Logger.error('BargeAPI::createBarge::' + barge.id + '::error::' + error);
		throw error;
	}
}

// Update
export async function updateBarge(barge: Barge) {
	if (barge.id) {
		Logger.log('BargeAPI::updateBarge::' + barge.id);
		try {
			return await ApiClient.instance.client.collection(collectionName).update<Barge>(barge.id, barge);
		} catch (error) {
			Logger.error('BargeAPI::updateBarge::' + barge.id + '::error::' + error);
			throw error;
		}
	} else {
		return barge;
	}
}

// Delete
export async function deleteBarge(id: string) {
	Logger.log('BargeAPI::deleteBarge::' + id);
	if (id) {
		try {
			return await ApiClient.instance.client.collection(collectionName).delete(id);
		} catch (error) {
			Logger.error('BargeAPI::deleteBarge::' + id + '::error::' + error);
			throw error;
		}
	} else {
		throw new Error('BargeAPI::deleteBarge::id is null');
	}
}
