import { generateExcelSheetFromBankAccount } from './generateExcelSheetFromBankAccount';
import { generateExcelSheetFromBarge } from './generateExcelSheetFromBarge';
import { utils, writeFile } from 'xlsx';
import type { BankAccount } from '../../models/BankAccount';
import type { BankAccountTransaction } from '../../models/BankAccountTransaction';
import type { Barge } from '../../models/Barge';
import type { BargeTransaction } from '../../models/BargeTransaction';
import type { TransactionCategory } from '../../models/TransactionCategory';
import type { YouthWork } from '../../models/YouthWork';

export const downloadKassenbuchFormat = (
	bankAccounts: BankAccount[],
	transactions: BankAccountTransaction[],
	barges: Barge[],
	bargeTransactions: BargeTransaction[],
	youthWork: YouthWork,
	transactionCategories: TransactionCategory[],
	year: number,
	document: Document,
) => {
	const workbook = utils.book_new();

	utils.book_append_sheet(
		workbook,
		utils.table_to_sheet(document.getElementById('overviewTable'), { raw: true }),
		'Übersicht',
	);

	bankAccounts.forEach((bankAccount) => {
		const bankAccountTransactions = transactions.filter(
			(transaction) => transaction.bankAccount === bankAccount.id,
		);
		utils.book_append_sheet(
			workbook,
			generateExcelSheetFromBankAccount(bankAccount, bankAccountTransactions, transactionCategories),
			bankAccount.accountDescription.substring(0, 31),
		);
	});

	barges.forEach((barge) => {
		const filteredBargeTransactions = bargeTransactions.filter((transaction) => transaction.barge === barge.id);

		utils.book_append_sheet(
			workbook,
			generateExcelSheetFromBarge(barge, filteredBargeTransactions, transactionCategories),
			barge.accountDescription.substring(0, 31),
		);
	});

	writeFile(workbook, `Kassenbuch-${youthWork.name}.xlsx`, {
		compression: true,
	});
};
