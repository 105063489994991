import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createTransactionCategory,
	deleteTransactionCategory,
	getAllTransactionCategories,
	getTransactionCategory,
	updateTransactionCategory,
} from '../../../api_clients/transactionCategoryAPI';
import type { TransactionCategory } from '../../../models/TransactionCategory';

const name = 'transactionCategories';

export const createTransactionCategoryExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllTransactionCategories());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getTransactionCategory(id));
	}

	function create() {
		return createAsyncThunk(
			`${name}/create`,
			async (transactionCategory: TransactionCategory) => await createTransactionCategory(transactionCategory),
		);
	}

	function update() {
		return createAsyncThunk(
			`${name}/update`,
			async (transactionCategory: TransactionCategory) => await updateTransactionCategory(transactionCategory),
		);
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteTransactionCategory(id));
	}
};
