import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';

export type StyledMarkdownProps = {
	markdown: string;
};

export const StyledMarkdown = React.memo(({ markdown }: StyledMarkdownProps) => {
	return (
		<ReactMarkdown
			components={{
				h4({ node, ...props }) {
					return <Typography variant="h4" {...props} />;
				},
				h5({ node, ...props }) {
					return <Typography variant="h5" {...props} />;
				},
				h6({ node, ...props }) {
					return <Typography variant="h6" {...props} />;
				},
				p({ node, ...props }) {
					return <Typography variant="body1" {...props} />;
				},
				ul({ node, ...props }) {
					return <List sx={{ listStyleType: 'disc', pl: 4 }} {...props} />;
				},
				li({ node, ...props }) {
					return <ListItem sx={{ display: 'list-item' }} {...props} />;
				},
			}}
		>
			{markdown}
		</ReactMarkdown>
	);
});
