import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';

// List
export async function getAllLogs() {
	Logger.log('LogsAPI::getAllLogs');
	try {
		return await ApiClient.instance.client.logs.getRequestsList(1, 200, {
			sort: '-rowid',
		});
	} catch (error) {
		Logger.error('LogsAPI::getAllLogs::error::' + error);
		throw error;
	}
}

// List Statistics
export async function getRequestStatistics() {
	Logger.log('LogsAPI::getRequestStatistics');
	try {
		return await ApiClient.instance.client.logs.getRequestsStats();
	} catch (error) {
		Logger.error('LogsAPI::getRequestStatistics::error::' + error);
		throw error;
	}
}

// View
export async function getLog(id: string) {
	Logger.log('LogsAPI::getLog::' + id);
	try {
		return await ApiClient.instance.client.logs.getRequest(id);
	} catch (error) {
		Logger.error('LogsAPI::getLog::error::' + error);
		throw error;
	}
}
