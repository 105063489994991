import { bargeTransactionExtraActions } from './bargeTransaction.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { BargeTransactionState } from './bargeTransaction.state';

export const bargeTransactionExtraReducers = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	builder.addCase(bargeTransactionExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BargeTransaction::fetch::pending');
	});
	builder.addCase(bargeTransactionExtraActions.fetchAll.fulfilled, (state, action) => {
		state.bargeTransactions = action.payload;
		state.isLoading = false;
		Logger.log('BargeTransaction::fetch::success');
	});
	builder.addCase(bargeTransactionExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BargeTransaction::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	builder.addCase(bargeTransactionExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BargeTransaction::fetch::pending');
	});
	builder.addCase(bargeTransactionExtraActions.fetch.fulfilled, (state, action) => {
		if (state.bargeTransactions.findIndex((bargeTransaction) => bargeTransaction.id === action.payload.id) >= 0) {
			state.bargeTransactions = state.bargeTransactions.map((bargeTransaction) => {
				if (bargeTransaction.id === action.payload.id) {
					return action.payload;
				} else {
					return bargeTransaction;
				}
			});
		} else {
			state.bargeTransactions.push(action.payload);
		}
		state.isLoading = false;
		Logger.log('BargeTransaction::fetch::success');
	});
	builder.addCase(bargeTransactionExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BargeTransaction::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	builder.addCase(bargeTransactionExtraActions.create.pending, (state, action) => {
		state.error = null;
		Logger.log('BargeTransaction::create::pending');
	});
	builder.addCase(bargeTransactionExtraActions.create.fulfilled, (state, action) => {
		state.bargeTransactions.push(action.payload);
		Logger.log('BargeTransaction::create::success');
	});
	builder.addCase(bargeTransactionExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		Logger.error('BargeTransaction::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	builder.addCase(bargeTransactionExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BargeTransaction::update::pending');
	});
	builder.addCase(bargeTransactionExtraActions.update.fulfilled, (state, action) => {
		state.bargeTransactions = state.bargeTransactions.map((bargeTransaction) => {
			if (bargeTransaction.id === action.payload.id) {
				return action.payload;
			} else {
				return bargeTransaction;
			}
		});
		state.isLoading = false;
		Logger.log('BargeTransaction::update::success');
	});
	builder.addCase(bargeTransactionExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BargeTransaction::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<BargeTransactionState>) => {
	builder.addCase(bargeTransactionExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('BargeTransaction::delete::pending');
	});
	builder.addCase(bargeTransactionExtraActions.remove.fulfilled, (state, action) => {
		state.isLoading = false;
		Logger.log('BargeTransaction::delete::success');
	});
	builder.addCase(bargeTransactionExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('BargeTransaction::delete::error::' + action.error.message);
	});
};
