import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createBankAccountTransaction,
	deleteBankAccountTransaction,
	getAllBankAccountTransactions,
	getBankAccountTransaction,
	updateBankAccountTransaction,
} from '../../../api_clients/bankAccountTransactionAPI';
import type { BankAccountTransaction } from '../../../models/BankAccountTransaction';

const name = 'bankAccountTransactionTransactions';

export const createBankAccountExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllBankAccountTransactions());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getBankAccountTransaction(id));
	}

	function create() {
		return createAsyncThunk(
			`${name}/create`,
			async (bankAccountTransaction: BankAccountTransaction) =>
				await createBankAccountTransaction(bankAccountTransaction),
		);
	}

	function update() {
		return createAsyncThunk(
			`${name}/update`,
			async (bankAccountTransaction: BankAccountTransaction) =>
				await updateBankAccountTransaction(bankAccountTransaction),
		);
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteBankAccountTransaction(id));
	}
};
