import { CreateStandardTransactionCategoryForm } from './CreateStandardTransactionCategoryForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { standardTransactionCategoryActions } from '../../../../../redux/reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React from 'react';
import type { CreateStandardTransactionCategoryFormDataType } from './CreateStandardTransactionCategoryForm';

export type CreateStandardTransactionCategoryModalProps = {
	didClose: () => void;
	show: boolean;
};

export const CreateStandardTransactionCategoryModal = React.memo(
	({ didClose, show }: CreateStandardTransactionCategoryModalProps) => {
		const dispatch = useAppDispatch();

		return (
			<StyledModal show={show} onClose={didClose} heading="Standard Transaktionskategorie Erstellen">
				<CreateStandardTransactionCategoryForm
					submitData={function (data: CreateStandardTransactionCategoryFormDataType): void {
						dispatch(
							standardTransactionCategoryActions.create({
								name: data.name,
								description: data.description,
							}),
						).then(() => didClose());
					}}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
