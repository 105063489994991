import { Box, Modal, Typography } from '@mui/material';
import React from 'react';

export type StyledModalProps = {
	show: boolean;
	onClose: () => void;
	heading?: string;
	children: React.ReactNode;
};

export const StyledModal = React.memo(({ show, onClose, heading, children }: StyledModalProps) => {
	return (
		<Modal open={show} onClose={onClose}>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
				}}
			>
				{heading && (
					<Typography variant="h5" mb={2}>
						{heading}
					</Typography>
				)}
				{children}
			</Box>
		</Modal>
	);
});
