import { Box, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { calculateBankAccountBalanceExcel } from '../../helpers/calculateBankAccountBalance';
import { calculateBargeBalanceExcel } from '../../helpers/calculateBargeBalance';
import { downloadKassenbuchFormat } from '../../helpers/excel/downloadKassenbuchFormat';
import { getExcelLocalizedAmount } from '../../helpers/excel/getExcelLocalizedAmount';
import React from 'react';
import type { BankAccount } from '../../models/BankAccount';
import type { BankAccountTransaction } from '../../models/BankAccountTransaction';
import type { Barge } from '../../models/Barge';
import type { BargeTransaction } from '../../models/BargeTransaction';
import type { TransactionCategory } from '../../models/TransactionCategory';
import type { YouthWork } from '../../models/YouthWork';

export type ExportKassenbuchButtonProps = {
	bankAccounts: BankAccount[];
	bankAccountTransactions: BankAccountTransaction[];
	barges: Barge[];
	bargeTransactions: BargeTransaction[];
	youthWork: YouthWork;
	transactionCategories: TransactionCategory[];
	year: number;
	title?: string;
};

export const ExportKassenbuchButton = React.memo(
	({
		bankAccounts,
		bankAccountTransactions,
		barges,
		bargeTransactions,
		youthWork,
		transactionCategories,
		year,
		title = 'Excel Datei im Kassenbuch Format herunterladen',
	}: ExportKassenbuchButtonProps) => {
		return (
			<>
				<Box>
					<Button
						variant="contained"
						startIcon={<Download />}
						onClick={() => {
							downloadKassenbuchFormat(
								bankAccounts,
								bankAccountTransactions,
								barges,
								bargeTransactions,
								youthWork,
								transactionCategories,
								year,
								document,
							);
						}}
					>
						{title}
					</Button>
				</Box>
				<table hidden id="overviewTable">
					<tr>
						<th>SWD-EC-Verband: Einnahmen und Ausgaben - Jahresübersicht</th>
					</tr>
					<tr>
						<th>EC-Jugendarbeit: {youthWork.name}</th>
						<th>Jahr: {year}</th>
					</tr>
					<tr></tr>
					<tr>
						<th>Einnahmen</th>
						<th></th>
						<th></th>
						<th></th>
						<th>Ausgaben</th>
					</tr>
					{transactionCategories.map((transactionCategory) => {
						const bankAccountTransactionsOfCategory = bankAccountTransactions.filter(
							(bankAccountTransaction) =>
								bankAccountTransaction.transactionCategory === transactionCategory.id,
						);
						const bargeTransactionsOfCategory = bargeTransactions.filter(
							(bargeTransaction) => bargeTransaction.transactionCategory === transactionCategory.id,
						);
						const bankAccountTransactionsOfCategoryIncome = bankAccountTransactionsOfCategory.filter(
							(bankAccountTransaction) => bankAccountTransaction.amount > 0,
						);
						const bankAccountTransactionsOfCategoryExpense = bankAccountTransactionsOfCategory.filter(
							(bankAccountTransaction) => bankAccountTransaction.amount < 0,
						);
						const bargeTransactionsOfCategoryIncome = bargeTransactionsOfCategory.filter(
							(bargeTransaction) => bargeTransaction.amount > 0,
						);
						const bargeTransactionsOfCategoryExpense = bargeTransactionsOfCategory.filter(
							(bargeTransaction) => bargeTransaction.amount < 0,
						);

						const incomeSum =
							bankAccountTransactionsOfCategoryIncome.reduce(
								(sum, bankAccountTransaction) => sum + bankAccountTransaction.amount,
								0,
							) +
							bargeTransactionsOfCategoryIncome.reduce(
								(sum, bargeTransaction) => sum + bargeTransaction.amount,
								0,
							);
						const expenseSum =
							bankAccountTransactionsOfCategoryExpense.reduce(
								(sum, bankAccountTransaction) => sum + bankAccountTransaction.amount,
								0,
							) +
							bargeTransactionsOfCategoryExpense.reduce(
								(sum, bargeTransaction) => sum + bargeTransaction.amount,
								0,
							);

						return (
							<tr key={transactionCategory.id}>
								<td>{transactionCategory.name}</td>
								<td>{getExcelLocalizedAmount(incomeSum)}</td>
								<td></td>
								<td></td>
								<td>{transactionCategory.name}</td>
								<td>{getExcelLocalizedAmount(expenseSum)}</td>
							</tr>
						);
					})}
					<tr></tr>
					<tr>
						<td>Summe aller Einnahmen</td>
						<td>
							{getExcelLocalizedAmount(
								bankAccountTransactions
									.filter((bankAccountTransaction) => bankAccountTransaction.amount > 0)
									.reduce((sum, bankAccountTransaction) => sum + bankAccountTransaction.amount, 0),
							)}
						</td>
						<td></td>
						<td></td>
						<td>Summe aller Ausgaben</td>
						<td>
							{getExcelLocalizedAmount(
								bankAccountTransactions
									.filter((bankAccountTransaction) => bankAccountTransaction.amount < 0)
									.reduce((sum, bankAccountTransaction) => sum + bankAccountTransaction.amount, 0),
							)}
						</td>
					</tr>
					<tr></tr>
					<tr></tr>
					<tr></tr>
					<tr>
						<td>Konten:</td>
					</tr>
					{bankAccounts.map((bankAccount) => {
						const transactionsOfBankAccount = bankAccountTransactions.filter(
							(bankAccountTransaction) => bankAccountTransaction.bankAccount === bankAccount.id,
						);
						return (
							<tr key={bankAccount.id}>
								<td>Stand {bankAccount.accountDescription}</td>
								<td></td>
								<td>{calculateBankAccountBalanceExcel(transactionsOfBankAccount)}</td>
							</tr>
						);
					})}
					<tr></tr>
					<tr>
						<td>Barkassen:</td>
					</tr>
					{barges.map((barge) => {
						const transactionsOfBarge = bargeTransactions.filter(
							(bargeTransaction) => bargeTransaction.barge === barge.id,
						);
						return (
							<tr key={barge.id}>
								<td>Stand {barge.accountDescription}</td>
								<td></td>
								<td>{calculateBargeBalanceExcel(transactionsOfBarge)}</td>
							</tr>
						);
					})}
				</table>
			</>
		);
	},
);
