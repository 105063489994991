import { authentificationExtraReducers } from './authentification.extra.reducers';
import { createAuthentificationExtraActions } from './authentification.extra.actions';
import { createSlice } from '@reduxjs/toolkit';
import type { AuthenticationState } from './authentification.state';

const name = 'authentification';
const initialState: AuthenticationState = {
	error: null,
	isLoading: false,
};
const reducers = createReducers();

export const authentificationExtraActions = createAuthentificationExtraActions();

const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: authentificationExtraReducers,
});

export const authActions = { ...slice.actions, ...authentificationExtraActions };
export const authenticationReducer = slice.reducer;

function createReducers() {
	return {
		logout,
	};

	function logout(state: AuthenticationState) {
		state.error = null;
		localStorage.removeItem('isAuthenticated');
		localStorage.removeItem('pocketbase_auth');
	}
}
