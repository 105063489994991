import { adminActions } from '../../redux/reducers/adminReducer/admin.slice';
import { bankAccountActions } from '../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeActions } from '../../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { logsActions } from '../../redux/reducers/LogsReducer/logs.slice';
import { receiptActions } from '../../redux/reducers/receiptsReducer/receiptReducer.slice';
import { standardTransactionCategoryActions } from '../../redux/reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { store } from '../../redux/store';
import { transactionCategoryActions } from '../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { userActions } from '../../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../../redux/reducers/YouthWorkReducer/youthWork.slice';

export const fetchMasterData = async (isAdmin = false) => {
	const dispatch = store.dispatch;

	if (isAdmin) {
		dispatch(adminActions.fetchAll());
		dispatch(logsActions.fetchLogs());
		dispatch(logsActions.fetchStatistics());
		dispatch(standardTransactionCategoryActions.fetchAll());
		dispatch(userActions.fetchAll());
	}

	dispatch(bankAccountActions.fetchAll());
	dispatch(bankAccountTransactionActions.fetchAll());
	dispatch(bargeActions.fetchAll());
	dispatch(bargeTransactionActions.fetchAll());
	dispatch(transactionCategoryActions.fetchAll());
	dispatch(receiptActions.fetchAll());
	dispatch(youthWorkActions.fetchAll());
};
