export const Strings = {
	admin: {
		userManagement: {
			siteTitle: 'Benutzerverwaltung - Kassenbuch',
			heading: 'Benutzerverwaltung',
			subheading: 'Hier kannst du Benutzer, Jugendarbeit und Bankkonten verwalten.',
			createYouthWork: 'Jugendarbeit erstellen',
			deleteYouthWorkConfirmation:
				'Möchtest du den Benutzer, die Jugendarbeit und alle mit damit verbundenen Bankkonten, Buchungen und Belege wirklich unwiederruflich löschen?',
		},
	},
	global: {
		deleteButtonTitle: 'Löschen',
	},
};
