import { generateChangelog } from './changelog/generateChangelog';
import { isLocalDev } from '../helpers/isDev';

export const APPCONFIG = {
	API_BASE_URL: isLocalDev ? 'http://127.0.0.1:8090' : 'https://kassenbuch.niklas.services',
	API_VERSION: 'v1',
	APP_VERSION: process.env.REACT_APP_VERSION || '0.0.0',
	SUPPORT_EMAIL: 'git+swdec-kassenbuch-3-3rzzgad8hfg3wreat98nlipvs-issue@swdec.de',
	DSN: 'https://03b4a985a34f418484377ab42f4b93be@glitchtip.niklas.services/1',
	CHANGELOG: `#### Changelog\n${generateChangelog()}`,
};
