import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CreateBargeTransactionModal } from './components/CreateBargeTransactionModal';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { DeleteConfirmationDialog } from '../../../components/Modals/DeleteConfirmationDialog';
import { EditBargeTransactionModal } from './components/EditBargeTransactionModal';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bargeActions } from '../../../../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { calculateBargeBalance } from '../../../../helpers/calculateBargeBalance';
import { getLocalizedAmount } from '../../../../helpers/getLocalizedAmount';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import type { BargeTransaction } from '../../../../models/BargeTransaction';

export const BargeDetailView = React.memo(() => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const barge = useAppSelector((state) => state.barges.barges.find((barge) => barge.id === id));
	const transactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions).filter(
		(transaction) => transaction.barge === id,
	);

	const categoriesById = useAppSelector((state) => state.transactionCategories.byId);

	useEffect(() => {
		if (id) {
			dispatch(bargeActions.fetch(id));
		}

		dispatch(transactionCategoryActions.fetchAll());
		dispatch(bargeTransactionActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (barge) {
			document.title = `${barge.accountDescription} - Kassenbuch`;
		} else {
			document.title = `Kassenbuch`;
		}
	}, [barge]);

	const [showCreateTransactionDialog, setShowCreateTransactionDialog] = React.useState(false);

	const [editTransactionDialog, setEditTransactionDialog] = React.useState(false);
	const [selectedTransaction, setSelectedTransaction] = React.useState<BargeTransaction | undefined>(undefined);

	const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

	const didCloseCreateTransactionDialog = () => setShowCreateTransactionDialog(false);
	const didCloseEditTransactionDialog = () => {
		setEditTransactionDialog(false);
		setSelectedTransaction(undefined);
	};

	const dataGrid = useMemo(
		() => (
			<DataGrid
				autoHeight={true}
				rows={transactions}
				isRowSelectable={() => false}
				hideFooter
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				getRowClassName={(params) => {
					if (params.row.hasCheckError) {
						return 'error';
					}
					return '';
				}}
				columns={[
					{
						field: 'date',
						headerName: 'Datum',
						width: 100,
						valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY'),
					},
					{
						field: 'amount',
						headerName: 'Betrag',
						width: 130,
						renderCell: ({ value, row }) => getLocalizedAmount(value),
					},
					{ field: 'description', headerName: 'Beschreibung', width: 200 },
					{ field: 'person', headerName: 'Person', width: 140 },
					{
						field: 'transactionCategory',
						headerName: 'Kategorie',
						width: 200,
						renderCell: ({ row }) => categoriesById[row.transactionCategory!]?.name ?? '',
					},
					{
						field: 'isChecked',
						headerName: 'Geprüft',
						width: 70,
						renderCell: ({ value }) => (value ? 'Ja' : 'Nein'),
					},
					{
						field: 'hasCheckError',
						headerName: 'Prüffehler?',
						width: 90,
						renderCell: ({ value }) => (value ? 'Ja' : 'Nein'),
					},
					{
						field: 'checkErrorMessage',
						headerName: 'Fehlermeldung',
						width: 200,
					},
					{
						field: 'edit',
						headerName: 'Bearbeiten',
						width: 145,
						renderCell: ({ row }) => (
							<Button
								variant="contained"
								color="primary"
								startIcon={<Edit />}
								onClick={() => {
									setSelectedTransaction(row);
									setEditTransactionDialog(true);
								}}
							>
								<Typography variant="body2">Bearbeiten</Typography>
							</Button>
						),
					},
					{
						field: 'delete',
						headerName: 'Löschen',
						width: 130,
						renderCell: ({ row }) => (
							<Button
								variant="contained"
								color="error"
								startIcon={<Delete />}
								onClick={() => {
									setSelectedTransaction(row);
									setShowConfirmDialog(true);
								}}
							>
								<Typography variant="body2">Löschen</Typography>
							</Button>
						),
					},
				]}
			/>
		),
		[categoriesById, transactions],
	);

	if (!barge && !transactions) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<CreateBargeTransactionModal
				show={showCreateTransactionDialog}
				didClose={didCloseCreateTransactionDialog}
				barge={barge!}
			/>
			<EditBargeTransactionModal
				show={editTransactionDialog}
				didClose={didCloseEditTransactionDialog}
				bankAccount={barge!}
				transaction={selectedTransaction!}
			/>
			<DeleteConfirmationDialog
				show={showConfirmDialog}
				itemToDeleteTitle={selectedTransaction?.description}
				onCancel={() => setShowConfirmDialog(false)}
				onDelete={() => {
					if (selectedTransaction && selectedTransaction?.id) {
						dispatch(bargeTransactionActions.remove(selectedTransaction.id)).then(() => {
							setShowConfirmDialog(false);
							dispatch(bargeTransactionActions.fetchAll());
						});
					}
				}}
			/>
			<StyledToolbar
				title={barge?.accountDescription ?? ''}
				description={`Wert der Kasse: ${calculateBargeBalance(transactions)}`}
				primaryActions={
					<Button
						variant="contained"
						onClick={() => setShowCreateTransactionDialog(true)}
						startIcon={<Add />}
					>
						Buchung hinzufügen
					</Button>
				}
			/>
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				sx={{
					'& .error': {
						bgcolor: (theme) => theme.palette.error.light,
						'&:hover': {
							bgcolor: (theme) => theme.palette.error.dark,
						},
					},
				}}
			>
				{dataGrid}
			</Box>
		</>
	);
});
