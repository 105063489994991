import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Constants } from '../../../../globals/Constants';
import { Stack } from '@mui/system';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeTransactionActions } from '../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { calculateBargeBalance } from '../../../../helpers/calculateBargeBalance';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { BargeTransaction } from '../../../../models/BargeTransaction';

import { CheckBargeListItem } from './components/CheckBargeListItem';
import { LinearProgressWithLabel } from '../BankAccountDetailView/components/LinearProgressWithLabel';

export const CheckBarge = React.memo(() => {
	const dispatch = useAppDispatch();

	const { id } = useParams();

	const barge = useAppSelector((state) => state.barges.barges.find((barge) => barge.id === id));
	const transactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions).filter(
		(transaction) => transaction.barge === id,
	);
	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);

	const uncheckedTransactions = useMemo(
		() => transactions.filter((transaction) => !transaction.isChecked),
		[transactions],
	);

	const [errorTextDialogIsOpen, setErrorTextDialogIsOpen] = React.useState(false);
	const [errorText, setErrorText] = React.useState('');
	const [transactionToError, setTransactionToError] = React.useState<BargeTransaction | undefined>(undefined);

	const didCancelErrorTextDialog = useCallback(() => {
		setTransactionToError(undefined);
		setErrorTextDialogIsOpen(false);
	}, []);

	const didSubmitErrorTextDialog = useCallback(() => {
		if (!(errorText.length > 0)) {
			return;
		}

		if (transactionToError) {
			dispatch(
				bargeTransactionActions.update({
					...transactionToError,
					checkErrorMessage: errorText,
					hasCheckError: true,
				}),
			).then(() =>
				dispatch(bankAccountTransactionActions.fetchAll()).then(() => setErrorTextDialogIsOpen(false)),
			);
		} else {
			setErrorTextDialogIsOpen(false);
		}
	}, [dispatch, errorText, transactionToError]);

	useEffect(() => {
		dispatch(bankAccountActions.fetchAll());
		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());

		if (barge) {
			document.title = `Prüfung: ${barge.accountDescription} - Kassenbuch`;
		} else {
			document.title = `Kassenprüfung - Kassenbuch`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uncheckedTransactionsListItems = useMemo(
		() =>
			uncheckedTransactions.map((transaction) => {
				return (
					<CheckBargeListItem
						key={transaction.id}
						transaction={transaction}
						categories={categories}
						onCheck={(transaction) => {
							dispatch(
								bargeTransactionActions.update({
									...transaction,
									isChecked: true,
									hasCheckError: false,
									checkErrorMessage: '',
								}),
							).then(() => dispatch(bankAccountTransactionActions.fetchAll()));
						}}
						onError={(transaction) => {
							setTransactionToError(transaction);
							setErrorTextDialogIsOpen(true);
						}}
					/>
				);
			}),
		[categories, dispatch, uncheckedTransactions],
	);

	if (!(barge && barge.accountDescription)) {
		return <div>Loading</div>;
	}

	return (
		<>
			<Dialog open={errorTextDialogIsOpen} onClose={didCancelErrorTextDialog}>
				<DialogTitle>Welchen Fehler hast du mit dieser Buchung erkannt?</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="errorText"
						label="Fehlerbeschreibung"
						type="text"
						fullWidth
						variant="standard"
						onChange={(event) => setErrorText(event.target.value)}
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={didCancelErrorTextDialog}>
						Abbrechen
					</Button>
					<Button variant="contained" onClick={didSubmitErrorTextDialog}>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
			<StyledToolbar title={`Kontoprüfung: ${barge?.accountDescription}`} />
			<Stack gap={Constants.gap}>
				<StyledPaperListItem
					leading={
						<>
							<Typography variant="subtitle1">Saldo: {calculateBargeBalance(transactions)}</Typography>
							<Typography variant="subtitle1">Buchungen: {transactions.length}</Typography>
							<Typography variant="subtitle1">
								Ungeprüfte Buchungen: {uncheckedTransactions.length}
							</Typography>
							<Typography sx={{ mt: 1 }} variant="subtitle1">
								Prüffortschritt:
							</Typography>
							<LinearProgressWithLabel
								value={100 - (uncheckedTransactions.length / transactions.length) * 100}
							/>
						</>
					}
				/>
				{uncheckedTransactions.length > 0 && <Typography variant="h5">Ungeprüfte Buchungen</Typography>}
				<Stack gap={Constants.gap}>{uncheckedTransactionsListItems}</Stack>
			</Stack>
		</>
	);
});
