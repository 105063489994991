import { EditBargeTransactionForm } from './EditBargeTransactionForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { bargeTransactionActions } from '../../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { Barge } from '../../../../../models/Barge';
import type { BargeTransaction } from '../../../../../models/BargeTransaction';
import type { EditBargeTransactionFormDataType } from './EditBargeTransactionForm';

export type EditBargeTransactionModalProps = {
	bankAccount: Barge;
	transaction: BargeTransaction;
	didClose: () => void;
	show: boolean;
};

export const EditBargeTransactionModal = React.memo(
	({ bankAccount, transaction, didClose, show }: EditBargeTransactionModalProps) => {
		const dispatch = useAppDispatch();

		useEffect(() => {
			dispatch(transactionCategoryActions.fetchAll());
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<StyledModal show={show} onClose={didClose} heading="Buchung Bearbeiten">
				<EditBargeTransactionForm
					submitData={function (data: EditBargeTransactionFormDataType): void {
						dispatch(
							bargeTransactionActions.update({
								id: transaction.id,
								date: moment(data.date).format('YYYY-MM-DD HH:mm:ss'),
								amount: data.amount,
								description: data.description,
								person: data.person,
								barge: transaction.barge,
								transactionCategory: data.transactionCategory,
							}),
						).then(() => didClose());
					}}
					initialData={transaction}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
