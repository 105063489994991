import { createSlice } from '@reduxjs/toolkit';
import { createTransactionCategoryExtraActions } from './transactionCategory.extra.actions';
import { transactionCategoryExtraReducers } from './transactionCategory.extra.reducers';
import type { TransactionCategoryState } from './transactionCategory.state';

const name = 'transactionCategories';
const initialState: TransactionCategoryState = {
	transactionCategories: [],
	byId: {},
	error: null,
	isLoading: false,
};
const reducers = {};
const slice = createSlice({
	name,
	initialState,
	reducers,
	extraReducers: transactionCategoryExtraReducers,
});

// Exports
export const transactionCategoryExtraActions = createTransactionCategoryExtraActions();
export const transactionCategoryActions = { ...slice.actions, ...transactionCategoryExtraActions };
export const transactionCategoryReducer = slice.reducer;
