import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createYouthwork,
	deleteYouthwork,
	getAllYouthwork,
	getYouthwork,
	updateYouthwork,
} from '../../../api_clients/youthworkAPI';
import type { YouthWork } from '../../../models/YouthWork';

const name = 'youthWorks';

export const createYouthworkExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllYouthwork());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getYouthwork(id));
	}

	function create() {
		return createAsyncThunk(`${name}/create`, async (youthwork: YouthWork) => await createYouthwork(youthwork));
	}

	function update() {
		return createAsyncThunk(`${name}/update`, async (youthwork: YouthWork) => await updateYouthwork(youthwork));
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteYouthwork(id));
	}
};
