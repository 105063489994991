import { generateExcelSheetFromBankAccount } from './generateExcelSheetFromBankAccount';
import { utils, writeFile } from 'xlsx';
import type { BankAccount } from '../../models/BankAccount';
import type { BankAccountTransaction } from '../../models/BankAccountTransaction';
import type { TransactionCategory } from '../../models/TransactionCategory';

export const downloadSingleBankAccount = (
	bankAccount: BankAccount,
	transactions: BankAccountTransaction[],
	transactionCategories: TransactionCategory[],
) => {
	const workbook = utils.book_new();
	utils.book_append_sheet(
		workbook,
		generateExcelSheetFromBankAccount(bankAccount, transactions, transactionCategories),
		bankAccount.accountDescription.substring(0, 31),
	);

	writeFile(workbook, `${bankAccount.accountDescription}.xlsx`, {
		compression: true,
	});
};
