import * as Sentry from '@sentry/browser';
import { isDev } from '../helpers/isDev';
import ApiClient from './apiClient';

const Logger = {
	log(message: string) {
		if (isDev) {
			if (message.includes('autocancelled')) {
				return;
			} else {
				console.log(message);
			}
		}
	},
	error(message: string) {
		if (isDev) {
			if (message.includes('autocancelled')) {
				console.warn(message);
			} else {
				console.error(message);
			}
		}

		Sentry.captureMessage(
			// eslint-disable-next-line max-len
			`Error: ${message}, UserId: ${ApiClient.instance.client.authStore.model?.id}, Email: ${ApiClient.instance.client.authStore.model?.email}`,
			'error',
		);
	},
};

export default Logger;
