import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Constants } from '../../../../globals/Constants';
import { CreateStandardTransactionCategoryModal } from './CreateStandardTransactionCategory/CreateStandardTransactionCategoryModal';
import { DeleteConfirmationDialog } from '../../../components/Modals/DeleteConfirmationDialog';
import { EditStandardTransactionCategoryModal } from './EditStandardTransactionCategory/EditStandardTransactionCategoryModal';
import { StyledPaperListItem } from '../../../components/Styled/StyledPaperListItem';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { standardTransactionCategoryActions } from '../../../../redux/reducers/standardTransactionCategoryReducer/standardTransactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { StandardTransactionCategory } from '../../../../models/StandardTransactionCategory';

export const StandardTransactionCategoryScreen = React.memo(() => {
	const dispatch = useAppDispatch();

	const standardCategories = useAppSelector(
		(state) => state.standardTransactionCategories.standardTransactionCategories,
	);

	useEffect(() => {
		dispatch(standardTransactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showCreateTransactionCategoryModal, setShowCreateTransactionCategoryModal] = useState(false);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [categoryToDelete, setCategoryToDelete] = useState<StandardTransactionCategory | undefined>(undefined);

	const [showEditTransactionCategoryModal, setShowEditTransactionCategoryModal] = useState(false);
	const [categoryToEdit, setCategoryToEdit] = useState<StandardTransactionCategory | undefined>(undefined);

	const didCloseCreateTransactionCategoryModal = useCallback(() => {
		setShowCreateTransactionCategoryModal(false);
		dispatch(standardTransactionCategoryActions.fetchAll());
	}, [dispatch]);

	const didCloseConfirmationDialogWithoutDelete = useCallback(() => {
		setShowConfirmationDialog(false);
		setCategoryToDelete(undefined);
	}, []);

	const didCloseConfirmationDialogWithDelete = useCallback(() => {
		if (categoryToDelete) {
			dispatch(standardTransactionCategoryActions.remove(categoryToDelete.id!));
		}
		setShowConfirmationDialog(false);
		setCategoryToDelete(undefined);
	}, [categoryToDelete, dispatch]);

	const didCloseEditTransactionCategoryModal = useCallback(() => {
		setShowEditTransactionCategoryModal(false);
		setCategoryToEdit(undefined);
		dispatch(standardTransactionCategoryActions.fetchAll());
	}, [dispatch]);

	const listItems = useMemo(() => {
		return standardCategories.map((standardCategory) => {
			return (
				<StyledPaperListItem
					key={standardCategory.id}
					leading={
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant="h6">{standardCategory.name}</Typography>
							<Typography variant="body1">{standardCategory.description}</Typography>
						</Box>
					}
					trailing={
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setCategoryToEdit(standardCategory);
									setShowEditTransactionCategoryModal(true);
								}}
							>
								Bearbeiten
							</Button>
							<Button
								sx={{ ml: 1 }}
								variant="contained"
								color="error"
								onClick={() => {
									setShowConfirmationDialog(true);
									setCategoryToDelete(standardCategory);
								}}
							>
								Löschen
							</Button>
						</Box>
					}
				/>
			);
		});
	}, [standardCategories]);

	return (
		<>
			<CreateStandardTransactionCategoryModal
				didClose={didCloseCreateTransactionCategoryModal}
				show={showCreateTransactionCategoryModal}
			/>
			<EditStandardTransactionCategoryModal
				standardCategory={categoryToEdit!}
				didClose={didCloseEditTransactionCategoryModal}
				show={showEditTransactionCategoryModal}
			/>
			<DeleteConfirmationDialog
				itemToDeleteTitle={categoryToDelete?.name ?? ''}
				onDelete={didCloseConfirmationDialogWithDelete}
				onCancel={didCloseConfirmationDialogWithoutDelete}
				show={showConfirmationDialog}
			/>
			<StyledToolbar
				title="Standard Kategorien"
				description="Hier können Standard Kategorien erstellt, bearbeitet und gelöscht werden."
				primaryActions={
					<Button
						variant="contained"
						color="primary"
						startIcon={<Add />}
						onClick={() => setShowCreateTransactionCategoryModal(true)}
					>
						Standardkategorie hinzufügen
					</Button>
				}
			/>
			<Stack gap={Constants.gap}>{listItems}</Stack>
		</>
	);
});
