import { Box, List, Paper, Typography } from '@mui/material';
import { ExportKassenbuchButton } from '../../../components/ExportKassenbuchButton';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { bankAccountActions } from '../../../../redux/reducers/bankAccountReducer/bankAccount.slice';
import { bankAccountTransactionActions } from '../../../../redux/reducers/bankAccountTransactionReducer/bankAccountTransaction.slice';
import { bargeActions } from '../../../../redux/reducers/bargeReducer/barge.slice';
import { bargeTransactionActions } from '../../../../redux/reducers/bargeTransactionReducer/bargeTransaction.slice';
import { transactionCategoryActions } from '../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { userActions } from '../../../../redux/reducers/UserReducer/user.slice';
import { youthWorkActions } from '../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useEffect, useMemo } from 'react';

export const AdminExport = React.memo(() => {
	const dispatch = useAppDispatch();

	const bankAccounts = useAppSelector((state) => state.bankAccounts.bankAccounts);
	const bankAccountTransactions = useAppSelector((state) => state.bankAccountTransactions.bankAccountTransactions);
	const transactionCategories = useAppSelector((state) => state.transactionCategories.transactionCategories);
	const barges = useAppSelector((state) => state.barges.barges);
	const bargeTransactions = useAppSelector((state) => state.bargeTransactions.bargeTransactions);
	const cashiers = useAppSelector((state) => state.users.users);
	const youthWorks = useAppSelector((state) => state.youthWorks.youthWorks);

	useEffect(() => {
		document.title = 'Export - Kassenbuch';

		dispatch(bankAccountTransactionActions.fetchAll());
		dispatch(bankAccountActions.fetchAll());
		dispatch(youthWorkActions.fetchAll());
		dispatch(transactionCategoryActions.fetchAll());
		dispatch(bargeActions.fetchAll());
		dispatch(bargeTransactionActions.fetchAll());
		dispatch(userActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const listItems = useMemo(() => {
		return youthWorks.map((youthWork) => {
			const cashier = cashiers.find((cashier) => cashier.id === youthWork.cashier);
			const bankAccountCount = bankAccounts.filter(
				(bankAccount) => bankAccount.youthWork === youthWork.id,
			).length;

			return (
				<Paper
					key={`${youthWork.id}-paper`}
					sx={{
						p: 2,
						mb: 2,
						justifyContent: 'space-between',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'row',
						}}
					>
						<Typography variant="h6">{youthWork.name}</Typography>
						<ExportKassenbuchButton
							bankAccounts={bankAccounts}
							youthWork={youthWork}
							year={new Date().getFullYear()}
							bankAccountTransactions={bankAccountTransactions}
							transactionCategories={transactionCategories}
							barges={barges}
							bargeTransactions={bargeTransactions}
							title="Excel Export"
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'row',
							mt: 2,
						}}
					>
						<Typography variant="body1">{`Kassier: ${cashier?.firstName} ${cashier?.lastName}`}</Typography>
						<Typography variant="body1">
							{(bankAccountCount === 0 && 'Keine Bankkonten') ||
								(bankAccountCount === 1 && '1 Bankkonto') ||
								`${bankAccountCount} Bankkonten`}
						</Typography>
					</Box>
				</Paper>
			);
		});
	}, [bankAccountTransactions, bankAccounts, bargeTransactions, barges, cashiers, transactionCategories, youthWorks]);

	return (
		<>
			<StyledToolbar title="Datenexport" description="Export einzelner Kassenbücher als Excel Datei" />
			<List>{listItems}</List>
		</>
	);
});
