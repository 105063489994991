import { CreateTransactionCategoryForm } from './CreateTransactionCategoryForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { getUserId } from '../../../../../helpers/getUserId';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { youthWorkActions } from '../../../../../redux/reducers/YouthWorkReducer/youthWork.slice';
import React, { useEffect } from 'react';
import type { CreateTransactionCategoryFormDataType } from './CreateTransactionCategoryForm';

export type CreateTransactionCategoryModalProps = {
	didClose: () => void;
	show: boolean;
};

export const CreateTransactionCategoryModal = React.memo(({ didClose, show }: CreateTransactionCategoryModalProps) => {
	const dispatch = useAppDispatch();

	const youthWork = useAppSelector((state) =>
		state.youthWorks.youthWorks.find((youthWork) => youthWork.cashier === getUserId()),
	);

	useEffect(() => {
		dispatch(youthWorkActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!youthWork) {
		return null;
	}

	return (
		<StyledModal show={show} onClose={didClose} heading="Kategorie erstellen">
			<CreateTransactionCategoryForm
				submitData={function (data: CreateTransactionCategoryFormDataType): void {
					if (youthWork.id) {
						dispatch(
							transactionCategoryActions.create({
								name: data.name,
								youthwork: youthWork.id,
								isStandard: false,
							}),
						).then(() => didClose());
					} else {
						didClose();
					}
				}}
				didCancel={didClose}
			/>
		</StyledModal>
	);
});
