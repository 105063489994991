import { Box, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import React from 'react';

export type StyledPaperListItemProps = {
	leadingIcon?: React.ReactNode;
	trailingIcon?: React.ReactNode;
	leading: React.ReactNode;
	trailing?: React.ReactNode;
};

export const StyledPaperListItem: React.FC<StyledPaperListItemProps> = React.memo(
	({ leadingIcon, trailingIcon, leading, trailing }: StyledPaperListItemProps) => {
		return (
			<Paper>
				<ListItem
					sx={{
						flex: 1,
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<Box
						sx={{
							flex: 1,
							flexDirection: {
								xs: 'column',
								md: 'row',
							},
							alignItems: {
								xs: 'flex-start',
								md: 'flex-start',
							},
							width: '100%',
						}}
					>
						{leadingIcon && <ListItemIcon>{leadingIcon}</ListItemIcon>}
						<ListItemText primary={leading} />
					</Box>
					<Box>
						{trailing && <ListItemText primary={trailing} />}
						{trailingIcon && <ListItemIcon>{trailingIcon}</ListItemIcon>}
					</Box>
				</ListItem>
			</Paper>
		);
	},
);
