import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createStandardTransactionCategory,
	deleteStandardTransactionCategory,
	getAllStandardTransactionCategories,
	getStandardTransactionCategory,
	updateStandardTransactionCategory,
} from '../../../api_clients/standardTransactionCategoryAPI';
import type { StandardTransactionCategory } from '../../../models/StandardTransactionCategory';

const name = 'standardTransactionCategory';

export const createStandardTransactionCategoryExtraActions = () => {
	return {
		fetchAll: fetchAll(),
		fetch: fetch(),
		create: create(),
		update: update(),
		remove: remove(),
	};

	function fetchAll() {
		return createAsyncThunk(`${name}/fetchAll`, async () => await getAllStandardTransactionCategories());
	}

	function fetch() {
		return createAsyncThunk(`${name}/fetch`, async (id: string) => await getStandardTransactionCategory(id));
	}

	function create() {
		return createAsyncThunk(
			`${name}/create`,
			async (standardTransactionCategory: StandardTransactionCategory) =>
				await createStandardTransactionCategory(standardTransactionCategory),
		);
	}

	function update() {
		return createAsyncThunk(
			`${name}/update`,
			async (standardTransactionCategory: StandardTransactionCategory) =>
				await updateStandardTransactionCategory(standardTransactionCategory),
		);
	}

	function remove() {
		return createAsyncThunk(`${name}/remove`, async (id: string) => await deleteStandardTransactionCategory(id));
	}
};
