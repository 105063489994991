import { transactionCategoryExtraActions } from './transactionCategory.slice';
import Logger from '../../../globals/Logger';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { TransactionCategory } from '../../../models/TransactionCategory';
import type { TransactionCategoryState } from './transactionCategory.state';

export const transactionCategoryExtraReducers = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	fetchAll(builder);
	fetch(builder);
	create(builder);
	update(builder);
	remove(builder);
};

// FetchAll
const fetchAll = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	builder.addCase(transactionCategoryExtraActions.fetchAll.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('TransactionCategory::fetch::pending');
	});
	builder.addCase(transactionCategoryExtraActions.fetchAll.fulfilled, (state, action) => {
		state.transactionCategories = action.payload;

		state.byId = state.transactionCategories.reduce((acc, transactionCategory) => {
			if (transactionCategory.id) {
				acc[transactionCategory.id] = transactionCategory;
			}
			return acc;
		}, {} as Record<string, TransactionCategory>);

		state.isLoading = false;
		Logger.log('TransactionCategory::fetch::success');
	});
	builder.addCase(transactionCategoryExtraActions.fetchAll.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('TransactionCategory::fetch::error::' + action.error.message);
	});
};

// Fetch
const fetch = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	builder.addCase(transactionCategoryExtraActions.fetch.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('TransactionCategory::fetch::pending');
	});
	builder.addCase(transactionCategoryExtraActions.fetch.fulfilled, (state, action) => {
		const payload = action.payload;

		if (state.transactionCategories.findIndex((x) => x.id === payload.id) >= 0) {
			state.transactionCategories = state.transactionCategories.map((transactionCategory) => {
				if (transactionCategory.id === payload.id) {
					return payload;
				} else {
					return transactionCategory;
				}
			});
		} else {
			state.transactionCategories.push(payload);
		}

		state.byId = state.transactionCategories.reduce((acc, transactionCategory) => {
			if (transactionCategory.id) {
				acc[transactionCategory.id] = transactionCategory;
			}
			return acc;
		}, {} as Record<string, TransactionCategory>);

		state.isLoading = false;
		Logger.log('TransactionCategory::fetch::success');
	});

	builder.addCase(transactionCategoryExtraActions.fetch.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('TransactionCategory::fetch::error::' + action.error.message);
	});
};

// Create
const create = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	builder.addCase(transactionCategoryExtraActions.create.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('TransactionCategory::create::pending');
	});
	builder.addCase(transactionCategoryExtraActions.create.fulfilled, (state, action) => {
		state.transactionCategories.push(action.payload);
		state.isLoading = false;
		Logger.log('TransactionCategory::create::success');
	});
	builder.addCase(transactionCategoryExtraActions.create.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('TransactionCategory::create::error::' + action.error.message);
	});
};

// Update
const update = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	builder.addCase(transactionCategoryExtraActions.update.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('TransactionCategory::update::pending');
	});
	builder.addCase(transactionCategoryExtraActions.update.fulfilled, (state, action) => {
		const payload = action.payload;

		state.transactionCategories = state.transactionCategories.map((transactionCategory) => {
			if (transactionCategory.id === payload.id) {
				return payload;
			} else {
				return transactionCategory;
			}
		});

		state.isLoading = false;
		Logger.log('TransactionCategory::update::success');
	});
	builder.addCase(transactionCategoryExtraActions.update.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('TransactionCategory::update::error::' + action.error.message);
	});
};

// Remove
const remove = (builder: ActionReducerMapBuilder<TransactionCategoryState>) => {
	builder.addCase(transactionCategoryExtraActions.remove.pending, (state, action) => {
		state.error = null;
		state.isLoading = true;
		Logger.log('TransactionCategory::remove::pending');
	});
	builder.addCase(transactionCategoryExtraActions.remove.fulfilled, (state, action) => {
		state.transactionCategories = state.transactionCategories.filter(
			(transactionCategory) => transactionCategory.id !== action.meta.arg,
		);
		state.isLoading = false;
		Logger.log('TransactionCategory::remove::success');
	});
	builder.addCase(transactionCategoryExtraActions.remove.rejected, (state, action) => {
		state.error = action.error.message;
		state.isLoading = false;
		Logger.error('TransactionCategory::remove::error::' + action.error.message);
	});
};
