import { Box, Button, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components/FormTextField';
import { LocalizedDatePickerFormField } from '../../../../components/LocalizedDatePickerFormField';
import { NumericFormat } from 'react-number-format';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import React, { useEffect } from 'react';
import moment from 'moment';
import type { BankAccountTransaction } from '../../../../../models/BankAccountTransaction';

export type EditBankAccountTransactionFormDataType = {
	bankStatement: string;
	date: string;
	amount: number;
	description: string;
	person: string;
	transactionCategory: string;
};

export type EditBankAccountTransactionFormProps = {
	submitData: (data: EditBankAccountTransactionFormDataType) => void;
	didCancel: () => void;
	initialData: BankAccountTransaction;
};

export const EditBankAccountTransactionForm = ({
	submitData,
	didCancel,
	initialData,
}: EditBankAccountTransactionFormProps) => {
	const dispatch = useAppDispatch();

	const { control, handleSubmit, getValues } = useForm({
		defaultValues: {
			bankStatement: initialData.bankStatement,
			date: moment(initialData.date).format('DD.MM.YYYY'),
			amount: initialData.amount,
			description: initialData.description,
			person: initialData.person ?? '',
			transactionCategory: initialData.transactionCategory ?? '',
		},
	});

	const categories = useAppSelector((state) => state.transactionCategories.transactionCategories);

	useEffect(() => {
		dispatch(transactionCategoryActions.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = () => submitData(getValues());

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Controller
					name="bankStatement"
					control={control}
					render={({ field }) => <FormTextField field={field} label={'Kontoauszug'} isFirstField={true} />}
				/>
				<Controller
					name="date"
					control={control}
					render={({ field }) => <LocalizedDatePickerFormField label="Datum" field={field} />}
				/>
				<Controller
					name="amount"
					control={control}
					render={({ field }) => (
						<NumericFormat
							onValueChange={(values) => {
								field.onChange(values.floatValue);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale
							allowNegative={true}
							customInput={TextField}
							suffix=" €"
							required={true}
							label="Betrag"
							defaultValue={field.value}
						/>
					)}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field }) => <FormTextField label="Beschreibung" field={field} />}
				/>
				<Controller
					name="person"
					control={control}
					render={({ field }) => <FormTextField label="Person/Klient" field={field} />}
				/>
				<Controller
					name="transactionCategory"
					control={control}
					render={({ field }) => (
						<TextField label="Kategorie" select {...field}>
							{categories.map((category) => (
								<MenuItem key={category.id} value={category.id}>
									{category.name}
								</MenuItem>
							))}
						</TextField>
					)}
				/>
				<Box>
					<Button variant="contained" type="submit">
						Speichern
					</Button>
					<Button variant="contained" type="button" onClick={() => didCancel()}>
						Abbrechen
					</Button>
				</Box>
			</Box>
		</form>
	);
};
