import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Grid, Paper, Typography } from '@mui/material';
import { StyledToolbar } from '../../../components/Styled/StyledToolbar';
import { logsActions } from '../../../../redux/reducers/LogsReducer/logs.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React, { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';

export const AdminStatistics = React.memo(() => {
	const dispatch = useAppDispatch();

	const statistics = useAppSelector((state) => state.logs.statistics);
	const logs = useAppSelector((state) => state.logs.logs);

	const paperRef = useRef<HTMLDivElement>(null);

	const totalRequests = useMemo(
		() =>
			statistics.reduce((acc, item) => {
				acc += item.total;
				return acc;
			}, 0),
		[statistics],
	);

	const totalRequestsLast24Hours = useMemo(
		() =>
			statistics.reduce((acc, item) => {
				if (moment().diff(moment(item.date), 'hours') <= 24) {
					acc += item.total;
				}
				return acc;
			}, 0),
		[statistics],
	);

	const totalErrorsLast24Hours = useMemo(
		() =>
			statistics.reduce((acc, item) => {
				if (moment().diff(moment(item.date), 'hours') <= 24) {
					acc += logs
						.filter((log) => moment(log.date).isSame(moment(item.date), 'day'))
						.filter((log) => log.status >= 400).length;
				}
				return acc;
			}, 0),
		[logs, statistics],
	);

	const graphData = useMemo(() => {
		const groupedByDay = _.groupBy(statistics, (statistic) => moment(statistic.date).format('YYYY-MM-DD'));

		const statisticsPerDay = _.map(groupedByDay, function (group, date) {
			return {
				date: date,
				total: _.sumBy(group, 'total'),
			};
		});

		return [
			...statisticsPerDay.map((item) => {
				return {
					date: moment(item.date).format('DD.MM.YYYY'),
					requests: item.total,
				};
			}),
		];
	}, [statistics]);

	useEffect(() => {
		document.title = 'Statistiken - Kassenbuch';

		dispatch(logsActions.fetchLogs());
		dispatch(logsActions.fetchStatistics());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const requestGraph = useMemo(() => {
		return (
			<Paper sx={{ mb: 3 }} ref={paperRef}>
				<LineChart
					data={graphData}
					width={paperRef.current ? paperRef.current.offsetWidth : 0}
					height={300}
					margin={{ top: 20, right: 40, bottom: 10, left: 20 }}
				>
					<Line type="monotone" dataKey="requests" stroke="#8884d8" />
					<CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
					<XAxis dataKey="date" />
					<YAxis />
					<Tooltip />
				</LineChart>
			</Paper>
		);
	}, [graphData]);

	return (
		<>
			<StyledToolbar title="Statistiken" description="Hier findest du die Statistiken des Backend-Services" />
			{requestGraph}
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignContent: 'start',
							alignItems: 'start',
							padding: '1rem',
						}}
					>
						<Typography variant="h6">Gesamte Requests</Typography>
						<Typography variant="h4">{totalRequests}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignContent: 'start',
							alignItems: 'start',
							padding: '1rem',
						}}
					>
						<Typography variant="h6">Requests in den letzten 24h</Typography>
						<Typography variant="h4">{totalRequestsLast24Hours}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignContent: 'start',
							alignItems: 'start',
							padding: '1rem',
						}}
					>
						<Typography variant="h6">Fehler in den letzten 24h</Typography>
						<Typography variant="h4">{totalErrorsLast24Hours}</Typography>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
});
