import { ButtonBase, Grid, Paper } from '@mui/material';
import React from 'react';

export type StyledPaperGridItemProps = {
	children: React.ReactNode;
	onClick?: () => void;
	key: string;
};

export const StyledPaperGridItem: React.FC<StyledPaperGridItemProps> = React.memo(
	({ children, onClick, key }: StyledPaperGridItemProps) => {
		return (
			<Grid item>
				<Paper key={key}>
					<ButtonBase
						sx={{
							width: '100%',
							height: '100%',
							padding: 2,
							display: 'flex',
							flexDirection: {
								xs: 'column',
								md: 'row',
							},
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						onClick={onClick}
						disableRipple={!onClick}
					>
						{children}
					</ButtonBase>
				</Paper>
			</Grid>
		);
	},
);
