import { TextField } from '@mui/material';
import React from 'react';
import type { HTMLInputTypeAttribute } from 'react';

export type FormTextFieldProps = {
	field: any;
	label: string;
	type?: HTMLInputTypeAttribute;
	optional?: boolean;
	isFirstField?: boolean;
	fullWidth?: boolean;
};

export const FormTextField = React.memo(
	({ field, label, type = 'text', optional = false, isFirstField = false, fullWidth = true }: FormTextFieldProps) => {
		return (
			<TextField
				fullWidth={fullWidth}
				label={label}
				type={type}
				required={!optional}
				autoFocus={isFirstField}
				{...field}
			/>
		);
	},
);
