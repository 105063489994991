import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { StandardTransactionCategory } from '../models/StandardTransactionCategory';

const collectionName = 'standardTransactionCategories';

// List
export async function getAllStandardTransactionCategories() {
	Logger.log('StandardTransactionCategoryAPI::getAllStandardTransactionCategories');
	try {
		return ApiClient.instance.client.collection(collectionName).getFullList<StandardTransactionCategory>();
	} catch (error) {
		Logger.error('StandardTransactionCategoryAPI::getAllStandardTransactionCategories::error::' + error);
		throw error;
	}
}

// View
export async function getStandardTransactionCategory(id: string) {
	Logger.log('StandardTransactionCategoryAPI::getStandardTransactionCategory::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<StandardTransactionCategory>(id);
	} catch (error) {
		Logger.error('StandardTransactionCategoryAPI::getStandardTransactionCategory::error::' + error);
		throw error;
	}
}

// Create
export async function createStandardTransactionCategory(standardTransactionCategory: StandardTransactionCategory) {
	Logger.log('StandardTransactionCategoryAPI::createStandardTransactionCategory::' + standardTransactionCategory.id);
	try {
		return await ApiClient.instance.client
			.collection(collectionName)
			.create<StandardTransactionCategory>(standardTransactionCategory);
	} catch (error) {
		Logger.error(
			'StandardTransactionCategoryAPI::createStandardTransactionCategory::' +
				standardTransactionCategory.id +
				'::error::' +
				error,
		);
		throw error;
	}
}

// Update
export async function updateStandardTransactionCategory(standardTransactionCategory: StandardTransactionCategory) {
	if (standardTransactionCategory.id) {
		Logger.log(
			'StandardTransactionCategoryAPI::updateStandardTransactionCategory::' + standardTransactionCategory.id,
		);
		try {
			return await ApiClient.instance.client
				.collection(collectionName)
				.update<StandardTransactionCategory>(standardTransactionCategory.id, standardTransactionCategory);
		} catch (error) {
			Logger.error(
				'StandardTransactionCategoryAPI::updateStandardTransactionCategory::' +
					standardTransactionCategory.id +
					'::error::' +
					error,
			);
			throw error;
		}
	} else {
		return standardTransactionCategory;
	}
}

// Delete
export async function deleteStandardTransactionCategory(id: string) {
	Logger.log('StandardTransactionCategoryAPI::deleteStandardTransactionCategory::' + id);
	if (id) {
		try {
			return await ApiClient.instance.client.collection(collectionName).delete(id);
		} catch (error) {
			Logger.error(
				'StandardTransactionCategoryAPI::deleteStandardTransactionCategory::' + id + '::error::' + error,
			);
			throw error;
		}
	} else {
		throw new Error('StandardTransactionCategoryAPI::deleteStandardTransactionCategory::id is null');
	}
}
