import { Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import React from 'react';
import type { ReactNode } from 'react';

export type SidebarLinkProps = {
	text: string;
	icon: ReactNode;
	path: string;
	onClick: () => void;
	showEditButton?: boolean;
	editCallBack?: () => void;
};

export const SidebarLink = React.memo(
	({ text, icon, path, onClick, showEditButton, editCallBack }: SidebarLinkProps) => {
		const location = useLocation();

		return (
			<ListItemButton onClick={onClick} selected={location.pathname === path}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={text} />
				{showEditButton && editCallBack && (
					<Button variant="contained" onClick={editCallBack} startIcon={<Edit />} />
				)}
			</ListItemButton>
		);
	},
);
