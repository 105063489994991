import { EditTransactionCategoryForm } from './EditTransactionCategoryForm';
import { StyledModal } from '../../../../components/Styled/StyledModal';
import { transactionCategoryActions } from '../../../../../redux/reducers/TransactionCategoryReducer/transactionCategory.slice';
import { useAppDispatch } from '../../../../../redux/hooks';
import React from 'react';
import type { EditTransactionCategoryFormDataType } from './EditTransactionCategoryForm';
import type { TransactionCategory } from '../../../../../models/TransactionCategory';

export type EditTransactionCategoryModalProps = {
	transactionCategory: TransactionCategory;
	didClose: () => void;
	show: boolean;
};

export const EditTransactionCategoryModal = React.memo(
	({ transactionCategory, didClose, show }: EditTransactionCategoryModalProps) => {
		const dispatch = useAppDispatch();

		return (
			<StyledModal show={show} onClose={didClose} heading="Kategorie Bearbeiten">
				<EditTransactionCategoryForm
					submitData={function (data: EditTransactionCategoryFormDataType): void {
						dispatch(
							transactionCategoryActions.update({
								id: transactionCategory.id,
								name: data.name,
								youthwork: transactionCategory.youthwork,
								isStandard: false,
							}),
						).then(() => didClose());
					}}
					transactionCategory={transactionCategory}
					didCancel={didClose}
				/>
			</StyledModal>
		);
	},
);
