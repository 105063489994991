import ApiClient from '../globals/apiClient';
import Logger from '../globals/Logger';
import type { BankAccount } from '../models/BankAccount';

const collectionName = 'bankAccounts';

// List
export async function getAllBankAccounts() {
	Logger.log('BankAccountAPI::getAllBankAccounts');
	try {
		const response = await ApiClient.instance.client.collection(collectionName).getFullList<BankAccount>();
		return response;
	} catch (error) {
		Logger.error('BankAccountAPI::getAllBankAccounts::error::' + error);
		throw error;
	}
}

// View
export async function getBankAccount(id: string) {
	Logger.log('BankAccountAPI::getBankAccount::' + id);
	try {
		return await ApiClient.instance.client.collection(collectionName).getOne<BankAccount>(id);
	} catch (error) {
		Logger.error('BankAccountAPI::getBankAccount::error::' + error);
		throw error;
	}
}

// Create
export async function createBankAccount(bankAccount: BankAccount) {
	Logger.log('BankAccountAPI::createBankAccount::' + bankAccount.id);
	try {
		return await ApiClient.instance.client.collection(collectionName).create<BankAccount>(bankAccount);
	} catch (error) {
		Logger.error('BankAccountAPI::createBankAccount::' + bankAccount.id + '::error::' + error);
		throw error;
	}
}

// Update
export async function updateBankAccount(bankAccount: BankAccount) {
	if (bankAccount.id) {
		Logger.log('BankAccountAPI::updateBankAccount::' + bankAccount.id);
		try {
			return await ApiClient.instance.client
				.collection(collectionName)
				.update<BankAccount>(bankAccount.id, bankAccount);
		} catch (error) {
			Logger.error('BankAccountAPI::updateBankAccount::' + bankAccount.id + '::error::' + error);
			throw error;
		}
	} else {
		return bankAccount;
	}
}

// Delete
export async function deleteBankAccount(id: string) {
	Logger.log('BankAccountAPI::deleteBankAccount::' + id);
	if (id) {
		try {
			return await ApiClient.instance.client.collection(collectionName).delete(id);
		} catch (error) {
			Logger.error('BankAccountAPI::deleteBankAccount::' + id + '::error::' + error);
			throw error;
		}
	} else {
		throw new Error('BankAccountAPI::deleteBankAccount::id is null');
	}
}
