import { APPCONFIG } from './APP_CONFIG';
import PocketBase from 'pocketbase';
import moment from 'moment';

class ApiClient {
	private readonly baseUrl: string;
	public readonly client: PocketBase;

	public static instance = new ApiClient(APPCONFIG.API_BASE_URL);

	constructor(baseUrl: string) {
		this.baseUrl = baseUrl;
		this.client = new PocketBase(this.baseUrl);
	}

	public async authenticate(email: string, password: string, asAdmin: boolean = false) {
		if (asAdmin) {
			return await this.client.admins.authWithPassword(email, password);
		} else {
			return await this.authenticateUserAndSetLastLogin(email, password);
		}
	}

	private async authenticateUserAndSetLastLogin(email: string, password: string) {
		const user = await this.client.collection('users').authWithPassword(email, password);
		return await this.client
			.collection('users')
			.update(user.record.id, { lastLogin: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') });
	}

	public async setLastLogin(userId: string) {
		return await this.client
			.collection('users')
			.update(userId, { lastLogin: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') });
	}
}

export default ApiClient;
